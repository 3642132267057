<template>
  <div class="flex flex-col pt-4 px-2 md:px-6">
    <div class="w-full text-center font-medium md:text-lg">{{ "Presentazione domanda graduatoria MMG/PLS anno " +
      statusStore.year }}</div>
    <div class="flex flex-wrap xl:flex-nowrap justify-center mt-4">
      <div class="flex w-full xl:w-auto mx-4 my-2 xl:my-0">
        <div class="font-medium text-sm md:text-base">
          Istruzioni per la compilazione:
        </div>
      </div>
      <div class="flex w-full xl:w-auto group cursor-pointer mx-4 my-2 xl:my-0" @click="downloadMMGFirstRequestVideo()">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary group-hover:text-primary-dark" icon="fas fa-video" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-base">
          MMG - Primo Inserimento
        </div>
      </div>
      <div class="flex w-full xl:w-auto group cursor-pointer mx-4 my-2 xl:my-0" @click="downloadMMGIntegrationRequestVideo()">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary group-hover:text-primary-dark" icon="fas fa-video" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-base">
          MMG - Integrazione
        </div>
      </div>
      <div class="flex w-full xl:w-auto group cursor-pointer mx-4 my-2 xl:my-0" @click="downloadPLSFirstRequestVideo()">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary group-hover:text-primary-dark" icon="fas fa-video" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-base">
          PLS - Primo Inserimento
        </div>
      </div>
      <div class="flex w-full xl:w-auto group cursor-pointer mx-4 my-2 xl:my-0" @click="downloadPLSIntegrationRequestVideo()">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary group-hover:text-primary-dark" icon="fas fa-video" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-base">
          PLS - Integrazione
        </div>
      </div>
    </div>
    <div class="flex mt-4 text-red-flag font-medium text-sm md:text-base">
      Se già inclusi in graduatoria di anni precedenti cliccare su 'INTEGRAZIONE SERVIZI'.
      LA DOMANDA DEVE CONTENERE DICHIARAZIONI CONCERNENTI I TITOLI E I SERVIZI PRECEDENTEMENTE NON AUTOCERTIFICATI.
    </div>
    <div class="flex mt-2 text-red-flag font-medium text-sm md:text-base">
      Se si sta presentando domanda per la prima volta proseguire cliccando su 'PRIMO INSERIMENTO'.
    </div>
    <div class="flex flex-col lg:flex-row mt-4 lg:space-x-4">
      <div class="w-full lg:w-1/2 border-primary-dark shadow-rankings">
        <div class="flex flex-col">
          <div class="flex flex-col bg-primary text-white p-4">
            <div class="md:text-lg font-semibold">MMG - Medicina Generale</div>
            <div class="text-sm md:text-base">{{ 'Scadenza presentazione domanda: ' + $moment(statusStore.mmgDeadLine,
              'YYYY-MM-DD').format('DD/MM/YYYY') }}</div>
          </div>
          <div v-if="checkMMGAvailability && (!statusStore.type || statusStore.type.toLowerCase() === 'mmg')"
            class="flex flex-col">
            <div class="flex p-4">
              <input type="radio" id="mmgFirstRequest" value="mmgFirstRequest"
                v-model="applicationRequestStore.requestType.requestCategory" class="mr-4 cursor-pointer" />
              <label for="mmgFirstRequest" class="text-sm md:text-base cursor-pointer font-medium">PRIMO
                INSERIMENTO</label>
            </div>
            <div v-if="applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest'"
              class="flex flex-col ml-4">
              <div class="flex px-4 pb-4">
                <input type="radio" id="mmgFirstRequestWithTitle" value="mmgFirstRequestWithTitle"
                  v-model="applicationRequestStore.requestType.firstRequestTitle" class="mr-4 cursor-pointer" />
                <label for="mmgFirstRequestWithTitle" class="text-sm md:text-base cursor-pointer">in possesso
                  dell'attestato di
                  formazione specifica in medicina generale</label>
              </div>
              <div class="flex px-4 pb-4">
                <input type="radio" id="mmgFirstRequestWithoutTitle" value="mmgFirstRequestWithoutTitle"
                  v-model="applicationRequestStore.requestType.firstRequestTitle" class="mr-4 cursor-pointer" />
                <label for="mmgFirstRequestWithoutTitle" class="text-sm md:text-base cursor-pointer">{{ `in attesa di
                  conseguire l'attestato di formazione specifica in medicina generale da acquisire, autocertificare e
                  trasmettere a mezzo PEC entro il 15 settembre ${(parseInt(statusStore.year) - 1)}` }}</label>
              </div>
            </div>
            <div class="flex px-4 pb-4">
              <input type="radio" id="mmgRequestIntegration" value="mmgRequestIntegration"
                v-model="applicationRequestStore.requestType.requestCategory" class="mr-4 cursor-pointer" />
              <label for="mmgRequestIntegration" class="text-sm md:text-base cursor-pointer font-medium">INTEGRAZIONE
                SERVIZI</label>
            </div>
          </div>
          <div v-else-if="statusStore.type.toLowerCase() === 'pls'" class="flex justify-center mt-8 p-2 font-semibold">Non è
            possibile inserire domande per MMG poiché risulta presente una domanda per PLS</div>
          <div
            v-if="checkMMGAvailability && (applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' || applicationRequestStore.requestType.requestCategory === 'mmgRequestIntegration')"
            class="flex flex-col">
            <div class="flex p-4">{{ `Il/La sottoscritto/a, secondo quanto previsto dall'Accordo Collettivo Nazionale per la disciplina dei rapporti con i Medici di Medicina Generale 29 luglio 2009 e s.m.i., chiede di essere inserito nella graduatoria unica regionale per la medicina generale, valevole per l'anno ${(parseInt(statusStore.year))}, per lo svolgimento delle attività nel settore/settori di seguito specificato/i (selezionare il settore/settori per il quale si intende concorrere): ` }}
            </div>
            <div v-for="(sector, index) in mmgSectors" :key="index" class="flex mx-6">
              <input type="checkbox" :id="sector.sect_id" :true-value="sector.sect_id"
                v-model="applicationRequestStore.requestType.sectors[sector.sect_id]">
              <label class="ml-4" for="sector.sect_id">{{ sector.sector }}</label>
            </div>
          </div>
          <button
            v-if="checkMMGAvailability && (applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' || applicationRequestStore.requestType.requestCategory === 'mmgRequestIntegration')"
            class="btn-primary ml-auto mr-4 my-4 text-sm md:text-base" @click="goToRequest()">Vai alla domanda</button>
          <div v-if="!checkMMGAvailability" class="p-8 font-semibold">Termini di presentazione domanda scaduti</div>
        </div>
      </div>
      <div class="w-full lg:w-1/2 border-primary-dark shadow-rankings my-4 lg:my-0">
        <div class="flex flex-col">
          <div class="flex flex-col bg-primary text-white p-4">
            <div class="md:text-lg font-semibold">PLS - Pediatria di Libera Scelta</div>
            <div class="text-sm md:text-base">{{ 'Scadenza presentazione domanda: ' + $moment(statusStore.plsDeadLine,
              'YYYY-MM-DD').format('DD/MM/YYYY') }}</div>
          </div>
          <div v-if="checkPLSAvailability && (!statusStore.type || statusStore.type.toLowerCase() === 'pls')"
            class="flex flex-col">
            <div class="flex p-4">
              <input type="radio" id="plsFirstRequest" value="plsFirstRequest"
                v-model="applicationRequestStore.requestType.requestCategory" class="mr-4 cursor-pointer" />
              <label for="plsFirstRequest" class="text-sm md:text-base cursor-pointer font-medium">PRIMO
                INSERIMENTO</label>
            </div>
            <div v-if="applicationRequestStore.requestType.requestCategory === 'plsFirstRequest'"
              class="flex flex-col ml-4">
              <div class="flex px-4 pb-4">
                <input type="radio" id="plsFirstRequestWithTitle" value="plsFirstRequestWithTitle"
                  v-model="applicationRequestStore.requestType.firstRequestTitle" class="mr-4 cursor-pointer" />
                <label for="plsFirstRequestWithTitle" class="text-sm md:text-base cursor-pointer">in possesso del diploma
                  di
                  specializzazione in pediatria o equipollente</label>
              </div>
              <div class="flex px-4 pb-4">
                <input type="radio" id="plsFirstRequestWithoutTitle" value="plsFirstRequestWithoutTitle"
                  v-model="applicationRequestStore.requestType.firstRequestTitle" class="mr-4 cursor-pointer" />
                <label for="plsFirstRequestWithoutTitle" class="text-sm md:text-base cursor-pointer"> {{ `diploma di
                  specializzazione in pediatria o equipollente da acquisire, autocertificare e trasmettere a mezzo PEC
                  entro il 15 settembre ${(parseInt(statusStore.year) - 1)}` }}</label>
              </div>
            </div>
            <div class="flex px-4 pb-4">
              <input type="radio" id="plsRequestIntegration" value="plsRequestIntegration"
                v-model="applicationRequestStore.requestType.requestCategory" class="mr-4 cursor-pointer" />
              <label for="plsRequestIntegration" class="text-sm md:text-base cursor-pointer font-medium">INTEGRAZIONE
                SERVIZI</label>
            </div>
          </div>
          <div v-else-if="statusStore.type.toLowerCase() === 'mmg'" class="flex justify-center mt-8 p-2 font-semibold">Non è
            possibile inserire domande per PLS poiché risulta presente una domanda per MMG</div>
          <button
            v-if="checkPLSAvailability && (applicationRequestStore.requestType.requestCategory === 'plsFirstRequest' || applicationRequestStore.requestType.requestCategory === 'plsRequestIntegration')"
            class="btn-primary ml-auto mr-4 my-4 text-sm md:text-base" @click="goToRequest()">Vai alla domanda</button>
          <div v-if="!checkPLSAvailability" class="p-8 font-semibold">Termini di presentazione domanda scaduti</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
  name: 'Home-page',
  components: {},
  data: () => ({
    mmgSectors: null,
    isMMGUpdate: false,
    isPLSUpdate: false,
    loading: false,
  }),
  created() {
    this.getSectors()
    this.getMMGRequest()
    this.getPLSRequest()
  },
  computed: {
    ...mapStores(useStatusStore),
    ...mapStores(useApplicationRequestStore),
    checkFirstRequest() {
      return ((this.applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' && (this.applicationRequestStore.requestType.firstRequestTitle && this.applicationRequestStore.requestType.firstRequestTitle.startsWith('mmg'))) || (this.applicationRequestStore.requestType.requestCategory === 'plsFirstRequest' && (this.applicationRequestStore.requestType.firstRequestTitle && this.applicationRequestStore.requestType.firstRequestTitle.startsWith('pls'))))
    },
    checkMMGAvailability() {
      let now = this.$moment().format('YYYY-MM-DD')
      return ((!this.statusStore.statusId || (this.statusStore.statusId == '1' || this.statusStore.statusId == '6')) && this.$moment(now).isBetween(this.statusStore.mmgStartLine, this.statusStore.mmgDeadLine, 'days', '[]'))
    },
    checkPLSAvailability() {
      let now = this.$moment().format('YYYY-MM-DD')
      return ((!this.statusStore.statusId || (this.statusStore.statusId == '1' || this.statusStore.statusId == '6')) && this.$moment(now).isBetween(this.statusStore.plsStartLine, this.statusStore.plsDeadLine, 'days', '[]'))
    }
  },
  methods: {
    async getMMGRequest() {
      var dataToSend = {};
      try {
        const { data } = await Services.getMMGRequest()
        if (data) {
          this.isMMGUpdate = true
          dataToSend.requestCategory = data.new_entry === '1' ? 'mmgFirstRequest' : 'mmgRequestIntegration'
          if (data.new_entry === '1') {
            dataToSend.firstRequestTitle = data.mmg_training_doc === '1' ? 'mmgFirstRequestWithTitle' : 'mmgFirstRequestWithoutTitle'
          }
          dataToSend.sectors = data.sectors
          await this.updateRequestType(dataToSend)
        }
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore nel recupero dei dati della richiesta. Contatta il gestore del servizio."
        })
      }
    },

    async getPLSRequest() {
      var dataToSend = {};
      try {
        const { data } = await Services.getPLSRequest()
        if (data) {
          this.isPLSUpdate = true
          dataToSend.requestCategory = data.new_entry === '1' ? 'plsFirstRequest' : 'plsRequestIntegration'
          if (data.new_entry === '1') {
            dataToSend.firstRequestTitle = data.pls_training_doc === '1' ? 'plsFirstRequestWithTitle' : 'plsFirstRequestWithoutTitle'
          }
          await this.updateRequestType(dataToSend)
        }
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore nel recupero dei dati della richiesta. Contatta il gestore del servizio."
        })
      }
    },

    async getSectors() {
      try {
        const { data } = await Services.getSectors()
        if (data) {
          this.mmgSectors = data
        }
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore nel recupero dei settori MMG. Contatta il gestore del servizio."
        })
      }
    },

    async postMMGRequest() {

      var selectedSectors = this.applicationRequestStore.requestType.sectors.filter(n => n)

      var dataToSend = {
        new_entry: this.applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' ? '1' : '0',
        mmg_training_doc: this.applicationRequestStore.requestType.firstRequestTitle === 'mmgFirstRequestWithTitle' ? '1' : '0',
        sectors: selectedSectors
      }

      if (this.statusStore.mmgLastApplicationYear && dataToSend.new_entry == '1') {
        this.$swal({
          title: 'Attenzione',
          text: "Risulti già in graduatoria per l'anno " + this.statusStore.mmgLastApplicationYear + ". Hai selezionato 'Primo inserimento' e continuando perderai i dati precedentemente inseriti e dovrai ricompilare l'intera domanda.",
          showCancelButton: true,
          confirmButtonText: 'Continua',
          cancelButtonText: 'Annulla'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await Services.postMMGRequest(dataToSend)
              this.$router.push('/application-request')
            } catch (e) {
              if (e.response.data.status === 400) {
                this.$swal({
                  title: "Attenzione",
                  text: e.response.data.data.error
                })
              } else {
                this.$swal({
                  title: "Errore",
                  text: "Si è verificato un errore nella creazione della domanda. Contatta il gestore del servizio"
                })
              }
              return;
            }
          }
        })
      } else {
        try {
          await Services.postMMGRequest(dataToSend)
          this.$router.push('/application-request')
        } catch (e) {
          if (e.response.data.status === 400) {
            this.$swal({
              title: "Attenzione",
              text: e.response.data.data.error
            })
          } else {
            this.$swal({
              title: "Errore",
              text: "Si è verificato un errore nella creazione della domanda. Contatta il gestore del servizio"
            })
          }
          return;
        }
      }

    },

    async postPLSRequest() {

      var dataToSend = {
        new_entry: this.applicationRequestStore.requestType.requestCategory === 'plsFirstRequest' ? '1' : '0',
        pls_training_doc: this.applicationRequestStore.requestType.firstRequestTitle === 'plsFirstRequestWithTitle' ? '1' : '0',
      }

      if (this.statusStore.plsLastApplicationYear && dataToSend.new_entry == '1') {
        this.$swal({
          title: 'Attenzione',
          text: "Risulti già in graduatoria per l'anno " + this.statusStore.plsLastApplicationYear + ". Hai selezionato 'Primo inserimento' e continuando perderai i dati precedentemente inseriti e dovrai ricompilare l'intera domanda.",
          showCancelButton: true,
          confirmButtonText: 'Continua',
          cancelButtonText: 'Annulla'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await Services.postPLSRequest(dataToSend)
              this.$router.push('/application-request')
            } catch (e) {
              if (e.response.data.status === 400) {
                this.$swal({
                  title: "Attenzione",
                  text: e.response.data.data.error
                })
              } else {
                this.$swal({
                  title: "Errore",
                  text: "Si è verificato un errore nella creazione della domanda. Contatta il gestore del servizio"
                })
              }
              return;
            }
          }
        })
      } else {
        try {
          await Services.postPLSRequest(dataToSend)
          this.$router.push('/application-request')
        } catch (e) {
          if (e.response.data.status === 400) {
            this.$swal({
              title: "Attenzione",
              text: e.response.data.data.error
            })
          } else {
            this.$swal({
              title: "Errore",
              text: "Si è verificato un errore nella creazione della domanda. Contatta il gestore del servizio"
            })
          }
          return;
        }
      }
    },

    async updateMMGRequest() {

      var selectedSectors = Object.values(this.applicationRequestStore.requestType.sectors).filter(n => n)

      var dataToSend = {
        new_entry: this.applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' ? '1' : '0',
        mmg_training_doc: this.applicationRequestStore.requestType.firstRequestTitle === 'mmgFirstRequestWithTitle' ? '1' : '0',
        sectors: selectedSectors
      }

      try {
        await Services.updateMMGRequest(dataToSend)
        this.$router.push('/application-request')
      } catch (e) {
        if (e.response.data.status === 400) {
          this.$swal({
            title: "Attenzione",
            text: e.response.data.data.error
          })
        } else {
          this.$swal({
            title: "Errore",
            text: "Si è verificato un errore nella modifica della domanda. Contatta il gestore del servizio"
          })
        }
        return;
      }
    },

    async updatePLSRequest() {

      var dataToSend = {
        new_entry: this.applicationRequestStore.requestType.requestCategory === 'plsFirstRequest' ? '1' : '0',
        pls_training_doc: this.applicationRequestStore.requestType.firstRequestTitle === 'plsFirstRequestWithTitle' ? '1' : '0',
      }

      try {
        await Services.updatePLSRequest(dataToSend)
        this.$router.push('/application-request')
      } catch (e) {
        if (e.response.data.status === 400) {
          this.$swal({
            title: "Attenzione",
            text: e.response.data.data.error
          })
        } else {
          this.$swal({
            title: "Errore",
            text: "Si è verificato un errore nella modifica della domanda. Contatta il gestore del servizio"
          })
        }
        return;
      }
    },

    goToRequest() {
      if (this.applicationRequestStore.requestType.requestCategory.startsWith('mmg') && Object.values(this.applicationRequestStore.requestType.sectors).filter(n => n).length <= 0) {
        if (this.applicationRequestStore.requestType.requestCategory === 'mmgFirstRequest' && !this.checkFirstRequest) {
          this.$swal({
            title: "Attenzione",
            text: "Selezionare se in possesso dell'attestato di formazione specifica in medicina generale"
          })
          return;
        }
        this.$swal({
          title: "Attenzione",
          text: "Specificare i settori per i quali si intende concorrere"
        })
        return;
      }
      if (this.applicationRequestStore.requestType.requestCategory === 'plsFirstRequest' && !this.checkFirstRequest) {
        this.$swal({
          title: "Attenzione",
          text: "Selezionare se in possesso del diploma di specializzazione in pediatria o equipollente"
        })
        return;
      }

      if (this.applicationRequestStore.requestType.requestCategory.startsWith('mmg')) {
        this.isMMGUpdate ? this.updateMMGRequest() : this.postMMGRequest()
      } else if (this.applicationRequestStore.requestType.requestCategory.startsWith('pls')) {
        this.isPLSUpdate ? this.updatePLSRequest() : this.postPLSRequest()
      }
    },

    async updateRequestType(data) {
      if (data.requestCategory.startsWith('mmg')) {
        await this.applicationRequestStore.setRequestType({
          requestCategory: data ? data.requestCategory : this.applicationRequestStore.requestType.requestCategory,
          firstRequestTitle: data ? data.firstRequestTitle : this.applicationRequestStore.requestType.firstRequestTitle,
          sectors: data ? data.sectors : this.applicationRequestStore.requestType.sectors,
        })
      } else if (data.requestCategory.startsWith('pls')) {
        await this.applicationRequestStore.setRequestType({
          requestCategory: data ? data.requestCategory : this.applicationRequestStore.requestType.requestCategory,
          firstRequestTitle: data ? data.firstRequestTitle : this.applicationRequestStore.requestType.firstRequestTitle,
        })
      }
    },

    downloadMMGFirstRequestVideo(){
      window.open("https://youtu.be/WZuPRUfCtQo", "_blank")
    },
    downloadMMGIntegrationRequestVideo(){
      window.open("https://youtu.be/Pr3z0ELfEkA", "_blank")
    },
    downloadPLSFirstRequestVideo(){
      window.open("https://youtu.be/vA9HrFUyaR4", "_blank")
    },
    downloadPLSIntegrationRequestVideo(){
      window.open("https://youtu.be/ygmGuNNn-lE", "_blank")
    },
  }
}
</script>

<style scoped>
:deep() {
  --vs-controls-color: #0185CF;
  --vs-border-color: #0185CF;
  --vs-selected-bg: #0185CF;
  --vs-dropdown-option--active-bg: #0185CF;
}
</style>