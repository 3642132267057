<template>
    <div class="flex flex-col items-center justify-center my-6">
        <div class="flex w-11/12 sm:w-3/4 xl:w-1/2 h-52 border border-gray-400 border-dashed rounded items-center justify-center"
            :class="isDragging ? 'bg-gray-500' : 'bg-white'" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" multiple name="file" id="fileInput"
                class="absolute overflow-hidden opacity-0 w-px h-px top-0" @change="onChange" ref="file" />
            <label for="fileInput" class="text-lg cursor-pointer p-2">
                <div class="text-white" v-if="isDragging">Rilascia il file qui</div>
                <div class="text-gray-500" v-else>Trascina i file qui o <u>clicca</u> per caricare i file.</div>
            </label>
        </div>
        <div class="flex flex-wrap mt-8 justify-center md:justify-start" v-if="files.length">
            <div v-for="file in files" :key="file.name" class="flex p-2 border border-primary rounded-full m-2">
                <div class="flex w-60 sm:w-auto sm:max-w-xs lg:max-w-sm items-center justify-center">
                    <div class="text-primary-dark truncate" :title="file.name">{{ file.name }}</div>
                    <div class="ml-2" @click="remove(files.indexOf(file))" title="Rimuovi il file">
                        <font-awesome-icon class="h-4 cursor-pointer text-primary hover:text-primary-dark"
                            icon="fas fa-circle-xmark" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap mt-8 justify-center md:justify-start" v-if="attachments && attachments.length">
            <div v-for="attachment in attachments" :key="attachment.attach_id" class="flex p-2 m-2">
                <div class="flex w-60 sm:w-auto sm:max-w-xs lg:max-w-sm items-center justify-center">
                    <div class="underline underline-offset-2 text-primary hover:text-primary-dark truncate cursor-pointer"
                    @click="downloadAttachment(attachment.attach_id)">{{ attachment.original_filename }}</div>
                    <div class="ml-2" @click="deleteFile(attachment.attach_id)" title="Rimuovi il file">
                        <font-awesome-icon class="h-4 cursor-pointer text-primary hover:text-primary-dark"
                            icon="fas fa-circle-xmark" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services';
export default {
    name: 'DragDrop',
    emits: ['fileAdded', 'reloadList'],
    props: {
        attachments: Array
    },
    data() {
        return {
            isDragging: false,
            files: [],
        };
    },
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        onChange() {
            this.files = [...this.$refs.file.files];
            this.$emit('fileAdded', this.files)
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        async downloadAttachment(id) {
            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.getMMGAttachment(id)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.getPLSAttachment(id)
                }
            } catch (error) {
                this.$swal({
                    title: "Errore durante il download",
                    text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
                })
            }
        },
        deleteFile(id) {
            try {
                this.$swal({
                    title: 'Eliminare il file',
                    text: 'Eliminando il file non sarà più possibile recuperarlo',
                    showCancelButton: true,
                    confirmButtonText: 'Elimina',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            if (this.statusStore.type.toLowerCase() === 'mmg') {
                                await Services.deleteMMGAttachment(id)
                            } else if (this.statusStore.type.toLowerCase() === 'pls') {
                                await Services.deletePLSAttachment(id)
                            }
                            this.$emit('reloadList')
                        } catch (e) {
                            this.$swal({
                                title: "Errore nell'invio dei dati",
                                text: "Si è verificato un errore nell'invio dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                            })
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore nell'invio dei dati",
                    text: "Si è verificato un errore nell'invio dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        }
    },
};
</script>