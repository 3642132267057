<template>
  <div id="#app">
    <TopBar v-if="!$route.meta.isAdmin" />
    <AdminTopBar v-if="$route.meta.isAdmin" />
    <div id="mainContainer" class="overflow-y-auto"
      :class="$route.name === 'Home' || $route.name === 'ApplicationRequest' ? 'screen-height-without-double-topbar-and-footer' : 'screen-height-without-topbar-and-footer'">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import './assets/tailwind.css'
import TopBar from '@/components/TopBar.vue'
import AdminTopBar from '@/components/administrator/AdminTopBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    TopBar,
    AdminTopBar,
    Footer
  },

}
</script>