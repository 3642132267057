<template>
    <div class="flex mt-4 mb-2 ml-4 lg:ml-6 group items-center">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary-dark group-hover:text-primary cursor-pointer"
            icon="fas fa-arrow-left" @click="$router.push('/adminhome')" />
        <div class="text-primary-dark group-hover:text-primary cursor-pointer" @click="$router.push('/adminhome')">Torna
            alla home</div>
    </div>
    <div class="flex flex-col pb-6" v-if="!loading">
        <div class="flex w-full justify-center text-primary-dark text-xl font-semibold mb-6 px-2 lg:px-4">{{ 'Graduatoria '
            + type + ' ' + reqType }}</div>
        <div v-if="rankId"
            class="flex w-full justify-center items-center text-primary-dark mb-6 px-2 lg:px-4 group hover:text-primary cursor-pointer"
            @click="downloadExcel">
            <font-awesome-icon class="h-4 mr-2 text-primary-dark group-hover:text-primary cursor-pointer"
                icon="fas fa-file-excel" />Scarica file Excel
        </div>
        <div v-if="ranking.length > 0"
            class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white mx-2 lg:mx-4 items-center">
            <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Posizione</div>
            <div class="flex w-3/12 border-l border-white p-2 text-sm xl:text-base">Cognome</div>
            <div class="flex w-3/12 border-l border-white p-2 text-sm xl:text-base">Nome</div>
            <div class="flex w-4/12 border-l border-r border-white p-2 text-sm xl:text-base">PEC</div>
            <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Punti</div>
        </div>
        <div v-for="(item, index) in ranking" :key="index" class="flex flex-col">
            <div class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary mx-2 lg:mx-4 items-center"
                :class="index === ranking.length - 1 ? 'rounded-b mb-4' : 'mb-4 lg:mb-0'">
                <div
                    class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 lg:justify-center p-2 order-1 border-r lg:border-r-0 border-primary lg:border-0">
                    <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Posizione</div>
                    <div class="flex">
                        {{ item.position }}</div>
                </div>
                <div
                    class="flex flex-col w-full lg:w-3/12 border-t lg:border-l lg:border-t-0 border-primary p-2 order-3 lg:order-2">
                    <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Cognome</div>
                    <div class="flex">{{ item.lastname }}</div>
                </div>
                <div
                    class="flex flex-col w-full lg:w-3/12 border-t lg:border-l lg:border-t-0 border-primary p-2 order-4 lg:order-3">
                    <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Nome</div>
                    <div class="flex">{{ item.firstname }}</div>
                </div>
                <div
                    class="flex flex-col w-full lg:w-4/12 border-t lg:border-l lg:border-r lg:border-t-0 border-primary p-2 order-5 lg:order-4">
                    <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">PEC</div>
                    <div class="flex">{{ item.pec }}</div>
                </div>
                <div
                    class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 p-2 lg:items-center lg:justify-center order-2 lg:order-5">
                    <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Punti</div>
                    <div class="flex mx-auto lg:mx-0">{{ item.score }}</div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="flex items-center h-96">
        <Spinner />
    </div>
</template>

<script>
import Services from '@/services';
import Spinner from '@/components/Spinner.vue'

export default {
    name: "Ranking",
    components: { Spinner },
    data: () => ({
        type: null,
        reqType: null,
        rankId: null,
        ranking: [],
        loading: false,
    }),
    created() {
        this.getRanking()
    },
    methods: {
        async getRanking() {
            try {
                this.loading = true
                if (this.$route.params.type === 'temporary') {
                    this.type = 'provvisoria'
                    if (this.$route.params.reqType === 'mmg') {
                        this.reqType = 'MMG'
                        const { data } = await Services.MMGGetTemporaryRanking()
                        this.rankId = data.rank_id
                        this.ranking = data.details
                        this.loading = false
                        return;
                    }
                    if (this.$route.params.reqType === 'pls') {
                        this.reqType = 'PLS'
                        const { data } = await Services.PLSGetTemporaryRanking()
                        this.rankId = data.rank_id
                        this.ranking = data.details
                        this.loading = false
                        return;
                    }

                }
                if (this.$route.params.type === 'final') {
                    this.type = 'definitiva'
                    if (this.$route.params.reqType === 'mmg') {
                        this.reqType = 'MMG'
                        const { data } = await Services.MMGGetFinalRanking()
                        this.rankId = data.rank_id
                        this.ranking = data.details
                        this.loading = false
                        return;
                    }
                    if (this.$route.params.reqType === 'pls') {
                        this.reqType = 'PLS'
                        const { data } = await Services.PLSGetFinalRanking()
                        this.rankId = data.rank_id
                        this.ranking = data.details
                        this.loading = false
                        return;
                    }
                }
            } catch (e) {
                this.$swal.fire({
                    title: 'Errore',
                    text: "Si è verificato un errore di recupero dati. Riprova più tardi o contatta l'assistenza tecnica."
                })
            }
        },
        async downloadExcel() {
            try {
                if (this.$route.params.reqType === 'mmg') {
                    await Services.downloadMMGRanking(this.rankId)
                } else if (this.$route.params.reqType === 'pls') {
                    await Services.downloadPLSRanking(this.rankId)
                }
            } catch (error) {
                this.$swal({
                    title: "Errore durante il download",
                    text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
                })
            }
        }

    }
}
</script>