import axios from 'axios';
import Services from '../index';
import AbstractRequest from './AbstractRequest';

class RankingsRequestClass extends AbstractRequest {

    constructor() {
        super(axios.create(), process.env.VUE_APP_API_BASE_URL, 'rankings_token');
        this.isRefreshing = false;
        this.requestsQueue = [];
    }

    processQueue(error) {
        this.requestsQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve();
            }
        })
        this.requestsQueue = [];
    }
}

const RankingsRequest = new RankingsRequestClass();

RankingsRequest.interceptResponse(function (response) {
    return response.data;
}, async function (error) {
    const originalRequest = error.config
    try {
        if (originalRequest.data) {
            const data = JSON.parse(originalRequest.data);
            if (data.grant_type === 'refresh_token') {
                return Promise.reject(error);
            }
        }
    } catch {
        return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest.__retry) {
        if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
                this.requestsQueue.push({resolve, reject})
            }).then(() => {
                originalRequest.headers = {
                    ...originalRequest.headers,
                };
                return this.instance(originalRequest);
            }).catch(error => {
                return Promise.reject(error)
            })
        }
        originalRequest.__retry = true;
        this.isRefreshing = true;
        try {
            await Services.refreshAuthToken();
            originalRequest.headers = {
                ...originalRequest.headers,
            };
            this.processQueue();
            this.isRefreshing = false;
            return this.instance(originalRequest);
        } catch (error) {
            this.processQueue(error);
        }
    }
    throw error;
});

export default RankingsRequest;
