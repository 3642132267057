import Home from '@/views/Home.vue'
import Landing from '@/views/Landing.vue'
import ApplicationRequest from '@/views/ApplicationRequest.vue'
import ApplicationList from '@/views/ApplicationList.vue'
import AdminHome from '@/views/administrator/AdminHome.vue'
import Ranking from '@/views/administrator/Ranking.vue'
import ApplicationDetails from '@/views/administrator/ApplicationDetails.vue'
import UserRecap from '@/views/administrator/UserRecap.vue'
import Settings from '@/views/administrator/Settings.vue'
import Signin from '@/views/Signin.vue'
import Maintenance from '@/views/Maintenance.vue'

export default [
    {
        path: "/",
        name: "Landing",
        component: Landing,
        meta: {
            auth: false,
            isAdmin: false
        },
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            auth: true,
            isAdmin: false
        },
    }, 
    {
        path: "/application-request",
        name: "ApplicationRequest",
        component: ApplicationRequest,
        meta: {
            auth: true,
            isAdmin: false
        },
    },
    {
        path: "/application-list",
        name: "ApplicationList",
        component: ApplicationList,
        meta: {
            auth: true,
            isAdmin: false
        },
    },
    {
        path: "/adminhome",
        name: "AdminHome",
        component: AdminHome,
        meta: {
            auth: true,
            isAdmin: true
        },
    },
    {
        path: "/ranking/:type/:reqType",
        name: "Ranking",
        component: Ranking,
        meta: {
            auth: true,
            isAdmin: true
        },
    },
    {
        path: "/application-details/:type/:id",
        name: "ApplicationDetails",
        component: ApplicationDetails,
        meta: {
            auth: true,
            isAdmin: true
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
            auth: true,
            isAdmin: true
        },
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
        meta: {
            auth: false,
            isAdmin: false
        },
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: Maintenance,
        meta: {
            auth: false,
            isAdmin: false
        },
    },
    {
        path: "/user-recap/:type/:reqId",
        name: "UserRecap",
        component: UserRecap,
        meta: {
            auth: true,
            isAdmin: true
        },
    },
];
