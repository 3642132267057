<template>
  <div class="flex flex-col">
    <div v-if="checkAvailability" class="flex mt-2 lg:mx-6 justify-center lg:justify-end items-center text-primary-dark font-semibold cursor-pointer hover:text-primary" @click="$router.push('/home')">Compila domanda per la graduatoria</div>
    <div class="w-full text-primary-dark text-center font-medium md:text-lg mt-4 mb-6">Storico delle domande presentate
    </div>
    <div
      class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white mx-2 lg:mx-4 items-center">
      <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Stato</div>
      <div class="flex w-1/12 border-l border-r border-white p-2 text-sm xl:text-base">Anno</div>
      <div class="flex w-9/12 border-l border-r border-white p-2 text-sm xl:text-base">Tipo domanda</div>
      <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Download</div>
    </div>
    <div v-if="applications.length > 0" v-for="(item, index) in applications" :key="index" class="flex flex-col">
      <ApplicationSummary :application="item" :isLast="index === applications.length - 1" />
    </div>
    <div v-else class="flex border border-primary-dark mx-4 p-4">Nessuna domanda presentata</div>
  </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import ApplicationSummary from '@/components/ApplicationSummary.vue'
import Services from '@/services'

export default {
  name: 'ApplicationList',
  components: { ApplicationSummary },
  data: () => ({
    applications: [],
  }),
  created() {
    this.getApplications()
  },
  computed: {
    ...mapStores(useStatusStore),
    checkAvailability(){
      if(this.statusStore.mmgStartLine && this.statusStore.mmgDeadLine && this.statusStore.plsStartLine && this.statusStore.plsDeadLine){
        let now = this.$moment().format('YYYY-MM-DD')
        if((this.$moment(now).isBetween(this.statusStore.mmgStartLine, this.statusStore.mmgDeadLine, 'days', '[]') || this.$moment(now).isBetween(this.statusStore.plsStartLine, this.statusStore.plsDeadLine, 'days', '[]')) && !this.statusStore.type){
          if(this.statusStore.nextStep == '0' && !this.statusStore.status){
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    async getApplications() {
      try {
        const { data } = await Services.getDoctorRequests()
        this.applications = data
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore nel recupero dei dati. Contatta il gestore del servizio."
        })
      }
    },
  },

}
</script>

<style scoped>
:deep() {
  --vs-controls-color: #0185CF;
  --vs-border-color: #0185CF;
  --vs-selected-bg: #0185CF;
  --vs-dropdown-option--active-bg: #0185CF;
}
</style>