<template>
    <div class="flex h-4 bg-primary"></div>
    <div class="flex h-2">
        <div class="w-1/3 bg-green-flag"></div>
        <div class="w-1/3 bg-white"></div>
        <div class="w-1/3 bg-red-flag"></div>
    </div>
    <div class="flex flex-wrap md:flex-nowrap h-36 bg-primary">
        <div class="flex items-center py-4 md:py-0 pr-4 md:pr-0 cursor-pointer" @click="$router.push('/adminhome')">
            <img class="h-18 mx-6" src="@/assets/images/logoRegioneMolise.png" />
            <div class="text-white text-lg sm:text-2xl font-medium">Graduatorie MMG/PLS</div>
        </div>
        <div class="flex sm:w-auto flex-grow justify-end items-center pr-6 py-2 md:py-0">
            <!-- <div v-if="$route.meta.auth" class="flex mt-1 items-center group">
                <div class="flex items-center cursor-pointer text-white group-hover:text-gray-300 mr-8"
                    :class="$route.path === '/settings' ? 'text-gray-300' : ''" @click="$router.push('/settings')">
                    <font-awesome-icon class="h-4 md:h-5 w-4 md:w-5 mr-2" icon="fas fa-gear" />
                    Impostazioni
                </div>
            </div> -->
            <div v-if="$route.meta.auth" class="flex mt-1 items-center group">
                <div v-if="$route.meta.auth" class="flex items-center cursor-pointer text-white group-hover:text-gray-300"
                    @click="logout">
                    <font-awesome-icon class="h-4 md:h-5 w-4 md:w-5 mr-2" icon="fas fa-right-from-bracket" />
                    Esci
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Services from '@/services'
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { mapStores } from 'pinia'

export default {
    name: "TopBar",
    computed: {
        ...mapStores(useApplicationRequestStore)
    },
    methods: {
        async logout() {
            try {
                await Services.signOut()
                this.$router.push('/signin')
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio.",
                })
            }
        }
    }
}
</script>