<template>
    <div class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary mx-2 lg:mx-4 items-center"
        :class="isLast ? 'rounded-b mb-4' : 'mb-4 lg:mb-0'">
        <div
            class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 lg:justify-center p-2 order-1 border-r lg:border-r-0 border-primary lg:border-0">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
            <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
                :class="qualification.status.toLowerCase() === 'rifiutato' ? 'bg-red-flag' : qualification.status.toLowerCase() === 'accettato' ? 'bg-green-flag' : 'bg-orange-500'">
                {{ qualification.status }}</div>
        </div>
        <div
            class="flex flex-col w-full lg:w-10/12 border-t lg:border-l lg:border-r lg:border-t-0 border-primary p-2 order-3 lg:order-2">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Titolo di studio</div>
            <div class="flex lg:mb-2">{{ qualification.title }}</div>
            <div v-if="qualification.specialization || qualification.diff_specialization" class="flex">
                <div>Specializzazione: </div>
                <div class="font-semibold ml-2">{{ qualification.specialization ? qualification.specialization : qualification.diff_specialization}}</div>
            </div>
            <div class="flex">
                <div>Ente: </div>
                <div class="font-semibold ml-2">{{ qualification.issued_by }}</div>
            </div>
            <div class="flex justify-end font-semibold text-primary"> {{ showDate }} </div>
        </div>
        <div class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 p-2 lg:items-center lg:justify-center order-2 lg:order-3">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Operazioni</div>
            <div class="flex mx-auto lg:mx-0">
                <font-awesome-icon v-if="isEditable" class="h-6 mr-6 lg:mr-4 text-primary hover:text-primary-dark cursor-pointer"
                    icon="fas fa-edit" alt="Modifica" title="Modifica" @click="modifyQualificationModal = true" />
                <font-awesome-icon class="h-6 text-red-logo hover:text-red-logo-dark cursor-pointer" icon="fas fa-trash"
                    alt="Elimina" title="Elimina" @click="deleteQualification" />
            </div>
        </div>
    </div>
    <Modal :active="modifyQualificationModal" title="Modifica Titolo di Studio"
        card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <QualificationDetails @close-add-qualification-modal="closeModal" :qualification="qualification" />
            </div>
        </template>
    </Modal>
</template>

<script>
import QualificationDetails from '@/components/qualifications/QualificationDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'QualificationSummary',
    emits: ['updateQualificationList'],
    props: {
        qualification: Object,
        isLast: Boolean
    },
    components: { Modal, QualificationDetails, Spinner },
    data() {
        return {
            modifyQualificationModal: false,
        }
    },
    computed: {
        ...mapStores(useStatusStore),
        showDate() {
            return this.qualification.graduation_date ? this.$moment(this.qualification.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.qualification.end ? this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' - ' + this.$moment(this.qualification.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        isEditable() {
            if(((this.qualification.code.toLowerCase() == 'a11-a' || this.qualification.code.toLowerCase() == 'a7-a') && this.statusStore.hasCertificate == '0') || ((this.qualification.code.toLowerCase() == 'a11-i' || this.qualification.code.toLowerCase() == 'a7-i') && this.statusStore.hasCertificate == '1')){
                return false;
            }
            return true;   
        }
    },
    methods: {
        deleteQualification() {
            this.$swal.fire({
                title: "Confermare l'eliminazione?",
                text: "Eliminare il titolo di studio?",
                showCancelButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        if (this.statusStore.type.toLowerCase() === 'mmg') {
                            await Services.deleteMMGQualification(this.qualification.ac_qual_id)
                        } else if (this.statusStore.type.toLowerCase() === 'pls') {
                            await Services.deletePLSQualification(this.qualification.ac_qual_id)
                        }
                        this.$swal.fire({
                            title: 'Titolo di studio eliminato',
                            text: "L'eliminazione è avvenuta con successo"
                        })
                        this.$emit('updateQualificationList')
                    } catch (e) {
                        this.$swal.fire({
                            title: 'Errore eliminazione',
                            text: "Si è verificato un errore durante l'eliminazione. Riprova più tardi o contatta l'assistenza tecnica."
                        })
                    }
                }
            })
        },
        closeModal(isSaved) {
            if (isSaved) {
                this.$emit('updateQualificationList')
            }
            this.modifyQualificationModal = false
        }
    }
}
</script>