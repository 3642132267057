<template>
    <div class="flex mt-4 mb-2 ml-4 lg:ml-6 group items-center">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary-dark group-hover:text-primary cursor-pointer"
            icon="fas fa-arrow-left" @click="$router.push('/adminhome')" />
        <div class="text-primary-dark group-hover:text-primary cursor-pointer" @click="$router.push('/adminhome')">Torna
            alla home</div>
    </div>
    <div class="flex flex-col pb-6 px-2 lg:px-6" v-if="!loading">
        <div class="flex w-full justify-center text-primary-dark text-xl font-semibold mb-6 ">Impostazioni</div>
        <div class="flex flex-col w-full border border-primary shadow-rankings rounded">
            <div class="flex justify-center bg-primary text-white font-semibold py-2 rounded-t">Generali</div>
            <div class="flex px-6 my-6 items-center">
                <input type="checkbox" v-model="inMaintenance" true-value="1" false-value="0" class="cursor-pointer mr-4" />
                <div>Sito in manutenzione</div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row mt-4 lg:space-x-4">
            <div class="w-full lg:w-1/2 border border-primary shadow-rankings rounded">
                <div class="flex flex-col">
                    <div class="flex justify-center bg-primary text-white font-semibold py-2 rounded-t">Medici di Medicina
                        Generale</div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Anno Graduatoria
                            <Datepicker inputClassName="dp-custom-input" v-model="mmgYear" class="w-full text-sm mt-1"
                                placeholder="Anno" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" year-picker>
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Data di inizio inserimento
                            <Datepicker inputClassName="dp-custom-input" v-model="mmgStartDate" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Data di fine inserimento
                            <Datepicker inputClassName="dp-custom-input" v-model="mmgEndDate" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 my-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Ultima data valida per i titoli di studio
                            <Datepicker inputClassName="dp-custom-input" v-model="mmgTitlesDeadline"
                                class="w-full text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it"
                                selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/2 border border-primary shadow-rankings my-4 lg:my-0 rounded">
                <div class="flex flex-col">
                    <div class="flex justify-center bg-primary text-white font-semibold py-2 rounded-t">Pediatri di Libera
                        Scelta</div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Anno Graduatoria
                            <Datepicker inputClassName="dp-custom-input" v-model="plsYear" class="w-full text-sm mt-1"
                                placeholder="Anno" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" year-picker>
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Data di inizio inserimento
                            <Datepicker inputClassName="dp-custom-input" v-model="plsStartDate" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 mt-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Data di fine inserimento
                            <Datepicker inputClassName="dp-custom-input" v-model="plsEndDate" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="flex px-6 my-6">
                        <label class="w-full sm:w-2/3 xl:w-1/2">Ultima data valida per i titoli di studio
                            <Datepicker inputClassName="dp-custom-input" v-model="plsTitlesDeadline"
                                class="w-full text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it"
                                selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy">
                            </Datepicker>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full justify-center">
            <button class="w-fit mt-6 btn-success" @click="saveSettings">Salva</button>
        </div>
    </div>
    <!-- Anno, start, end, titles_deadline -->
    <div v-else class="flex items-center h-96">
        <Spinner />
    </div>
</template>

<script>
import Services from '@/services';
import Spinner from '@/components/Spinner.vue'

export default {
    name: "Settings",
    components: { Spinner },
    data: () => ({
        loading: false,
        inMaintenance: '0',
        mmgYear: null,
        mmgStartDate: null,
        mmgEndDate: null,
        mmgTitlesDeadline: null,
        plsYear: null,
        plsStartDate: null,
        plsEndDate: null,
        plsTitlesDeadline: null,
    }),
    methods: {
        saveSettings() {
            //TODO
        }
    }
}
</script>

<style scoped>
:deep() {
    --vs-controls-color: #0185CF;
    --vs-border-color: #0185CF;
    --vs-selected-bg: #0185CF;
    --vs-dropdown-option--active-bg: #0185CF;
}

:deep(.dp-custom-input) {
    @apply border border-primary rounded hover:border-primary-dark hover:rounded;
}
</style>