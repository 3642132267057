import cookies from 'vue-cookies';

export default class AbstractRequest {

    constructor(instance, base_url, cookie_key) {
        this.instance = instance;
        this.BASE_URL = base_url;
        this.COOKIE_KEY = cookie_key;
    }

    interceptResponse(success, fail) {
        this.instance.interceptors.response.use(success.bind(this), fail.bind(this));
    }

    headers(auth) {
        if (!auth) return {};
        const token = cookies.get(this.COOKIE_KEY);
        return {
            Authorization: `Bearer ${token}`
        };
    }

    url(url) {
        return `${this.BASE_URL}/${url}`;
    }

    post({path, data = {}, auth = false, withCredentials = true}) {
        return this.instance.post(this.url(path), data, {
            headers: this.headers(auth),
            withCredentials
        });
    }

    put({path, data = {}, auth = false, withCredentials = true}) {
        return this.instance.put(this.url(path), data, {
            headers: this.headers(auth),
            withCredentials
        });
    }

    update({path, data = {}, auth = false, withCredentials = true}) {
        return this.instance.put(this.url(path), data, {
            headers: this.headers(auth),
            withCredentials
        });
    }

    get({path, auth = false, query = {}, withCredentials = true}) {
        const qs = new URLSearchParams(query).toString();
        path = this.url(path);
        path += qs ? `?${qs}` : '';
        return this.instance.get(path, {
            headers: this.headers(auth),
            withCredentials
        });
    }

    delete({path, auth = false, withCredentials = true}) {
        return this.instance.delete(this.url(path), {
            headers: this.headers(auth),
            withCredentials
        });
    }
};