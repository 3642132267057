<template>
    <div
        class="flex bg-primary w-full screen-height-without-topbar-and-footer border-t-2 border-color-white items-center justify-center">
        <div class="flex flex-col w-full sm:w-3/4 xl:w-1/3 bg-white justify-center shadow-rankings rounded-lg">
            <div class="text-3xl mt-16 mx-auto">Accedi</div>
            <input type="text" placeholder="Username"
                class="text-sm mt-16 block py-2 px-3 mx-10 border border-primary rounded focus:outline-none"
                v-model="username" :disabled="loading" />
            <input type="password" placeholder="Password"
                class="text-sm mt-10 block py-2 px-3 mx-10 border border-primary rounded focus:outline-none"
                v-model="password" :disabled="loading" @keyup.enter="signin" />
            <div class="flex flex-wrap sm:flex-nowrap mt-20 mb-10 w-full">
                <button class="btn-primary w-9/12 sm:w-auto mx-auto" :disabled="loading" @click="signin">
                    <span v-if="!loading">Login</span>
                    <svg viewBox="0 0 38 38" stroke="#FFFFFF" class="w-6 mx-auto" v-else>
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(1 1)" stroke-width="2">
                                <circle stroke-opacity="0.5" cx="18" cy="18" r="18" />
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18"
                                        dur="1s" repeatCount="indefinite" />
                                </path>
                            </g>
                        </g>
                    </svg>
                </button>
                <!-- <button class="btn-primary w-9/12 sm:w-auto mx-auto" :disabled="loading" @click="spidSignin">
                    <span v-if="!loading">Entra con Spid</span>
                    <svg viewBox="0 0 38 38" stroke="#FFFFFF" class="w-6 mx-auto" v-else>
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(1 1)" stroke-width="2">
                                <circle stroke-opacity="0.5" cx="18" cy="18" r="18" />
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18"
                                        dur="1s" repeatCount="indefinite" />
                                </path>
                            </g>
                        </g>
                    </svg>
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services';
export default {
    name: 'sign-in',
    data() {
        return {
            username: "",
            password: "",
            loading: false,
        };
    },
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        async signin() {
            try {
                this.loading = true;
                if (!this.username || !this.password) {
                    this.loading = false;
                    this.$swal.fire({
                        title: 'Dati mancanti',
                        text: 'Inserire username e password',
                        confirmButtonText: 'OK',
                    })
                    return;
                }
                const { data } = await Services.signin(this.username, this.password);
                data.isAdmin ? this.$router.push('/adminhome') : this.$router.push('/home')
            } catch (e) {
                this.loading = false;
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else if (e.response.data.status === 401) {
                    this.$swal({
                        title: "Attenzione",
                        text: "Nome utente o password errati"
                    })
                } else {
                    this.$swal('Si è verificato un errore')
                }
                return;
            }
        },
    }
}
</script>