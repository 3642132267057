<template>
    <div v-if="!loading">
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex flex-col items-center justify-center bg-primary text-white p-2 rounded-t">
                <div class="text-lg font-semibold uppercase">Dichiarazione sostitutiva di certificazione</div>
                <div class="text-sm">(art. 46 D.P.R. n. 445 del 28/12/2000)</div>
                <div class="font-semibold uppercase">Dichiara</div>
            </div>
            <div class="text-gray-600 font-bold my-4 mx-2">Sotto la propria responsabilità e consapevole delle sanzioni
                penali
                previste dall'art. 76 del D.P.R. n.445 del 28/12/2000 per ipotesi di falsità in atti e dichiarazioni
                mendaci:
            </div>
            <div class="flex flex-col p-2 rounded shadow-rankings border border-primary mb-4 mx-2">
                <div class="text-gray-600 font-bold my-4 mx-2">Spuntare il campo sottostante.</div>
                <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full mt-2 mb-4">
                        <input type="checkbox" v-model="applicationRequestStore.selfCertification.noPermanentContract"
                            true-value="1" false-value="0" class="cursor-pointer" />
                        <label class="ml-2">{{ statusStore.type === 'mmg' ? `di non essere titolare di incarico a tempo
                            indeterminato nei suddetti settori prescelti per i quali intende concorrere` : `di non essere
                            titolare di incarico a tempo indeterminato di pediatria di libera scelta` }}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-col p-2 rounded shadow-rankings border border-primary mb-4 mx-2">
                <div class="text-gray-600 font-bold my-4 mx-2">Spuntare i campi riferiti al trattamento per invalidità
                    assoluta
                    e permanente e al trattamento di quiescenza.</div>
                <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full mt-2 mb-4">
                        <input type="checkbox" v-model="applicationRequestStore.selfCertification.noDisability"
                            true-value="1" false-value="0" class="cursor-pointer" />
                        <label class="ml-2">DI NON FRUIRE DEL TRATTAMENTO PER INVALIDITA' ASSOLUTA E PERMANENTE da parte del
                            fondo di previdenza competente di cui al Decreto 15 ottobre 1976 del Ministero del lavoro e
                            della
                            previdenza sociale </label>
                    </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full mb-6">
                        <input type="checkbox" v-model="applicationRequestStore.selfCertification.noQuiescence"
                            true-value="1" false-value="0" class="cursor-pointer" />
                        <label class="ml-2">DI NON FRUIRE DEL TRATTAMENTO DI QUIESCENZA COME PREVISTO DALLA NORMATIVA
                            VIGENTE.
                            (Tale incompatibilità non opera nei confronti di medici che beneficiano delle sole prestazioni
                            delle
                            quote "A e B" del fondo di previdenza generale dell'ENPAM)</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-col p-2 rounded shadow-rankings border border-primary mb-4 mx-2">
                <div class="text-gray-600 font-bold my-4 mx-2">Spuntare i campi riferiti alla cittadinanza</div>
                <div class="mx-2 lg:mx-4">
                    <div class="mt-2 mb-4">
                        <input type="radio" id="italian" value="it"
                            v-model="applicationRequestStore.selfCertification.nationalityArea"
                            class="mr-2 cursor-pointer" />
                        <label for="italian">di essere cittadino italiano</label>
                    </div>
                    <div>
                        <input type="radio" id="eu" value="eu"
                            v-model="applicationRequestStore.selfCertification.nationalityArea"
                            class="mr-2 cursor-pointer" />
                        <label for="eu">di essere cittadino di Stato membro dell'Unione Europea</label>
                    </div>
                    <div class="mt-4 mb-6">
                        <input type="radio" id="noEu" value="noEu"
                            v-model="applicationRequestStore.selfCertification.nationalityArea"
                            class="mr-2 cursor-pointer" />
                        <label for="noEu">di essere cittadino del seguente Stato ed in possesso di uno dei requisiti di cui
                            all'art.38, commi 1 e 3 bis del D.Lgs. 165/2001 e s.m.i.</label>
                    </div>
                </div>
                <div v-if="applicationRequestStore.selfCertification.nationalityArea === 'eu' || applicationRequestStore.selfCertification.nationalityArea === 'noEu'"
                    class="mx-2 lg:mx-4">
                    <label>Stato <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Inserire lo stato della cittadinanza"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.selfCertification.nationality" />
                    </label>
                </div>
            </div>
            <div class="flex flex-col p-2 rounded shadow-rankings border border-primary mb-4 mx-2">
                <div class="text-gray-600 font-bold my-4 mx-2">Il sottoscritto dichiara inoltre</div>
                <div class="flex mx-2 lg:mx-4 mt-2 mb-4">
                    <div class="mr-8">
                        <input type="radio" id="notRevokated" value="0"
                            v-model="applicationRequestStore.selfCertification.isRevoked" class="mr-2 cursor-pointer" />
                        <label class="font-bold" for="notRevokated">DI NON AVERE</label>
                    </div>
                    <div>
                        <input type="radio" id="revokated" value="1"
                            v-model="applicationRequestStore.selfCertification.isRevoked" class="mr-2 cursor-pointer" />
                        <label class="font-bold" for="revokated">DI AVERE</label>
                    </div>
                </div>
                <div class="mx-2 mb-4">{{ statusStore.type === 'mmg' ? `SUBITO PROVVEDIMENTI DI REVOCA DEL RAPPORTO
                    CONVENZIONALE DI MEDICO DI MEDICINA GENERALE. IN CASO AFFERMATIVO INDICARE LA DATA DI REVOCA DEL
                    RAPPORTO CONVENZIONALE E L'AZIENDA CHE HA ADOTTATO IL PROVVEDIMENTO.` : `SUBITO PROVVEDIMENTI DI REVOCA
                    DEL RAPPORTO CONVENZIONALE DI PEDIATRA DI LIBERA SCELTA. IN CASO AFFERMATIVO INDICARE LA DATA DI REVOCA
                    DEL RAPPORTO CONVENZIONALE E L'AZIENDA CHE HA ADOTTATO IL PROVVEDIMENTO.` }}</div>
                <div v-if="applicationRequestStore.selfCertification.isRevoked === '1'" class="flex flex-col mx-2 lg:mx-4">
                    <div class="mb-2">
                        <label>Data di revoca <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input"
                                v-model="applicationRequestStore.selfCertification.revokationDate"
                                class="w-full md:w-1/2 text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Seleziona la data"
                                locale="it" selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false"
                                format="dd/MM/yyyy" :max-date="new Date()" @change="updateSelfCertificationData()">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="mb-6">
                        <label>Azienda <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Specificare l'azienda"
                                class="w-full md:w-1/2 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="applicationRequestStore.selfCertification.revokationCompany" />
                        </label>
                    </div>
                </div>
                <div class="mx-2 font-bold">che le notizie sopra riportate corrispondano al vero (1)</div>
                <div class="mx-2 mb-6">(1) il dichiarante decade dai benefici eventualmente conseguiti al provvedimento
                    emanato sulla base della
                    dichiarazione non veritiera (art.75 del DPR 445/2000)</div>
            </div>
        </div>
        <div class="flex w-full justify-center">
            <button class="w-fit mt-6" :class="missingMandatoryFields ? 'btn-disabled' : 'btn-success'"
                @click="saveSelfCertification" :disabled="missingMandatoryFields">Salva</button>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
</template>

<script>
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { useUiStore } from '@/store/ui.js'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Spinner from '@/components/Spinner.vue'
import Services from '@/services'

export default {
    name: 'SelfCertification',
    components: { Spinner },
    data() {
        return {
            loading: false,
        }
    },
    created() {
        this.getSelfCertification()
    },
    computed: {
        ...mapStores(useApplicationRequestStore),
        ...mapStores(useStatusStore),
        ...mapStores(useUiStore),
        missingMandatoryFields() {
            return (!this.applicationRequestStore.selfCertification.noPermanentContract || this.applicationRequestStore.selfCertification.noPermanentContract === '0' || !this.applicationRequestStore.selfCertification.noDisability || this.applicationRequestStore.selfCertification.noDisability === '0' || !this.applicationRequestStore.selfCertification.noQuiescence || this.applicationRequestStore.selfCertification.noQuiescence === '0' || !this.applicationRequestStore.selfCertification.nationalityArea || ((this.applicationRequestStore.selfCertification.nationalityArea === 'eu' || this.applicationRequestStore.selfCertification.nationalityArea === 'noEu') && !this.applicationRequestStore.selfCertification.nationality) || !this.applicationRequestStore.selfCertification.isRevoked || (this.applicationRequestStore.selfCertification.isRevoked === '1' && (!this.applicationRequestStore.selfCertification.revokationDate || !this.applicationRequestStore.selfCertification.revokationCompany)))
        },
    },
    methods: {
        async getSelfCertification() {
            this.loading = true
            try {

                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGSelfCertifications() : await Services.getPLSSelfCertifications()

                await this.updateSelfCertificationData(data)
                if (data && !this.missingMandatoryFields) {
                    this.uiStore.setShowStepperGoForward(true)
                } else {
                    this.uiStore.setShowStepperGoForward(false)
                }
                this.loading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.loading = false
            }
        },
        async updateSelfCertificationData(data = null) {
            if (data) {
                await this.applicationRequestStore.setSelfCertification({
                    noPermanentContract: data ? data.permanent_assignment : this.applicationRequestStore.selfCertification.noPermanentContract,
                    noDisability: data ? data.disability : this.applicationRequestStore.selfCertification.noDisability,
                    noQuiescence: data ? data.quiescence : this.applicationRequestStore.selfCertification.noQuiescence,
                    nationalityArea: data ? data.nationality : this.applicationRequestStore.selfCertification.nationalityArea,
                    nationality: data ? data.nat_country : this.applicationRequestStore.selfCertification.nationality,
                    isRevoked: data ? data.agreement_revoked : this.applicationRequestStore.selfCertification.isRevoked,
                    revokationDate: (data && data.revoked_at) ? this.$moment(data.revoked_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.applicationRequestStore.selfCertification.revokationDate,
                    revokationCompany: (data && data.revoking_company) ? data.revoking_company : this.applicationRequestStore.selfCertification.revokationCompany,
                })
            }
        },
        async saveSelfCertification() {
            this.loading = true

            if (this.missingMandatoryFields) {
                this.$swal({
                    title: "Campi obbligatori mancanti",
                    text: "Alcuni campi obbligatori non sono stati compilati"
                })
                this.loading = false
                return;
            }

            var dataToSend = {
                permanent_assignment: this.applicationRequestStore.selfCertification.noPermanentContract,
                disability: this.applicationRequestStore.selfCertification.noDisability,
                quiescence: this.applicationRequestStore.selfCertification.noQuiescence,
                nationality: this.applicationRequestStore.selfCertification.nationalityArea,
                nat_country: this.applicationRequestStore.selfCertification.nationality,
                agreement_revoked: this.applicationRequestStore.selfCertification.isRevoked,
                revoked_at: this.applicationRequestStore.selfCertification.revokationDate || null,
                revoking_company: this.applicationRequestStore.selfCertification.revokationCompany || null,
            }

            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.updateMMGSelfCertifications(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.updatePLSSelfCertifications(dataToSend)
                }
                this.uiStore.setShowStepperGoForward(true)
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.loading = false
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.loading = false
                return;
            }

        },
    }
}
</script>