<template>
    <div class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary items-center"
        :class="rowClass" @click="openQualificationDetails">
        <!-- <div
            class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 lg:justify-center p-2 order-1 border-r lg:border-r-0 border-primary lg:border-0">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
            <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
                :class="qualification.status.toLowerCase() === 'rifiutato' ? 'bg-red-flag' : qualification.status.toLowerCase() === 'accettato' ? 'bg-green-flag' : 'bg-orange-500'">
                {{ qualification.status }}</div>
        </div> -->
        <div class="flex flex-col w-full lg:w-full p-2">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Titolo di studio</div>
            <div class="flex sm:mb-2 font-semibold text-primary-dark">{{ qualification.title }}</div>
            <div v-if="qualification.specialization || qualification.diff_specialization" class="flex flex-col sm:flex-row mt-2 sm:mt-0">
                <div class="text-primary-dark">Specializzazione: </div>
                <div class="font-semibold sm:ml-2">{{ qualification.specialization ? qualification.specialization : qualification.diff_specialization }}</div>
            </div>
            <div class="flex flex-col sm:flex-row mt-2 sm:mt-0">
                <div class="text-primary-dark">{{ qualification.type_id == 3 ? 'Provincia: ' : qualification.type_id == 1 ? 'Ateneo: ' : 'Ente: ' }}
                </div>
                <div class="font-semibold sm:ml-2">{{ qualification.type_id == 3 ? qualification.province :
                    qualification.issued_by }}</div>
            </div>
            <div v-if="qualification.type_id == 1" class="flex mt-2 sm:mt-0">
                <div class="text-primary-dark">Voto: </div>
                <div class="font-semibold ml-2">{{ qualification.honors == '1' ? qualification.vote + '/' +
                    qualification.scale + ' e lode' : qualification.vote + '/' + qualification.scale }}</div>
            </div>
            <div v-if="(qualification.type_id == 1 || qualification.type == 2) && qualification.foreign_title == '1'"
                class="flex mt-2 sm:mt-0">
                <div class="font-semibold">Titolo conseguito all'estero</div>
            </div>
            <div class="flex justify-end font-semibold text-primary mt-2 sm:mt-0"> {{ showDate }} </div>
        </div>
        <!-- <div class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 p-2 lg:items-center lg:justify-center order-2 lg:order-3">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Operazioni</div>
            <div class="flex mx-auto lg:mx-0">
                <font-awesome-icon class="h-6 mr-6 lg:mr-4 text-primary hover:text-primary-dark cursor-pointer"
                    icon="fas fa-edit" alt="Modifica" title="Modifica" @click="modifyQualificationModal = true" />
                <font-awesome-icon class="h-6 text-red-logo hover:text-red-logo-dark cursor-pointer" icon="fas fa-trash"
                    alt="Elimina" title="Elimina" @click="deleteQualification"/>
            </div>
        </div> -->
    </div>
    <Modal :active="qualificationDetailsModal" title="Dettagli Titolo di Studio"
        card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <AdminQualificationDetails @close-add-qualification-modal="qualificationDetailsModal = false"
                    :qualification="qualification" />
            </div>
        </template>
    </Modal>
</template>

<script>
import AdminQualificationDetails from '@/components/administrator/AdminQualificationDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import Services from '@/services'

export default {
    name: 'AdminQualificationSummary',
    props: {
        qualification: Object,
        isLast: Boolean
    },
    components: { Modal, AdminQualificationDetails, Spinner },
    data() {
        return {
            qualificationDetailsModal: false,
        }
    },
    computed: {
        showDate() {
            return this.qualification.graduation_date ? this.$moment(this.qualification.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.qualification.end ? this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' - ' + this.$moment(this.qualification.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        rowClass() {
            return {
                'rounded-b': this.isLast,
                'mb-4 lg:mb-0': !this.isLast,
                'cursor-pointer hover:bg-gray-100': this.qualification.type_id != 1 && this.qualification.type_id != 2 && this.qualification.type_id != 3 && this.qualification.code.toLowerCase() !== 'c9'
            }
        }
    },
    methods: {
        openQualificationDetails() {
            if (this.qualification.type_id != 1 && this.qualification.type_id != 2 && this.qualification.type_id != 3 && this.qualification.code.toLowerCase() !== 'c9') {
                this.qualificationDetailsModal = true
            }
        }
    }
}
</script>