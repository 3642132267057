<template>
    <div class="relative group">
        <div class="cursor-pointer relative align-middle">
            <!-- Clickable area -->
            <div class="flex items-center" :class="textColorClass" @click.prevent="active = !active">
                <!-- Icon -->
                <div class="flex mr-4">
                    <font-awesome-icon class="h-5 w-5" :icon="icon" />
                </div>
                <!-- Title -->
                <div class="flex w-11/12">
                    <div class="text-primary-dark font-medium" :class="textColorClass">{{ title }}</div>
                </div>
                <!-- Chevron icon -->
                <div v-if="!active" class="w-auto ml-2 sm:ml-auto py-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5" viewBox="0 0 24 24">
                        <g id="Raggruppa_10773" data-name="Raggruppa 10773" transform="translate(-1589.338 -1485)">
                            <rect id="Path" width="24" height="24" transform="translate(1589.338 1485)" fill="none"/>
                            <path id="chevron-down-Filled_1_" d="M944.707,2705.707l-9,9a1,1,0,0,1-1.414,0l-9-9a1,1,0,0,1,1.414-1.414l8.293,8.293,8.293-8.293a1,1,0,1,1,1.414,1.414Z" transform="translate(666.338 -1212)" fill="currentColor"/>
                        </g>
                    </svg>
                </div>
                <div v-else class="w-auto ml-2 sm:ml-auto py-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5" viewBox="0 0 24 24">
                        <g id="Raggruppa_10772" data-name="Raggruppa 10772" transform="translate(-1541.338 -1485)">
                            <rect id="Path" width="24" height="24" transform="translate(1541.338 1485)" fill="none"/>
                            <path id="chevron-up-Filled_1_" d="M896.707,2713.707a1,1,0,0,1-1.414,0L887,2705.414l-8.293,8.293a1,1,0,0,1-1.414-1.414l9-9a1,1,0,0,1,1.414,0l9,9A1,1,0,0,1,896.707,2713.707Z" transform="translate(666.338 -1212)" fill="currentColor"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <!-- Content -->
        <transition
            enter-active-class="transition ease-out duration-500"
            leave-active-class="transition ease-in duration-200"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div v-if="active">
                <slot name="content" class="w-full"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Accordion",
    props: [
        'textColorClass', // CSS class for text color
        'title', // Title of the accordion
        'icon', // Icon for the accordion
        'isActive' // Whether the accordion is initially active or not
    ],
    data() {
        return {
            active: false, // Whether the accordion is currently active or not
        }
    },
    created(){
        this.active = this.isActive // Set the initial active state based on the 'isActive' prop
    }
}
</script>