<template>
    <input class="hidden" ref="uploadInput" type="file" name="attachment" @change="uploadFile" />
    <div class="flex flex-col mt-4">
        <div class="flex items-center justify-center">
            <button class="w-52" :class="attachment ? 'btn-disabled' : 'btn-primary'" role="button" @click="showFileChooser"
                :disabled="attachment">
                Carica file
            </button>
        </div>
        <div v-if="file" class="flex items-center justify-center mt-6">
            <div class="flex w-60 sm:w-auto sm:max-w-xs lg:max-w-sm pr-4 items-center justify-center">
                <div class="text-primary-dark truncate">{{ file.name }}</div>
                <div class="ml-2" @click="removeFile">
                    <font-awesome-icon class="h-4 cursor-pointer text-primary hover:text-primary-dark"
                        icon="fas fa-circle-xmark" title="Rimuovi il file" />
                </div>
            </div>
        </div>
        <div v-if="attachment" class="flex items-center justify-center mt-6">
            <div class="flex w-60 sm:w-auto sm:max-w-xs lg:max-w-sm pr-4 mb-4 lg:mb-0 items-center justify-center">
                <div class="underline underline-offset-2 text-primary hover:text-primary-dark truncate cursor-pointer"
                    @click="downloadAttachment">{{ attachment.original_filename }}</div>
                <div class="ml-2" @click="deleteAttachment">
                    <font-awesome-icon class="h-4 cursor-pointer text-primary hover:text-primary-dark"
                        icon="fas fa-circle-xmark" title="Rimuovi il file" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services';
export default {
    name: "Attachment",
    emits: ['fileAdded', 'reloadList', 'removeFile'],
    props: {
        attachment: Object
    },
    data: () => ({
        file: null,
    }),
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        showFileChooser() {
            this.$refs.uploadInput.click()
        },
        uploadFile(e) {
            e.preventDefault();
            var file;
            if (e.dataTransfer) {
                file = e.dataTransfer.files[0];
            } else {
                file = e.target.files[0];
            }
            e.target.value = "";
            this.file = file
            this.$emit('fileAdded', file)
        },
        async downloadAttachment() {
            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.getMMGAttachment(this.attachment.attach_id)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.getPLSAttachment(this.attachment.attach_id)
                }
            } catch (error) {
                this.$swal({
                    title: "Errore durante il download",
                    text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
                })
            }
        },
        async deleteAttachment() {
            if (this.attachment) {
                try {
                    this.$swal({
                        title: 'Eliminare il file',
                        text: 'Eliminando il file non sarà più possibile recuperarlo',
                        showCancelButton: true,
                        confirmButtonText: 'Elimina',
                        cancelButtonText: 'Annulla'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                if (this.statusStore.type.toLowerCase() === 'mmg') {
                                    await Services.deleteMMGAttachment(this.attachment.attach_id)
                                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                                    await Services.deletePLSAttachment(this.attachment.attach_id)
                                }
                                this.$emit('reloadList')
                            } catch (error) {
                                this.$swal({
                                    title: "Errore nell'invio dei dati",
                                    text: "Si è verificato un errore nell'invio dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                                })
                            }
                        }
                    })
                } catch (e) {
                    this.$swal({
                        title: "Errore nell'invio dei dati",
                        text: "Si è verificato un errore nell'invio dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                    })
                }
            }
        },
        removeFile() {
            this.file = null
            this.$emit('removeFile')
        }
    },
}
</script>