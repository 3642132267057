<template>
    <div class="flex flex-wrap md:flex-nowrap h-24 lg:h-10 bg-primary justify-end pr-4 border-t border-white">
        <div class="flex pl-2 lg:px-auto items-center justify-center text-white">Assistenza: 0874619314 / 0874619310 - graduatoriemmgpls@regione.molise.it</div>
        <div class="flex w-full mt-2 sm:mt-0 lg:w-1/3 items-center justify-end" @click="goToMD">
            <div class="flex items-center cursor-pointer" @click="goToMD">
                <img class="h-6 mr-2" src="@/assets/images/iconMd.png" alt="Molise Dati homepage"/>
                <div class="text-white">{{ '© ' + $moment(new Date()).format('YYYY') + ' Molise Dati S.p.A.' }} </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    methods:{
        goToMD () {
            window.open('https://www.molisedati.it', '_blank')
        }
    }
}
</script>