import {
    RankingsRequest,
    AuthRequest,
} from "@/services/request";

import { getActivePinia } from 'pinia'

class Services {
    static untilSecondsFromNow(seconds) {
        const date = new Date(seconds * 1000);
        return date.toUTCString();
    }

    // N.B. il signin e il signout spid (dottori) viene fatto lato server...dal client si fa solo un redirect
    
    // signin LDAP
    
    static async signin(username, password, remember = false) {
        const response = await AuthRequest.post({
            path: "session/login",
            data: {
                username,
                password,
                grant_type: "password",
                scope: "*",
            },
            withCredentials: true,
        });
       
        return response;
    }

    // unica refresh token per spid e ldap

    static async refreshAuthToken() {
        const response = await AuthRequest.post({
            path: "session/refresh",
            data: {
                grant_type: "refresh_token",
                scope: "*",
            },
            withCredentials: true,
        });
        
        return response;
    }

    // signout LDAP

    static async signOut() {

        await AuthRequest.get({
            path: "session/logout",
            withCredentials: true,
        });

        const pinia = getActivePinia()
        pinia._s.forEach((store) => store.$reset())

    }

    //startAPP

    static init() {
        return RankingsRequest.get({
            path: `user/init`
        });
    }

    // varie per select, checkbox e radio

    static getCities() {
        return RankingsRequest.get({
            path: `cities`,
        });
    }

    static getProvinces() {
        return RankingsRequest.get({
            path: `provinces`,
        });
    }

    static getRegions() {
        return RankingsRequest.get({
            path: `regions`,
        });
    }

    static getSectors() {
        return RankingsRequest.get({
            path: `sectors`,
        })
    }

    static getDegreeScales() {
        return RankingsRequest.get({
            path: `degree-scales`,
        })
    }

    static getPlsSpecScales() {
        return RankingsRequest.get({
            path: `spec-scales`,
        })
    }

    static getMMGQualificationStudyTitles() {
        return RankingsRequest.get({
            path: `mmg/ac-qual/titles`,
        })
    }

    static getPLSQualificationStudyTitles() {
        return RankingsRequest.get({
            path: `pls/ac-qual/titles`,
        })
    }
    
    static getMMGJobTitles() {
        return RankingsRequest.get({
            path: `mmg/job-qual/titles`,
        })
    }

    static getPLSJobTitles() {
        return RankingsRequest.get({
            path: `pls/job-qual/titles`,
        })
    }

    // lista delle domande presentate per MMG o PLS

    static getDoctorRequests() {
        return RankingsRequest.get({
            path: `doctor/requests`,
        })
    }

    // ******************** MMG ************************* 

    // rest per la richiesta

    static getMMGRequest() {
        return RankingsRequest.get({
            path: `mmg/request`,
        })
    }

    static postMMGRequest(data) {
        return RankingsRequest.post({
            path: `mmg/request`,
            data
        })
    }

    static updateMMGRequest(data) {
        return RankingsRequest.put({
            path: `mmg/request`,
            data
        })
    }

    // rest per i dati personali

    static getMMGContacts() {
        return RankingsRequest.get({
            path: `mmg/request/contacts`,
        })
    }

    static updateMMGContacts(data) {
        return RankingsRequest.put({
            path: `mmg/request/contacts`,
            data
        })
    }

    static checkMMGMandatoryContacts() {
        return RankingsRequest.get({
            path: `mmg/request/check`,
        })
    }

    // rest per l'autocertificazione

    static getMMGSelfCertifications() {
        return RankingsRequest.get({
            path: `mmg/request/self-certifications`,
        })
    }

    static updateMMGSelfCertifications(data) {
        return RankingsRequest.put({
            path: `mmg/request/self-certifications`,
            data
        })
    }

    static checkMMGMandatorySelfCertifications() {
        return RankingsRequest.get({
            path: `mmg/request/self-certifications/check`,
        })
    }

    // rest per i titoli di studio

    static getMMGMandatoryQualifications() {
        return RankingsRequest.get({
            path: `mmg/ac-qual/mandatory`,
        })
    }

    static updateMMGMandatoryQualifications(data) {
        return RankingsRequest.post({
            path: `mmg/ac-qual/mandatory`,
            data
        })
    }

    static getMMGQualifications() {
        return RankingsRequest.get({
            path: `mmg/ac-qualifications`,
        })
    }

    static postMMGQualification(data) {
        return RankingsRequest.post({
            path: `mmg/ac-qual`,
            data
        })
    }

    static updateMMGQualification(data, id) {
        return RankingsRequest.put({
            path: `mmg/ac-qual/${id}`,
            data
        })
    }

    static deleteMMGQualification(id) {
        return RankingsRequest.delete({
            path: `mmg/ac-qual/${id}`,
        })
    }

    static checkMMGMandatoryQualifications() {
        return RankingsRequest.get({
            path: `mmg/ac-qual/check`,
        })
    }

    // rest per i titoli di servizio

    static getMMGJobs() {
        return RankingsRequest.get({
            path: `mmg/job-qualifications`,
        })
    }

    static postMMGJob(data) {
        return RankingsRequest.post({
            path: `mmg/job-qual`,
            data
        })
    }

    static updateMMGJob(data, id) {
        return RankingsRequest.put({
            path: `mmg/job-qual/${id}`,
            data
        })
    }

    static deleteMMGJob(id) {
        return RankingsRequest.delete({
            path: `mmg/job-qual/${id}`,
        })
    }

    static checkMMGMandatoryJobs() {
        return RankingsRequest.get({
            path: `mmg/job-qual/check`,
        })
    }

    // rest per gli allegati

    static postMMGAttachments(data) {
        const fd = new FormData();
        fd.append("identityFront", data.identityFront);
        fd.append("identityRear", data.identityRear);
        if(data.qce) fd.append("qce", data.qce);
        if(data.ecdl) fd.append("ecdl", data.ecdl);
        if(data.academics && data.academics.length > 0){
            for (var i = 0; i < data.academics.length; i++) {
                fd.append('academics[]', data.academics[i]);
            }
        }
        if(data.jobs && data.jobs.length > 0) {
            for (var i = 0; i < data.jobs.length; i++) {
                fd.append('jobs[]', data.jobs[i]);
            }
        }

        return RankingsRequest.post({
            path: `mmg/attachments`,
            data: fd
        })
    }

    static getMMGAttachments() {
        return RankingsRequest.get({
            path: `mmg/attachments`,
        })
    }

    static deleteMMGAttachment(id) {
        return RankingsRequest.delete({
            path: `mmg/attachment/${id}`,
        })
    }

    static getMMGAttachment(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/mmg/attachment/${id}/download`)
    }

    static checkMMGMandatoryAttachments() {
        return RankingsRequest.get({
            path: `mmg/attachment/check`,
        })
    }

    // rest dello step finale
    
    static getMMGEndStep() {
        return RankingsRequest.get({
            path: `mmg/request/receipt`,
        })
    }

    static postMMGEndStep(data) {
        const fd = new FormData();
        fd.append("privacy", data.privacy);
        fd.append("receipt", data.receipt);

        return RankingsRequest.post({
            path: `mmg/request/receipt`,
            data: fd
        })
    }

    static MMGSend() {
        return RankingsRequest.put({
            path: `mmg/request/send`
        })
    }

    static MMGRecap(){
        return RankingsRequest.get({
            path: `mmg/request/recap`,
        })
    }

    static MMGUploadRecap(data){
        const fd = new FormData();
        fd.append("recap", data.pdf);
        return RankingsRequest.post({
            path: `mmg/request/recap`,
            data: fd
        })
    }

    // PLS

    // rest per la richiesta

    static getPLSRequest() {
        return RankingsRequest.get({
            path: `pls/request`,
        })
    }

    static postPLSRequest(data) {
        return RankingsRequest.post({
            path: `pls/request`,
            data
        })
    }

    static updatePLSRequest(data) {
        return RankingsRequest.put({
            path: `pls/request`,
            data
        })
    }

    // rest per i dati personali

    static getPLSContacts() {
        return RankingsRequest.get({
            path: `pls/request/contacts`,
        })
    }

    static updatePLSContacts(data) {
        return RankingsRequest.put({
            path: `pls/request/contacts`,
            data
        })
    }

    static checkPLSMandatoryContacts() {
        return RankingsRequest.get({
            path: `pls/request/check`,
        })
    }

    // rest per l'autocertificazione

    static getPLSSelfCertifications() {
        return RankingsRequest.get({
            path: `pls/request/self-certifications`,
        })
    }

    static updatePLSSelfCertifications(data) {
        return RankingsRequest.put({
            path: `pls/request/self-certifications`,
            data
        })
    }

    static checkPLSMandatorySelfCertifications() {
        return RankingsRequest.get({
            path: `pls/request/self-certifications/check`,
        })
    }

    // rest per i titoli di studio

    static getPLSMandatoryQualifications() {
        return RankingsRequest.get({
            path: `pls/ac-qual/mandatory`,
        })
    }

    static updatePLSMandatoryQualifications(data) {
        return RankingsRequest.post({
            path: `pls/ac-qual/mandatory`,
            data
        })
    }

    static getPLSQualifications() {
        return RankingsRequest.get({
            path: `pls/ac-qualifications`,
        })
    }

    static postPLSQualification(data) {
        return RankingsRequest.post({
            path: `pls/ac-qual`,
            data
        })
    }

    static updatePLSQualification(data, id) {
        return RankingsRequest.put({
            path: `pls/ac-qual/${id}`,
            data
        })
    }

    static deletePLSQualification(id) {
        return RankingsRequest.delete({
            path: `pls/ac-qual/${id}`,
        })
    }

    static checkPLSMandatoryQualifications() {
        return RankingsRequest.get({
            path: `pls/ac-qual/check`,
        })
    }

      // rest per i titoli di servizio

      static getPLSJobs() {
        return RankingsRequest.get({
            path: `pls/job-qualifications`,
        })
    }

    static postPLSJob(data) {
        return RankingsRequest.post({
            path: `pls/job-qual`,
            data
        })
    }

    static updatePLSJob(data, id) {
        return RankingsRequest.put({
            path: `pls/job-qual/${id}`,
            data
        })
    }

    static deletePLSJob(id) {
        return RankingsRequest.delete({
            path: `pls/job-qual/${id}`,
        })
    }

    static checkPLSMandatoryJobs() {
        return RankingsRequest.get({
            path: `pls/job-qual/check`,
        })
    }

    // rest per gli allegati

    static postPLSAttachments(data) {
        const fd = new FormData();
        fd.append("identityFront", data.identityFront);
        fd.append("identityRear", data.identityRear);
        if(data.qce) fd.append("qce", data.qce);
        if(data.ecdl) fd.append("ecdl", data.ecdl);
        if(data.academics && data.academics.length > 0){
            for (var i = 0; i < data.academics.length; i++) {
                fd.append('academics[]', data.academics[i]);
            }
        }
        if(data.jobs && data.jobs.length > 0) {
            for (var i = 0; i < data.jobs.length; i++) {
                fd.append('jobs[]', data.jobs[i]);
            }
        }

        return RankingsRequest.post({
            path: `pls/attachments`,
            data: fd
        })
    }

    static getPLSAttachments() {
        return RankingsRequest.get({
            path: `pls/attachments`,
        })
    }

    static deletePLSAttachment(id) {
        return RankingsRequest.delete({
            path: `pls/attachment/${id}`,
        })
    }

    static getPLSAttachment(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/pls/attachment/${id}/download`)
    }

    static checkPLSMandatoryAttachments() {
        return RankingsRequest.get({
            path: `pls/attachment/check`,
        })
    }

    // rest dello step finale
    
    static getPLSEndStep() {
        return RankingsRequest.get({
            path: `pls/request/receipt`,
        })
    }

    static postPLSEndStep(data) {
        const fd = new FormData();
        fd.append("privacy", data.privacy);
        fd.append("receipt", data.receipt);

        return RankingsRequest.post({
            path: `pls/request/receipt`,
            data: fd
        })
    }

    static PLSSend() {
        return RankingsRequest.put({
            path: `pls/request/send`
        })
    }

    static PLSRecap(){
        return RankingsRequest.get({
            path: `pls/request/recap`,
        })
    }

    static PLSUploadRecap(data){
        const fd = new FormData();
        fd.append("recap", data.pdf);
        return RankingsRequest.post({
            path: `pls/request/recap`,
            data: fd
        })
    }

    // ADMINISTRATION

    // MMG

    static getMMGApplicationList(){
        return RankingsRequest.get({
            path: `mmg/requests`,
        })
    }

    static getMMGApplicationDetails(id){
        return RankingsRequest.get({
            path: `mmg/request/${id}`,
        })
    }

    static MMGAcceptApplication(id) {
        return RankingsRequest.put({
            path: `mmg/request/${id}/accept`
        })
    }

    static MMGRefuseApplication(id, data) {
        return RankingsRequest.put({
            path: `mmg/request/${id}/refuse`,
            data
        })
    }
    
    static MMGAcceptReserveApplication(id, data) {
        return RankingsRequest.put({
            path: `mmg/request/${id}/accept-reserve`,
            data
        })
    }

    static MMGReopenApplication(id) {
        return RankingsRequest.put({
            path: `mmg/request/${id}/reopen`
        })
    }

    static MMGAcceptAllApplications() {
        return RankingsRequest.put({
            path: `mmg/request/accept-all`
        })
    }

    static MMGMakeTemporaryRanking() {
        return RankingsRequest.post({
            path: `mmg/temporary/ranking`
        })
    }

    static MMGMakeFinalRanking() {
        return RankingsRequest.post({
            path: `mmg/final/ranking`
        })
    }

    static MMGGetTemporaryRanking() {
        return RankingsRequest.get({
            path: `mmg/temporary/ranking`
        })
    }

    static MMGGetFinalRanking() {
        return RankingsRequest.get({
            path: `mmg/final/ranking`
        })
    }

    static downloadMMGRanking(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/mmg/ranking/${id}/download`)
    }

    static downloadMMGCalculationLog(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/mmg/request/${id}/calculation-log/download`)
    }

    static MMGUserRecap(reqId){
        return RankingsRequest.get({
            path: `mmg/request/recap/${reqId}`,
        })
    }

    static MMGUploadUserRecap(data){
        const fd = new FormData();
        fd.append("recap", data.pdf);
        return RankingsRequest.post({
            path: `mmg/request/recap/${data.reqId}`,
            data: fd
        })
    }

    //PLS

    static getPLSApplicationList(){
        return RankingsRequest.get({
            path: `pls/requests`,
        })
    }

    static getPLSApplicationDetails(id){
        return RankingsRequest.get({
            path: `pls/request/${id}`,
        })
    }

    static PLSAcceptApplication(id) {
        return RankingsRequest.put({
            path: `pls/request/${id}/accept`
        })
    }

    static PLSRefuseApplication(id, data) {
        return RankingsRequest.put({
            path: `pls/request/${id}/refuse`,
            data
        })
    }

    static PLSAcceptReserveApplication(id, data) {
        return RankingsRequest.put({
            path: `pls/request/${id}/accept-reserve`,
            data
        })
    }

    static PLSReopenApplication(id) {
        return RankingsRequest.put({
            path: `pls/request/${id}/reopen`
        })
    }

    static PLSAcceptAllApplications() {
        return RankingsRequest.put({
            path: `pls/request/accept-all`
        })
    }

    static PLSMakeTemporaryRanking() {
        return RankingsRequest.post({
            path: `pls/temporary/ranking`
        })
    }

    static PLSMakeFinalRanking() {
        return RankingsRequest.post({
            path: `pls/final/ranking`
        })
    }

    static PLSGetTemporaryRanking() {
        return RankingsRequest.get({
            path: `pls/temporary/ranking`
        })
    }

    static PLSGetFinalRanking() {
        return RankingsRequest.get({
            path: `pls/final/ranking`
        })
    }

    static downloadPLSRanking(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/pls/ranking/${id}/download`)
    }

    static downloadPLSCalculationLog(id) {
        window.open(`${process.env.VUE_APP_API_BASE_URL}/pls/request/${id}/calculation-log/download`)
    }

    static PLSUserRecap(reqId){
        return RankingsRequest.get({
            path: `pls/request/recap/${reqId}`,
        })
    }

    static PLSUploadUserRecap(data){
        const fd = new FormData();
        fd.append("recap", data.pdf);
        return RankingsRequest.post({
            path: `pls/request/recap/${data.reqId}`,
            data: fd
        })
    }
}

export default Services;
