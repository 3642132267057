<template>
    <div class="flex bg-primary" :class="$route.name === 'Home' || $route.name === 'ApplicationRequest' ? '' : 'h-4'">
        <div v-if="$route.name === 'Home' || $route.name === 'ApplicationRequest'"
            class="text-lg font-medium cursor-pointer text-white hover:text-gray-300 py-2 ml-auto mr-6"
            @click="$router.push('/application-list')">Storico domande</div>
    </div>
    <div class="flex h-2">
        <div class="w-1/3 bg-green-flag"></div>
        <div class="w-1/3 bg-white"></div>
        <div class="w-1/3 bg-red-flag"></div>
    </div>
    <div class="flex flex-wrap md:flex-nowrap bg-primary h-36">
        <div class="flex items-center py-4 md:py-0 pr-4 md:pr-0">
            <img class="h-18 mx-6" src="@/assets/images/logoRegioneMolise.png" />
            <div class="text-white text-lg sm:text-2xl font-medium">Graduatorie MMG/PLS</div>
        </div>
        <div class="flex flex-col sm:w-auto flex-grow items-end justify-center group pr-6 py-2 md:py-0">
            <div v-if="$route.meta.auth" class="flex mt-1 items-center cursor-pointer text-white group-hover:text-gray-300"
                @click="logout">
                <font-awesome-icon class="h-4 md:h-5 w-4 md:w-5 mr-2" icon="fas fa-right-from-bracket" />
                Esci
            </div>
        </div>
    </div>
</template>

<script>
import Services from '@/services'
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { mapStores } from 'pinia'

export default {
    name: "TopBar",
    computed: {
        ...mapStores(useApplicationRequestStore)
    },
    methods: {
        logout() {
            window.open(process.env.VUE_APP_AUTH_API_BASE_URL + '/spid/logout', '_self')
        }
    }
}
</script>