<template>
    <div class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary items-center cursor-pointer hover:bg-gray-100"
        :class="isLast ? 'rounded-b' : 'mb-4 lg:mb-0'" @click="jobDetailsModal = true">
        <!-- <div class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 lg:justify-center p-2 order-1 border-r lg:border-r-0 border-primary lg:border-0">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
            <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
                :class="job.status.toLowerCase() === 'rifiutato' ? 'bg-red-flag' : job.status.toLowerCase() === 'accettato' ? 'bg-green-flag' : 'bg-orange-500'">
                {{ job.status }}</div>
        </div> -->
        <div class="flex flex-col w-full lg:w-full border-primary p-2">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Titolo di servizio</div>
            <div class="flex lg:mb-2">{{ job.title }}</div>
            <div v-if="job.region" class="flex">
                <div class="text-primary-dark">Regione: </div>
                <div class="font-semibold ml-2">{{ job.region }}</div>
            </div>
        </div>
        <!-- <div class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 p-2 lg:items-center lg:justify-center order-2 lg:order-3">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Operazioni</div>
            <div class="flex mx-auto lg:mx-0">
                <font-awesome-icon class="h-6 mr-6 lg:mr-4 text-primary hover:text-primary-dark cursor-pointer"
                    icon="fas fa-edit" alt="Modifica" title="Modifica" @click="modifyJobModal = true" />
                <font-awesome-icon class="h-6 text-red-logo hover:text-red-logo-dark cursor-pointer" icon="fas fa-trash"
                    alt="Elimina" title="Elimina" @click="deleteJob"/>
            </div>
        </div> -->
    </div>
    <Modal :active="jobDetailsModal" title="Modifica Titolo di Servizio"
        card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <AdminJobDetails @close-add-job-modal="jobDetailsModal = false" :job="job" />
            </div>
        </template>
    </Modal>
</template>

<script>
import AdminJobDetails from '@/components/administrator/AdminJobDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import Services from '@/services'

export default {
    name: 'AdminJobSummary',
    props: {
        job: Object,
        isLast: Boolean
    },
    components: { Modal, AdminJobDetails, Spinner },
    data() {
        return {
            jobDetailsModal: false,
        }
    },
}
</script>