import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'
import { useUiStore } from '@/store/ui.js'
import { useStatusStore } from '@/store/status.js'
import Services from '@/services'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {

    const isValidRoute = routes.filter(item => item.name === to.name);

    const uiStore = useUiStore()
    const statusStore = useStatusStore()

    const authorized = $cookies.get('mmg-pls-session-token')

    if (isValidRoute.length <= 0) {
        uiStore.setLoadingStatus(false)
        return next('/')
    }

    if (to.name === 'Signin' || to.name === 'Landing') {
        if (authorized) {
            uiStore.setLoadingStatus(false)
            return next("/home");
        }
        try {
            await Services.refreshAuthToken();
            uiStore.setLoadingStatus(false)
            return next("/home");
        } catch (_) {
            uiStore.setLoadingStatus(false)
            return next();
        }
    }

    if (to.meta.auth) {
        try {
            if (!authorized) {
                try {
                    await Services.refreshAuthToken();
                    uiStore.setLoadingStatus(false)
                } catch (e) {
                    uiStore.setLoadingStatus(false)
                    return next('/');
                }
            }

            const { data } = await Services.init();

            if (data.isAdmin) {
                statusStore.setMmgStartLine(data.mmgStartline)
                statusStore.setMmgDeadLine(data.mmgDeadline)
                statusStore.setPlsStartLine(data.plsStartline)
                statusStore.setPlsDeadLine(data.plsDeadline)
                statusStore.setYear(data.year)
                if(data.mmgTempRanking) statusStore.setMmgTempRanking(data.mmgTempRanking)
                if(data.mmgFinalRanking) statusStore.setMmgFinalRanking(data.mmgFinalRanking)
                if(data.mmgRankingTypeId) statusStore.setMmgRankingTypeId(data.mmgRankingTypeId)
                if(data.plsTempRanking) statusStore.setPlsTempRanking(data.plsTempRanking)
                if(data.plsFinalRanking) statusStore.setPlsFinalRanking(data.plsFinalRanking)
                if(data.plsRankingTypeId) statusStore.setPlsRankingTypeId(data.plsRankingTypeId)
            } else {  
                if(data.mmgLastApplicationYear){statusStore.setMmgLastApplicationYear(data.mmgLastApplicationYear)}
                if(data.plsLastApplicationYear){statusStore.setPlsLastApplicationYear(data.plsLastApplicationYear)}
                if(data.type){statusStore.setType(data.type.toLowerCase())}
                statusStore.setNextStep(data.nextStep)
                statusStore.setMmgStartLine(data.mmgStartline)
                statusStore.setMmgDeadLine(data.mmgDeadline)
                statusStore.setPlsStartLine(data.plsStartline)
                statusStore.setPlsDeadLine(data.plsDeadline)
                statusStore.setYear(data.year)
                statusStore.setStatus(data.status)
                statusStore.setStatusId(data.statusId)
                statusStore.setNewEntry(data.newEntry)
            }

            if (to.meta.isAdmin && !data.isAdmin) {
                uiStore.setLoadingStatus(false)
                return next('/home');
            }

            if (to.name === 'Home') {
                if (from.name === 'ApplicationRequest') {
                    uiStore.setLoadingStatus(false)
                    return next();
                }
                if (data.isAdmin) {
                    uiStore.setLoadingStatus(false)
                    return next('/adminhome');
                }
                if (data.statusId && (data.statusId != '1' && data.statusId != '6')) {
                    return next('/application-list');
                }

                if (data.nextStep > 0) {
                    return next('/application-request');
                }
            }

            if (to.name === 'ApplicationRequest') {
                if (data.isAdmin) {
                    uiStore.setLoadingStatus(false)
                    return next('/adminhome');
                }
                if (data.statusId && (data.statusId != '1' && data.statusId != '6')) {
                    return next('/application-list');
                }
            }

            uiStore.setLoadingStatus(false)
            return next();

        } catch (e) {
            if (e.response.status !== 401) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio.",
                })
                return next("/");
            }
            uiStore.setLoadingStatus(false)
            return next("/");
        }
    }

    return next()
})

export default router;
