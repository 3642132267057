<template>
    <div class="flex flex-col">
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Titolo di servizio <span class="text-red-flag">*</span>
                    <v-select v-model="jobEntitlementId" class="w-full text-sm mt-1" :options="jobEntitlements"
                        label="title" placeholder="Seleziona il titolo di servizio" :reduce="entitl => entitl.title_id"
                        :searchable="true" :clearable="false" disabled />
                </label>
            </div>
        </div>
        <div v-if="isOutherRegion" class="flex mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Regione <span class="text-red-flag">*</span>
                    <v-select v-model="region" class="w-full text-sm mt-1.5" :options="regions" label="region"
                        placeholder="Seleziona una regione" :reduce="region => region.region" :searchable="true" disabled />
                </label>
            </div>
        </div>
        <!-- start MMG section -->
        <div v-if="jobEntitlementId && $route.params.type.toLowerCase() === 'mmg'" class="flex flex-col mt-4 mx-2 lg:mx-4">
            <div v-for="(item, index) in jobTimesheets" :key="index" class="flex flex-col">
                <div class="flex flex-wrap md:flex-nowrap pb-4 border-b border-primary md:pb-0 md:border-none">
                    <div class="mt-4 pr-2"
                        :class="!isMonthly ? 'w-1/2 md:w-3/12' : specifyASL ? 'w-1/2 md:w-3/12' : 'w-1/2'">
                        <label>Inizio periodo <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input" v-model="item['start']" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()"
                                disabled>
                            </Datepicker>
                        </label>
                    </div>
                    <div class="mt-4 pr-2"
                        :class="!isMonthly ? 'w-1/2 md:w-3/12' : specifyASL ? 'w-1/2 md:w-3/12' : 'w-1/2'">
                        <label>Fine periodo <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input" v-model="item['end']" class="w-full text-sm mt-1"
                                modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                                cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()"
                                disabled>
                            </Datepicker>
                        </label>
                    </div>
                    <div class="mt-4 pr-2" :class="!isMonthly ? 'w-2/12 md:w-1/12' : 'hidden'">
                        <label>Ore <span class="text-red-flag">*</span>
                            <input type="number" placeholder="Ore" min="0"
                                class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                v-model="item['hours']" disabled />
                        </label>
                    </div>
                    <div class="mt-4 pr-2" :class="specifyASL ? 'w-9/12 md:w-5/12' : 'hidden'">
                        <label>Presso <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Presso"
                                class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                v-model="item['asl']" disabled />
                        </label>
                    </div>
                    <!-- <div class="flex w-1/12 items-end justify-center mx-auto pb-2">
                        <font-awesome-icon class="h-4 md:h-5 w-4 md:w-5 text-primary-dark mr-2 cursor-pointer"
                            icon="fas fa-circle-plus" @click="addJobTimesheet" />
                        <font-awesome-icon v-if="index !== 0"
                            class="h-4 md:h-5 w-4 md:w-5 text-primary-dark mr-2 cursor-pointer" icon="fas fa-trash"
                            @click="removeJobTimesheet(index)" />
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end MMG section -->
        <!-- start PLS section -->
        <div v-if="jobEntitlementId && $route.params.type.toLowerCase() === 'pls'" class="flex flex-col mt-4 mx-2 lg:mx-4">
            <div v-for="(item, index) in jobTimesheets" :key="index" class="flex flex-col">
                <div class="flex flex-wrap md:flex-nowrap pt-4 pb-10 border-primary"
                    :class="index > 0 ? 'border-t' : 'border-t-0'">
                    <div class="flex flex-col w-full">
                        <div class="flex flex-wrap md:flex-nowrap">
                            <div class="mt-4 pr-2" :class="!isMonthly ? 'w-10/12 md:w-5/12' : 'w-10/12 md:w-1/2'">
                                <label>Inizio periodo <span class="text-red-flag">*</span>
                                    <Datepicker inputClassName="dp-custom-input" v-model="item['start']"
                                        class="w-full text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa"
                                        locale="it" selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false"
                                        format="dd/MM/yyyy" :max-date="new Date()" disabled>
                                    </Datepicker>
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="!isMonthly ? 'w-10/12 md:w-5/12' : 'w-10/12 md:w-1/2'">
                                <label>Fine periodo <span class="text-red-flag">*</span>
                                    <Datepicker inputClassName="dp-custom-input" v-model="item['end']"
                                        class="w-full text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa"
                                        locale="it" selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false"
                                        format="dd/MM/yyyy" :max-date="new Date()" disabled>
                                    </Datepicker>
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="!isMonthly ? 'w-10/12 md:w-2/12' : 'hidden'">
                                <label>Ore <span class="text-red-flag">*</span>
                                    <input type="number" placeholder="Ore" min="0"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['hours']" disabled />
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-wrap md:flex-nowrap">
                            <div class="mt-4 pr-2" :class="specifyASL ? 'w-10/12 md:w-6/12' : 'hidden'">
                                <label>Presso <span class="text-red-flag">*</span>
                                    <input type="text" placeholder="Presso"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['asl']" disabled />
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="specifyFacility ? 'w-10/12 md:w-6/12' : 'hidden'">
                                <label>Struttura <span class="text-red-flag">*</span>
                                    <input type="text" placeholder="Struttura"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['asl']" disabled />
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="specifyPediatrician ? 'w-10/12 md:w-6/12' : 'hidden'">
                                <label>Pediatra <span class="text-red-flag">*</span>
                                    <input type="text" placeholder="Pediatra"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['doctor']" disabled />
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="specifyDoctor ? 'w-10/12 md:w-6/12' : 'hidden'">
                                <label>Medico <span class="text-red-flag">*</span>
                                    <input type="text" placeholder="Medico"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['doctor']" disabled />
                                </label>
                            </div>
                            <div class="mt-4 pr-2" :class="specifyActivity ? 'w-10/12 md:w-6/12' : 'hidden'">
                                <label>Attività <span class="text-red-flag">*</span>
                                    <input type="text" placeholder="Attività"
                                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                                        v-model="item['activity']" disabled />
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex md:w-1/12 items-end justify-center mx-auto pb-2 mt-8 md:mt-0">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark cursor-pointer"
                            icon="fas fa-circle-plus" @click="addJobTimesheet" />
                        <font-awesome-icon v-if="index !== 0"
                            class="h-5 w-5 text-primary-dark ml-4 mr-2 cursor-pointer" icon="fas fa-trash"
                            @click="removeJobTimesheet(index)" />
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end PLS section -->
    </div>
    <div class="py-5 flex w-full justify-around my-8">
        <button class="-btn-primary px-4 uppercase" @click="$emit('closeAddJobModal', false)">
            Chiudi
        </button>
    </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'AdminJobDetails',
    emits: ["closeAddJobModal"],
    props: {
        job: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            jobEntitlements: [],
            jobEntitlementId: null,
            regions: [],
            region: '',
            jobTimesheets: [{
                start: null,
                end: null,
                hours: null,
                asl: '',
            }],

            loading: false,
        }
    },
    async created() {
        await this.getJobTitles()
        await this.getRegions()
        if (this.job) {
            this.jobEntitlementId = this.job.title_id,
                this.region = this.job.region,
                this.jobTimesheets = this.job.periods.map((period) => ({ ...period, start: this.$moment(period.start, 'YYYY-MM-DD').format('DD/MM/YYYY'), end: this.$moment(period.end, 'YYYY-MM-DD').format('DD/MM/YYYY') }))
        }
    },
    computed: {
        ...mapStores(useStatusStore),
        isOutherRegion() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.outher_region === '1') {
                    return true
                }
                return false
            }
        },
        isMonthly() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.type_id === '2') {
                    return true
                }
                return false
            }
        },
        specifyASL() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.enter_asl === '1') {
                    return true
                }
                return false
            }
        },
        specifyFacility() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.enter_facility === '1') {
                    return true
                }
                return false
            }
        },
        specifyDoctor() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.enter_doctor === '2') {
                    return true
                }
                return false
            }
        },
        specifyPediatrician() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.enter_doctor === '1') {
                    return true
                }
                return false
            }
        },
        specifyActivity() {
            if (this.jobEntitlementId) {
                var selectedEntitlement = this.jobEntitlements.find(obj => {
                    return obj.title_id === this.jobEntitlementId
                })
                if (selectedEntitlement.enter_activity === '1') {
                    return true
                }
                return false
            }
        },
    },
    methods: {
        async getJobTitles() {
            try {
                const { data } = this.$route.params.type.toLowerCase() === 'mmg' ? await Services.getMMGJobTitles() : await Services.getPLSJobTitles()
                this.jobEntitlements = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getRegions() {
            try {
                const { data } = await Services.getRegions()
                this.regions = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero delle regioni",
                    text: "Si è verificato un errore nel recupero dei dati relativi alle regioni. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        addJobTimesheet() {
            this.jobTimesheets.push(
                {
                    start: null,
                    end: null,
                    hours: null,
                    asl: '',
                }
            )
        },
        removeJobTimesheet(i) {
            this.jobTimesheets.splice(i, 1)
        },

    }
}
</script>

<style scoped>
@media screen and (max-width: 414px) {
    :deep(.dp-custom-input) {
        @apply border border-primary rounded hover:border-primary-dark hover:rounded text-xs;
    }
}</style>