<template>
    <div class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Documento d'identità
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                <img src="@/assets/images/id-front.svg" />
                <div class="mt-2 text-primary-dark cursor-pointer" @click='download(idFront)'>Visualizza il fronte
                </div>
            </div>
            <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                <img src="@/assets/images/id-back.svg" />
                <div class="mt-2 text-primary-dark cursor-pointer" @click='download(idBack)'>Visualizza il retro
                </div>
            </div>
        </div>
    </div>
    <div v-if="qce" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Certificato di conoscenza
            della lingua inglese (QCE)
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full items-center my-6">
                <img src="@/assets/images/qce-certificate.svg" />
                <div class="mt-2 text-primary-dark cursor-pointer" @click='download(qce)'>Visualizza il
                    certificato
                </div>
            </div>
        </div>
    </div>
    <div v-if="ecdl" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Patente europea per
            l'utilizzo del P.C. (ECDL)
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full items-center my-6">
                <img src="@/assets/images/ecdl-certificate.svg" />
                <div class="mt-2 text-primary-dark cursor-pointer" @click='download(ecdl)'>Visualizza il
                    certificato
                </div>
            </div>
        </div>
    </div>
    <div v-if="qualifications.length > 0" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Documenti relativi a
            titoli accademici e di studio
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full items-center my-4">
                <div v-for="(item, index) in qualifications" :key="index" class="flex w-full px-2 my-2">
                    <div class="flex mr-4">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-file" />
                    </div>
                    <div class="text-primary-dark cursor-pointer" @click='download(item)'>Visualizza il documento
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="jobs.length > 0" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Documenti relativi ai
            certificati di servizio
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full items-center my-4">
                <div v-for="(item, index) in jobs" :key="index" class="flex w-full px-2 my-2">
                    <div class="flex mr-4">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-file" />
                    </div>
                    <div class="text-primary-dark cursor-pointer" @click='download(item)'>Visualizza il documento
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="receipt" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Ricevuta del pagamento
            della marca da bollo
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex flex-col w-full items-center my-6">
                <img src="@/assets/images/receipt.svg" />
                <div class="mt-2 text-primary-dark cursor-pointer" @click='download(receipt)'>Visualizza il
                    documento
                </div>
            </div>
        </div>
    </div>
    <div v-if="recap" class="flex flex-col w-full">
        <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Riepilogo domanda
            presentata
        </div>
        <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="flex w-full px-2 my-2">
                <div class="flex mr-4">
                    <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-file" />
                </div>
                <div class="text-primary-dark cursor-pointer" @click='download(recap)'>Visualizza il documento
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Services from '@/services'

export default {
    name: 'AttachmentsSummary',
    props: {
        attachments: Array,
    },
    data: () => ({
        idFront: null,
        idBack: null,
        qce: null,
        ecdl: null,
        jobs: [],
        qualifications: [],
        receipt: null,
        recap: null,
    }),
    created() {
        for (var attachment of this.attachments) {
            switch (attachment.type_id) {
                case '1':
                    this.idFront = attachment
                    break;
                case '2':
                    this.idBack = attachment
                    break;
                case '3':
                    this.qce = attachment
                    break;
                case '4':
                    this.ecdl = attachment
                    break;
                case '5':
                    this.jobs.push(attachment)
                    break;
                case '6':
                    this.qualifications.push(attachment)
                    break;
                case '7':
                    this.receipt = attachment
                    break;
                case '8':
                    this.recap = attachment
                    break;
                default:
                    break;
            }
        }
    },
    methods: {
        async download(attachment) {
            try {
                if (attachment.mmg_id) {
                    await Services.getMMGAttachment(attachment.attach_id)
                } else if (attachment.pls_id) {
                    await Services.getPLSAttachment(attachment.attach_id)
                }
            } catch (error) {
                this.$swal({
                    title: "Errore durante il download",
                    text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
                })
            }
        }
    }
}
</script>