import AbstractRequest from './AbstractRequest';
import axios from 'axios';
import Services from '../index';

class AuthRequestClass extends AbstractRequest {
    constructor() {
        super(axios.create(), process.env.VUE_APP_AUTH_API_BASE_URL, 'rankings_token');
        this.auth_paths = ['/doc_session/logout'];
        this.isRefreshing = false;
        this.requestsQueue = [];
    }

    processQueue(error) {
        this.requestsQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve();
            }
        })
        this.requestsQueue = [];
    }

}

const AuthRequest = new AuthRequestClass();

AuthRequest.interceptResponse(function (response) {
    return response.data;
}, async function (error) {
    const originalRequest = error.config
    const path = originalRequest.url.replace(process.env.VUE_APP_AUTH_API_BASE_URL, '');
    if (!this.auth_paths.includes(path)) throw error;

    try {
        if (originalRequest.data) {
            try {
                const data = JSON.parse(originalRequest.data);
                if (data.grant_type === 'refresh_token') {
                    return Promise.reject(error);
                }
            } catch (_) {
            }

        }
    } catch {
        return Promise.reject(error);
    }
    if (error.response.status === 400 && !originalRequest.__retry) {
        if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
                this.requestsQueue.push({resolve, reject})
            }).then(() => {
                originalRequest.headers = {
                    ...originalRequest.headers,
                };
                return this.instance(originalRequest);
            }).catch(error => {
                return Promise.reject(error)
            });
        }
        originalRequest.__retry = true;
        this.isRefreshing = true;
        try {
            await Services.refreshAuthToken();
            originalRequest.headers = {
                ...originalRequest.headers,
            };
            this.processQueue();
            this.isRefreshing = false;
            return this.instance(originalRequest);
        } catch (error) {
            this.processQueue(error);
        }
    }
    throw error;
});

export default AuthRequest;
