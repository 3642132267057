<template>
    <div v-if="!generalLoading">
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Allegati</div>
            <div class="mt-4">
                <div class="flex flex-col rounded shadow-rankings border border-primary mb-6 mx-1 md:mx-2">
                    <div class="m-4 text-primary-dark font-bold">Carica il fronte e il retro di un documento di identità in
                        corso di
                        validità <span class="text-red-flag">*</span></div>
                    <div class="flex flex-wrap md:flex-nowrap justify-center">
                        <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                            <img src="@/assets/images/id-front.svg" />
                            <div class="mt-2 text-gray-500">Carica il fronte</div>
                            <Attachment :attachment="attachments.identityFront" @file-added="addIdFront"
                                @remove-file="identityFront = null" @reload-list="getAttachments()" />
                        </div>
                        <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                            <img src="@/assets/images/id-back.svg" />
                            <div class="mt-2 text-gray-500">Carica il retro</div>
                            <Attachment :attachment="attachments.identityRear" @file-added="addIdRear"
                                @remove-file="identityRear = null" @reload-list="getAttachments()" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap md:flex-none">
                    <div class="flex w-full md:w-1/2 flex-col px-1 md:px-2">
                        <div class="flex flex-col rounded shadow-rankings border border-primary mb-6">
                            <div class="m-4 text-primary-dark font-bold">Carica il certificato di conoscenza della lingua
                                inglese
                                (QCE)
                            </div>
                            <div class="flex flex-wrap md:flex-nowrap justify-center">
                                <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                                    <img src="@/assets/images/qce-certificate.svg" />
                                    <Attachment :attachment="attachments.qce" @file-added="addQce" @remove-file="qce = null"
                                        @reload-list="getAttachments()" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full md:w-1/2 flex-col px-1 md:px-2">
                        <div class="flex flex-col rounded shadow-rankings border border-primary mb-6">
                            <div class="m-4 text-primary-dark font-bold">Carica la patente europea per l'utilizzo del P.C.
                                (ECDL)
                            </div>
                            <div class="flex flex-wrap md:flex-nowrap justify-center">
                                <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                                    <img src="@/assets/images/ecdl-certificate.svg" />
                                    <Attachment :attachment="attachments.ecdl" @file-added="addEcdl"
                                        @remove-file="ecdl = null" @reload-list="getAttachments()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col rounded shadow-rankings border border-primary mb-6 mx-1 md:mx-2">
                    <div class="m-4 text-primary-dark font-bold">Carica i documenti relativi a titoli accademici e di studio
                    </div>
                    <QualificationsDragDrop ref="qualificationsDD" :attachments="attachments.academics"
                        @file-added="addQualifications" @reload-list="getAttachments()" />
                </div>
                <div class="flex flex-col rounded shadow-rankings border border-primary mb-6 mx-1 md:mx-2">
                    <div class="m-4 text-primary-dark font-bold">Carica i documenti relativi ai certificati di servizio
                    </div>
                    <JobsDragDrop ref="jobsDD" :attachments="attachments.jobs" @file-added="addJobs"
                        @reload-list="getAttachments()" />
                </div>
            </div>

        </div>
        <div class="flex w-full justify-center">
            <button class="w-fit mt-6" :class="missingMandatoryFields ? 'btn-disabled' : 'btn-success'"
                @click="saveAttachments" :disabled="missingMandatoryFields">Salva</button>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import Attachment from '@/components/Attachment.vue'
import QualificationsDragDrop from '@/components/QualificationsDragDrop.vue'
import JobsDragDrop from '@/components/JobsDragDrop.vue'
import { useStatusStore } from '@/store/status.js'
import { useUiStore } from '@/store/ui.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'Attachments',
    components: { Spinner, Attachment, QualificationsDragDrop, JobsDragDrop },
    data() {
        return {
            identityFront: null,
            identityRear: null,
            qce: null,
            ecdl: null,
            academics: [],
            jobs: [],
            attachments: null,
            generalLoading: false,
        }
    },
    created() {
        this.getAttachments()
    },
    computed: {
        ...mapStores(useStatusStore),
        ...mapStores(useUiStore),
        missingMandatoryFields() {
            return ((!this.attachments && (!this.identityFront || !this.identityRear)) || (this.attachments && ((!this.attachments.identityFront && !this.identityFront) || (!this.attachments.identityRear && !this.identityRear))))
        },
    },
    methods: {
        async getAttachments() {
            this.generalLoading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGAttachments() : await Services.getPLSAttachments()
                this.attachments = data
                if (this.attachments && !this.missingMandatoryFields) {
                    this.uiStore.setShowStepperGoForward(true)
                } else {
                    this.uiStore.setShowStepperGoForward(false)
                }
                this.generalLoading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.generalLoading = false
            }
        },
        async saveAttachments() {
            this.generalLoading = true
            try {
                var dataToSend = {
                    identityFront: this.identityFront,
                    identityRear: this.identityRear,
                    qce: this.qce || null,
                    ecdl: this.ecdl || null,
                    academics: this.academics || null,
                    jobs: this.jobs || null,
                }
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.postMMGAttachments(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.postPLSAttachments(dataToSend)
                }
                this.uiStore.setShowStepperGoForward(true)
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.generalLoading = false
                this.identityFront = null,
                    this.identityRear = null,
                    this.qce = null,
                    this.ecdl = null,
                    this.academics = [],
                    this.jobs = [],
                    await this.getAttachments()
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.generalLoading = false
                return;
            }
        },
        addIdFront(file) {
            this.identityFront = file
        },
        addIdRear(file) {
            this.identityRear = file
        },
        addQce(file) {
            this.qce = file
        },
        addEcdl(file) {
            this.ecdl = file
        },
        addQualifications(file) {
            this.academics = file
        },
        addJobs(file) {
            this.jobs = file
        },
    },
}
</script>