<template>
    <div class="flex flex-col px-6">
        <textarea v-model="rejectReason" class="w-full border border-primary h-40 p-2 outline-none"
            placeholder="Inserisci la ragione del rifiuto"></textarea>
    </div>
    <div class="py-5 flex w-full justify-around my-8">
        <button class="-btn-primary px-4 uppercase" @click="$emit('closeRejectModal')">
            Annulla
        </button>
        <button class="btn-primary px-4 uppercase" @click="rejectApplication">
            Rifiuta domanda
        </button>
    </div>
</template>

<script>
import Services from '@/services'
export default {
    name: 'RejectMotivation',
    props: {
        application: Object,
    },
    emits: ['closeRejectModal', 'update'],
    data: () => ({
        rejectReason: ''
    }),
    methods: {
        rejectApplication() {
            if (!this.rejectReason) {
                this.$swal({
                    title: "Attenzione!",
                    text: "Specificare la ragione del rifiuto",
                })
                return;
            }
            try {
                this.$swal({
                    title: 'Rifiutare la domanda?',
                    text: 'Rifiutando la domanda, questa non verrà inserita in graduatoria.',
                    showCancelButton: true,
                    confirmButtonText: 'Rifiuta',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const data = this.application.req_type.toLowerCase() === 'mmg' ? await Services.MMGRefuseApplication(this.application.mmg_id, { refusal_reason: this.rejectReason }) : await Services.PLSRefuseApplication(this.application.pls_id, { refusal_reason: this.rejectReason })
                            this.$emit('closeRejectModal')
                            this.$swal({
                                title: "Salvataggio riuscito",
                                text: "La domanda è stata rifiutata."
                            })
                            this.$emit('update')
                        } catch (error) {
                            this.$swal({
                                title: "Errore",
                                text: "Si è verificato un errore. Contatta il gestore del servizio."
                            })
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio."
                })
            }
        },
    }
}
</script>