import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', {
    state: () => ({
        loadingStatus: false,
        showStepperGoForward: false,
        showStepperGoBack: true,
    }),
    getters: {
        getLoadingStatus: (state) => state.loadingStatus,
        getShowStepperGoForward: (state) => state.showStepperGoForward,
        getShowStepperGoBack: (state) => state.showStepperGoBack,
    },
    actions: {
        setLoadingStatus(loadingStatus) {
            this.loadingStatus = loadingStatus
        },
        setShowStepperGoForward(showStepperGoForward) {
            this.showStepperGoForward = showStepperGoForward
        },
        setShowStepperGoBack(showStepperGoBack) {
            this.showStepperGoBack = showStepperGoBack
        },
    },
})