<template>
    <div class="flex flex-col">
        <slot name="steps"></slot> <!-- Slot for displaying the steps -->
        <div class="mt-2 p-2 lg:p-6">
            <slot name="content"></slot> <!-- Slot for displaying the content -->
            <div v-if="showButtons" class="flex flex-col md:flex-row items-center justify-center bottom-0">

                <button class="order-2 md:order-1 mt-4 md:mt-0 md:mr-10" :class="uiStore.showStepperGoBack ? '-btn-primary' : 'btn-disabled'"
                    v-if="goBack && currentStep !== startStep" @click="$emit('move', 'backward')" :disabled="!uiStore.showStepperGoBack">Indietro</button> <!-- Button for going back to the previous step -->

                <button v-if="!saveAndGo" class="order-1 md:order-2"
                    :class="uiStore.showStepperGoForward ? 'btn-primary' : 'btn-disabled'" @click="$emit('move', 'forward')"
                    :disabled="!uiStore.showStepperGoForward">
                    {{ currentStep ===
                        steps ? 'Invia domanda' :
                        'Avanti' }} <!-- Button for moving forward to the next step or submitting the form -->
                </button>

                <button v-else class="order-1 md:order-2"
                    :class="uiStore.showStepperGoForward ? 'btn-primary' : 'btn-disabled'" @click="$emit('move', 'forward')"
                    :disabled="!uiStore.showStepperGoForward">
                    {{ currentStep ===
                        steps ? 'Conferma' :
                        'Salva e prosegui' }} <!-- Button for moving forward to the next step or saving and continuing -->
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { useUiStore } from '@/store/ui.js'
import { mapStores } from 'pinia'
import Stepper from "@/components/Stepper.vue"
export default {
    name: "Stepper",
    emits: ["move", "currentStep"],
    props: {
        steps: { type: Number, required: true }, // Number of steps in the stepper
        startStep: { type: Number, default: 1 }, // Starting step of the stepper
        jumpToStep: { type: Number }, // Step to jump to
        showButtons: { type: Boolean, default: true }, // Whether to show the navigation buttons
        goBack: { type: Boolean, default: true }, // Whether to show the "Go Back" button
        saveAndGo: { type: Boolean, default: true }, // Whether to show the "Save and Go" button
    },
    components: { Stepper },
    data() {
        return {
            currentStep: this.startStep, // Current step of the stepper
        }
    },
    computed: {
        ...mapStores(useUiStore),
    },
    methods: {
        move() {
            this.currentStep = this.jumpToStep // Move to the specified step
            this.$emit('currentStep', this.currentStep) // Emit the current step to the parent component
        },
    },
    watch: {
        jumpToStep: function (newVal, oldVal) {
            this.move() // Watch for changes in the jumpToStep prop and move to the specified step
            document.getElementById("mainContainer").scrollTop = 0
        },
    }
};
</script>