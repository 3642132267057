<template>
    <div class="flex flex-col">
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/2 mt-4 lg:mr-10">
                <label>Titolo di studio <span class="text-red-flag">*</span>
                    <v-select v-model="qualificationEntitlementId" class="w-full text-sm mt-1"
                        :options="qualificationEntitlements" label="title" placeholder="Seleziona il titolo di studio"
                        :reduce="entitl => entitl.title_id" :searchable="true" :clearable="false" disabled />
                </label>
            </div>
            <div class="w-full lg:w-1/2 mt-4">
                <label>Conseguito il <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="date" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()" disabled>
                    </Datepicker>
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/2 mt-4 lg:mr-10">
                <label>Inizio periodo <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="startDate" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()" disabled>
                    </Datepicker>
                </label>
            </div>
            <div class="w-full lg:w-1/2 mt-4">
                <label>Fine periodo <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="endDate" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()" disabled>
                    </Datepicker>
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Ente <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Ente"
                        class="w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                        v-model="entity" disabled />
                </label>
            </div>
        </div>
        <div v-if="hasVote" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                <label>Voto <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Inserisci voto"
                        class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                        v-model="vote" disabled />
                </label>
            </div>
            <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                <label>Su <span class="text-red-flag">*</span>
                    <v-select v-model="scale" class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="scaleArray"
                        label="scale" placeholder="Seleziona un valore" :reduce="scale => scale.scale" :searchable="false"
                        disabled />
                </label>
            </div>
            <div class="w-full lg:w-1/3 mt-4">
                <label>Lode <span class="text-red-flag">*</span>
                    <v-select v-model="laude" class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="laudeArray"
                        label="label" placeholder="Seleziona un valore" :reduce="laude => laude.isLaude" :searchable="false"
                        disabled />
                </label>
            </div>
        </div>
        <div v-if="hasSpecialization" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Specializzazione <span class="text-red-flag">*</span>
                    <v-select v-model="qualificationSpecializationId" class="w-full text-sm mt-1"
                        :options="qualificationSpecializations" label="specialization"
                        placeholder="Seleziona la specializzazione" :reduce="spec => spec.spec_id" :searchable="true"
                        :clearable="false" disabled />
                </label>
            </div>
        </div>
        <div v-if="hasDiffSpecialization" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Specializzazione <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Inserisci specializzazione"
                        class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-gray-300 rounded focus:outline-none"
                        v-model="diffSpecialization" disabled/>
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-6 mb-6">
                <input type="checkbox" class="cursor-pointer" v-model="isAbroad" true-value="1" false-value="0" disabled />
                <label class="ml-2 font-semibold">Titolo acquisito all'estero</label>
            </div>
        </div>
    </div>
    <div class="py-5 flex w-full justify-around my-8">
        <button class="-btn-primary px-4 uppercase" @click="$emit('closeAddQualificationModal', false)">
            Chiudi
        </button>
    </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'AdminQualificationDetails',
    emits: ["closeAddQualificationModal"],
    props: {
        qualification: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            laudeArray: [
                {
                    label: 'No',
                    isLaude: '0',
                },
                {
                    label: 'Si',
                    isLaude: '1',
                },
            ],
            scaleArray: [],
            qualificationEntitlements: [],
            qualificationSpecializations: [],
            qualificationEntitlementId: null,
            qualificationSpecializationId: null,
            diffSpecialization: null,
            date: null,
            startDate: null,
            endDate: null,
            entity: '',
            vote: '',
            scale: '',
            laude: '',
            isAbroad: false,
            loading: false,
        }
    },
    async created() {
        await this.getQualificationTitles()
        if (this.qualification) {
            this.qualificationEntitlementId = this.qualification.title_id,
                this.date = this.qualification.graduation_date ? this.$moment(this.qualification.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
                this.startDate = this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                this.endDate = this.qualification.end ? this.$moment(this.qualification.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
                this.entity = this.qualification.issued_by,
                this.vote = this.qualification.vote ? this.qualification.vote : '',
                this.scale = this.qualification.scale ? this.qualification.scale : '',
                this.laude = this.qualification.honors ? this.qualification.honors : '',
                this.qualificationSpecializationId = this.qualification.spec_id ? this.qualification.spec_id : null,
                this.diffSpecialization = this.qualification.diff_specialization ? this.qualification.diff_specialization : null
                this.isAbroad = this.qualification.foreign_title ? this.qualification.foreign_title : null
        }
    },
    computed: {
        ...mapStores(useStatusStore),
        hasDiffSpecialization(){
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if (selectedEntitlement.code.toLowerCase() == 'a13'){
                    return true
                }
                return false 
            }
        },
        hasSpecialization() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if (selectedEntitlement.specializations.length > 0) {
                    this.qualificationSpecializations = selectedEntitlement.specializations
                    return true
                }
                this.qualificationSpecializationId = null
                return false
            }
        },
        hasCertificate() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if ((selectedEntitlement.code.toLowerCase() === 'a7-i' || selectedEntitlement.code.toLowerCase() === 'a11-i') && this.statusStore.hasCertificate == 0) {
                    this.date = null
                    this.endDate = null
                    return false
                }
                return true
            }
        },
        hasVote() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if (selectedEntitlement.enter_vote == '1') {
                    return true
                }
                return false
            }
        },
    },
    methods: {
        async getQualificationTitles() {
            try {
                const { data } = this.$route.params.type.toLowerCase() === 'mmg' ? await Services.getMMGQualificationStudyTitles() : await Services.getPLSQualificationStudyTitles()
                this.qualificationEntitlements = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },

    }
}
</script>