<template>
    <div class="flex lg:pt-4 md:px-6 text-white">
        <div class="flex w-60 h-20 px-2 justify-center items-center hover:bg-primary-dark cursor-pointer border-r border-white"
            :class="isActive === 'mmg' ? 'bg-primary-dark border-b-0' : 'bg-primary border-b'"
            @click="isActive = 'mmg', getApplicationList()">
            Medici di Medicina Generale</div>
        <div class="flex w-60 h-20 px-2 justify-center items-center hover:bg-primary-dark cursor-pointer border-white"
            :class="isActive === 'pls' ? 'bg-primary-dark border-b-0' : 'bg-primary border-b'"
            @click="isActive = 'pls', getApplicationList()">
            Pediatri di Libera Scelta</div>
    </div>
    <div class="min-h-108 pt-4 md:mx-6 border border-primary rounded-b shadow-rankings mb-4">
        <div v-if="isActive === 'mmg' && statusStore.mmgRankingTypeId != '2'"
            class="flex flex-col md:flex-row items-center justify-center mt-2 mb-6">
            <button v-if="statusStore.mmgRankingTypeId != '2'" class="w-1/2 md:w-auto btn-success"
                @click="acceptAll('mmg')">MMG - Accetta tutti</button>
            <button v-if="statusStore.mmgTempRanking && !statusStore.mmgRankingTypeId"
                class="w-1/2 md:w-auto btn-primary mt-4 md:mt-0 md:ml-10" @click="makeTempRanking('mmg')">Pubblica
                provvisoria MMG</button>
            <button v-if="statusStore.mmgFinalRanking && statusStore.mmgRankingTypeId == '1'"
                class="w-1/2 md:w-auto btn-primary mt-4 md:mt-0 md:ml-10" @click="makeFinalRanking('mmg')">Pubblica
                definitiva MMG</button>
        </div>
        <div v-if="isActive === 'pls' && statusStore.plsRankingTypeId != '2'"
            class="flex flex-col md:flex-row items-center justify-center mt-2 mb-6">
            <button v-if="statusStore.plsRankingTypeId != '2'" class="w-1/2 md:w-auto btn-success"
                @click="acceptAll('pls')">PLS - Accetta tutti</button>
            <button v-if="statusStore.plsTempRanking && !statusStore.plsRankingTypeId"
                class="w-1/2 md:w-auto btn-primary mt-4 md:mt-0 md:ml-10" @click="makeTempRanking('pls')">Pubblica
                provvisoria PLS</button>
            <button v-if="statusStore.plsFinalRanking && statusStore.plsRankingTypeId == '1'"
                class="w-1/2 md:w-auto btn-primary mt-4 md:mt-0 md:ml-10" @click="makeFinalRanking('pls')">Pubblica
                definitiva PLS</button>
        </div>
        <div v-if="isActive === 'mmg' && statusStore.mmgRankingTypeId" class="flex w-full justify-center mb-4">
            <div v-if="statusStore.mmgRankingTypeId == '1'" class="font-bold text-primary-dark hover:text-primary cursor-pointer"
                @click="$router.push('/ranking/temporary/mmg')">Visualizza graduatoria provvisoria</div>
            <div v-if="statusStore.mmgRankingTypeId == '2'" class="font-bold text-primary-dark hover:text-primary cursor-pointer"
                @click="$router.push('/ranking/final/mmg')">Visualizza graduatoria definitiva</div>
        </div>
        <div v-if="isActive === 'pls' && statusStore.plsRankingTypeId" class="flex w-full justify-center mb-4">
            <div v-if="statusStore.plsRankingTypeId == '1'" class="font-bold text-primary-dark hover:text-primary cursor-pointer"
                @click="$router.push('/ranking/temporary/pls')">Visualizza graduatoria provvisoria</div>
            <div v-if="statusStore.plsRankingTypeId == '2'" class="font-bold text-primary-dark hover:text-primary cursor-pointer"
                @click="$router.push('/ranking/final/pls')">Visualizza graduatoria definitiva</div>
        </div>
        <div class="flex flex-col">
            <div class="flex xl:w-1/3 mx-2 lg:mx-4 mb-4">
                <input type="text" class="w-full px-4 h-10 rounded-l border-l border-b border-t border-primary outline-none"
                    placeholder="cerca..." v-model="searchText" @input="searchInTable()" @keyup.enter="searchInTable()" />
                <div class="flex bg-primary border border-primary w-11 items-center justify-center rounded-r">
                    <font-awesome-icon class="h-4 md:h-5 w-4 md:w-5 text-white" icon="fas fa-search" />
                </div>
            </div>
            <div v-if="filteredApplications && filteredApplications.length > 0" class="flex xl:w-1/3 mx-2 lg:mx-4 mb-4 font-medium text-primary-dark">{{ 'Numero di domande presentate: ' + filteredApplications.length }} </div>
            <div
                class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white mx-2 lg:mx-4 items-center">
                <div class="flex w-2/12">
                    <div class="flex w-5/12 xl:w-4/12 p-2 text-sm xl:text-base">Codice</div>
                    <div class="flex w-7/12 xl:w-8/12 border-l border-white p-2 text-sm xl:text-base">Stato</div>
                </div>
                <div class="flex w-2/12 border-l border-white p-2 text-sm xl:text-base">Tipo</div>
                <div class="flex w-3/12 border-l border-white p-2 text-sm xl:text-base">Cognome</div>
                <div class="flex w-3/12 border-l border-white p-2 text-sm xl:text-base">Nome</div>
                <div class="flex w-2/12 border-l border-white p-2 items-center text-sm xl:text-base">Operazioni</div>
            </div>
        </div>
        <div v-if="filteredApplications && filteredApplications.length > 0"
            v-for="(application, index) in filteredApplications" :key="index" class="flex flex-col">
            <AdminApplicationSummary :application="application" :isLast="index === filteredApplications.length - 1"
                @update="getApplicationList" />
        </div>
        <div v-else
            class="flex flex-col shadow-rankings border lg:border-t-0 border-primary mx-2 lg:mx-4 items-center rounded-b py-4">
            Nessuna domanda trovata
        </div>
    </div>
</template>

<script>
import AdminApplicationSummary from '@/components/administrator/AdminApplicationSummary.vue'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'AdminHome',
    components: { AdminApplicationSummary },
    data: () => ({
        isActive: 'mmg',
        searchText: '',
        applications: [],
        filteredApplications: []
    }),
    created() {
        this.getApplicationList();
    },
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        async getApplicationList() {
            try {
                this.applications = []
                this.filteredApplications = []
                const { data } = this.isActive === 'mmg' ? await Services.getMMGApplicationList() : await Services.getPLSApplicationList()
                this.applications = this.filteredApplications = data.map(obj => ({ ...obj, id: obj.mmg_id || obj.pls_id }))
                this.searchInTable()

            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore nel recupero dei dati. Contatta il gestore del servizio."
                })
            }
        },
        listFilter(el) {
            let type = el.new_entry == '1' ? 'Primo inserimento' : 'Integrazione'
            return (el.id.includes(this.searchText.toLowerCase()) || el.status.toLowerCase().includes(this.searchText.toLowerCase()) || type.toLowerCase().includes(this.searchText.toLowerCase()) || el.lastname.toLowerCase().includes(this.searchText.toLowerCase()) || el.firstname.toLowerCase().includes(this.searchText.toLowerCase()))
        },
        async searchInTable() {
            if (this.searchText) {
                this.filteredApplications = this.applications.filter(el => { return this.listFilter(el) })
            } else {
                this.filteredApplications = this.applications
            }
        },
        async acceptAll(requestType) {
            try {
                this.$swal({
                    title: 'Accettare tutte le domande?',
                    text: 'Verranno accettate solo le domande non rifiutate e non riaperte.',
                    showCancelButton: true,
                    confirmButtonText: 'Accetta',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            if (requestType === 'mmg') {
                                await Services.MMGAcceptAllApplications()
                            } else if (requestType === 'pls') {
                                await Services.PLSAcceptAllApplications()
                            }
                            this.getApplicationList();
                        } catch (e) {
                            if (e.response.data.status === 400) {
                                this.$swal({
                                    title: "Attenzione",
                                    text: e.response.data.data.error
                                })
                            } else {
                                this.$swal({
                                    title: "Errore",
                                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                                })
                            }
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async makeTempRanking(requestType) {
            try {
                this.$swal({
                    title: 'Generare la graduatoria provvisoria?',
                    text: 'Dopo aver generato la graduatoria provvisoria non potrai modificare lo stato delle domande, ma potrai riaprirle',
                    showCancelButton: true,
                    confirmButtonText: 'Pubblica',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            if (requestType === 'mmg') {
                                await Services.MMGMakeTemporaryRanking()
                                this.$router.go(0)
                            } else if (requestType === 'pls') {
                                await Services.PLSMakeTemporaryRanking()
                                this.$router.go(0)
                            }
                        } catch (e) {
                            if (e.response.data.status === 400) {
                                this.$swal({
                                    title: "Attenzione",
                                    text: e.response.data.data.error
                                })
                            } else {
                                this.$swal({
                                    title: "Errore",
                                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                                })
                            }
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async makeFinalRanking(requestType) {
            try {
                this.$swal({
                    title: 'Generare la graduatoria definitiva?',
                    text: 'Dopo aver generato la graduatoria definitiva non potrai modificare lo stato delle domande.',
                    showCancelButton: true,
                    confirmButtonText: 'Pubblica',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            if (requestType === 'mmg') {
                                await Services.MMGMakeFinalRanking()
                                this.$router.go(0)
                            } else if (requestType === 'pls') {
                                await Services.PLSMakeFinalRanking()
                                this.$router.go(0)
                            }
                        } catch (e) {
                            if (e.response.data.status === 400) {
                                this.$swal({
                                    title: "Attenzione",
                                    text: e.response.data.data.error
                                })
                            } else {
                                this.$swal({
                                    title: "Errore",
                                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                                })
                            }
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
    }
}
</script>

<style scoped>
:deep() {
    --vs-controls-color: #0185CF;
    --vs-border-color: #0185CF;
    --vs-selected-bg: #0185CF;
    --vs-dropdown-option--active-bg: #0185CF;
}
</style>

