<template>
    <div class="flex flex-col px-6">
        <textarea v-model="acceptReserveReason" class="w-full border border-primary h-40 p-2 outline-none"
            placeholder="Inserisci la ragione dello scioglimento della riserva"></textarea>
    </div>
    <div class="py-5 flex w-full justify-around my-8">
        <button class="-btn-primary px-4 uppercase" @click="$emit('closeAcceptReserveModal')">
            Annulla
        </button>
        <button class="btn-primary px-4 uppercase" @click="acceptApplication">
            Accetta domanda
        </button>
    </div>
</template>

<script>
import Services from '@/services'
export default {
    name: 'AcceptReserveMotivation',
    props: {
        application: Object,
    },
    emits: ['closeAcceptReserveModal', 'update'],
    data: () => ({
        acceptReserveReason: ''
    }),
    methods: {
        acceptApplication() {
            if (!this.acceptReserveReason) {
                this.$swal({
                    title: "Attenzione!",
                    text: "Giustificare lo scioglimento della riserva",
                })
                return;
            }
            try {
                this.$swal({
                    title: 'Accettare la domanda?',
                    text: 'Accettando la domanda, questa verrà inserita in graduatoria.',
                    showCancelButton: true,
                    confirmButtonText: 'Accetta',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const data = this.application.req_type.toLowerCase() === 'mmg' ? await Services.MMGAcceptReserveApplication(this.application.mmg_id, { accept_reserve_reason: this.acceptReserveReason }) : await Services.PLSAcceptReserveApplication(this.application.pls_id, { accept_reserve_reason: this.acceptReserveReason })
                            this.$emit('closeAcceptReserveModal')
                            this.$swal({
                                title: "Salvataggio riuscito",
                                text: "La domanda è stata accettata."
                            })
                            this.$emit('update')
                        } catch (error) {
                            this.$swal({
                                title: "Errore",
                                text: "Si è verificato un errore. Contatta il gestore del servizio."
                            })
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio."
                })
            }
        },
    }
}
</script>