<template>
    <div class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary mx-2 lg:mx-4 items-center"
        :class="isLast ? 'rounded-b mb-4' : 'mb-4 lg:mb-0'">
        <div
            class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 lg:justify-center p-2 order-1 border-r lg:border-r-0 border-primary lg:border-0">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
            <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
                :class="job.status.toLowerCase() === 'rifiutato' ? 'bg-red-flag' : job.status.toLowerCase() === 'accettato' ? 'bg-green-flag' : 'bg-orange-500'">
                {{ job.status }}</div>
        </div>
        <div
            class="flex flex-col w-full lg:w-10/12 border-t lg:border-l lg:border-r lg:border-t-0 border-primary p-2 order-3 lg:order-2">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Titolo di servizio</div>
            <div class="flex lg:mb-2">{{ job.title }}</div>
            <div v-if="job.region" class="flex">
                <div>Regione: </div>
                <div class="font-semibold ml-2">{{ job.region }}</div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row w-1/2 lg:w-1/12 p-2 lg:items-center lg:justify-center order-2 lg:order-3">
            <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Operazioni</div>
            <div class="flex mx-auto lg:mx-0">
                <font-awesome-icon class="h-6 mr-6 lg:mr-4 text-primary hover:text-primary-dark cursor-pointer"
                    icon="fas fa-edit" alt="Modifica" title="Modifica" @click="modifyJobModal = true" />
                <font-awesome-icon class="h-6 text-red-logo hover:text-red-logo-dark cursor-pointer" icon="fas fa-trash"
                    alt="Elimina" title="Elimina" @click="deleteJob" />
            </div>
        </div>
    </div>
    <Modal :active="modifyJobModal" title="Modifica Titolo di Servizio"
        card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <JobDetails @close-add-job-modal="closeModal" :job="job" />
            </div>
        </template>
    </Modal>
</template>

<script>
import JobDetails from '@/components/jobs/JobDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'JobSummary',
    emits: ['updateJobList'],
    props: {
        job: Object,
        isLast: Boolean
    },
    components: { Modal, JobDetails, Spinner },
    data() {
        return {
            modifyJobModal: false,
        }
    },
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        deleteJob() {
            this.$swal.fire({
                title: "Confermare l'eliminazione?",
                text: "Eliminare il titolo di servizio?",
                showCancelButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        if (this.statusStore.type.toLowerCase() === 'mmg') {
                            await Services.deleteMMGJob(this.job.job_qual_id)
                        } else if (this.statusStore.type.toLowerCase() === 'pls') {
                            await Services.deletePLSJob(this.job.job_qual_id)
                        }
                        this.$swal.fire({
                            title: 'Titolo di servizio eliminato',
                            text: "L'eliminazione è avvenuta con successo"
                        })
                        this.$emit('updateJobList')
                    } catch (e) {
                        this.$swal.fire({
                            title: 'Errore eliminazione',
                            text: "Si è verificato un errore durante l'eliminazione. Riprova più tardi o contatta l'assistenza tecnica."
                        })
                    }
                }
            })
        },
        closeModal(isSaved) {
            if (isSaved) {
                this.$emit('updateJobList')
            }
            this.modifyJobModal = false
        }
    }
}
</script>