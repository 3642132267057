<template>
    <div v-if="!generalLoading">
        <div class="flex p-2 text-red-logo font-medium text-lg">{{ 'IMPORTANTE! Affinché la domanda di inserimento in ' +
            'graduatoria possa ritenersi conclusa e disponibile per essere valutata dal personale competente, è necessario '
            +
            'inviare la stessa entro e non oltre il ' + (statusStore.type === 'mmg' ? $moment(statusStore.mmgDeadLine,
                'YYYY-MM-DD').format('DD/MM/YYYY') : $moment(statusStore.plsDeadLine, 'YYYY-MM-DD').format('DD/MM/YYYY')) }}
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t uppercase">Informativa sul trattamento dei
                dati
                personali</div>
            <div class="flex flex-col p-2 text-gray-600 font-medium">Ai sensi del Regolamento Europeo 27 aprile 2016 n.
                2016/679/UE e del
                Codice della Privacy, D. Lgs. 30 giugno
                2003, n. 196 modificato dal D.Lgs. 10 agosto 2018, n. 101, si informa che:
                <ul class="px-6 my-2 list-disc">
                    <li>{{ `i dati personali raccolti saranno trattati esclusivamente per le finalità strettamente
                        necessarie e
                        connesse alla formazione della graduatoria regionale di ` + (statusStore.type === 'mmg' ? `medicina
                        generale` : `pediatria di libera scelta`) + ` valida per l'anno ` + statusStore.year + `. Per tali
                        finalità l'acquisizione dei dati è necessaria e il loro mancato conferimento, o una loro omissione,
                        può
                        comportare l'esclusione dalla procedura;` }}
                    </li>
                    <li>
                        il trattamento viene effettuato mediante strumenti cartacei e informatici;
                    </li>
                    <li>
                        il Titolare del trattamento dei dati personali di cui alla presente Informativa è la Regione Molise,
                        con
                        sede in
                        Campobasso, Via Genova n.11, c.a.p. 86100, nella persona del Presidente della Giunta regionale
                        pro-tempore, quale Rappresentante legale, indirizzo PEC: regionemolise@cert.regione.molise.it;
                    </li>
                    <li>
                        il Responsabile della Protezione dei dati Personali (RDP - DPO), giusta deliberazione della Giunta
                        regionale
                        n. 501 del 23 dicembre 2020, è il dott. Mario Cuculo, dirigente della Regione Molise, contattabile
                        all'indirizzo e-mail: dpo@regione.molise.it; telefono 08744291;
                    </li>
                    <li>
                        i dati saranno conservati presso gli archivi cartacei e/o informatizzati esistenti presso la
                        Direzione
                        Generale
                        per la Salute - Servizio Risorse Umane del S.S.R., Formazione, E.C.M. - Via N. Sauro, 1 -
                        Campobasso.
                        Essi
                        saranno conservati per il periodo di tempo stabilito dalle norme in materia di conservazione di atti
                        e
                        documenti
                        della Pubblica Amministrazione;
                    </li>
                    <li>
                        l'interessato ha diritto di accedere ai dati che lo riguardano;
                    </li>
                    <li>
                        i dati relativi al nominativo dell'istante ed al punteggio saranno pubblicati sul sito istituzionale
                        e
                        nel Bollettino
                        Ufficiale della Regione Molise; gli altri dati possono essere comunicati, per finalità istituzionali
                        e
                        in base a
                        disposizioni di legge, alle Aziende del Servizio Sanitario Nazionale;
                    </li>
                    <li>
                        i dati raccolti, fatta salva la pubblicazione e le comunicazioni di cui sopra, non saranno diffusi e
                        non
                        saranno
                        oggetto di comunicazione a soggetti diversi da quelli abilitati al trattamento senza suo esplicito
                        assenso.
                    </li>
                </ul>
                <div class="flex mx-auto">
                    <div class="w-full mt-6 mb-6">
                        <input type="checkbox" class="cursor-pointer" true-value="1" false-value="0" v-model="privacy" />
                        <label class="ml-2 font-semibold md:text-lg text-primary-dark">Ho preso visione dell'informativa e
                            acconsento al trattamento dei
                            dati</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Marca da bollo
            </div>
            <div class="flex flex-col mb-6 mx-1 md:mx-2">
                <div class="mt-4 mx-4 text-primary-dark font-bold">Carica la ricevuta del pagamento della marca da bollo
                    <span class="text-red-flag">*</span>
                </div>
                <div class="flex group cursor-pointer mt-3 mb-4 mx-4" @click="downloadReceiptForm()">
                    <font-awesome-icon class="h-4 mr-2 text-primary group-hover:text-primary-dark"
                        icon="fas fa-file-pdf" />
                    <div class="text-primary group-hover:text-primary-dark text-sm">
                        Scarica il modello
                    </div>
                </div>
                <div class="flex flex-wrap md:flex-nowrap justify-center">
                    <div class="flex flex-col w-full md:w-1/2 items-center my-6">
                        <img src="@/assets/images/receipt.svg" />
                        <div class="mt-2 text-gray-500">Carica la ricevuta</div>
                        <Attachment :attachment="endStepData ? endStepData.receipt : null" @file-added="addReceipt"
                            @remove-file="receipt = null" @reload-list="getEndStep()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-full items-center">
            <button class="w-fit mt-6" :class="missingMandatoryFields ? 'btn-disabled' : 'btn-success'" @click="saveEndStep"
                :disabled="missingMandatoryFields">Salva</button>
            <button class="w-fit mt-6" :class="!uiStore.showStepperGoForward ? 'btn-disabled' : 'btn-success'"
                @click="$emit('reDownloadRecap')" :disabled="!uiStore.showStepperGoForward">Scarica riepilogo</button>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import Attachment from '@/components/Attachment.vue'
import Services from '@/services';
import { useStatusStore } from '@/store/status.js'
import { useUiStore } from '@/store/ui.js'
import { mapStores } from 'pinia'

export default {
    name: 'EndStep',
    components: { Spinner, Attachment },
    emits: ['recallRecap', 'reDownloadRecap'],
    data() {
        return {
            privacy: 0,
            receipt: null,
            endStepData: null,
            generalLoading: false,
        };
    },
    created() {
        this.getEndStep()
    },
    computed: {
        ...mapStores(useStatusStore),
        ...mapStores(useUiStore),
        missingMandatoryFields() {
            return (this.privacy == 0 || (!this.endStepData && !this.receipt) || (this.endStepData && (!this.endStepData.receipt && !this.receipt)))
        },
    },
    methods: {
        async getEndStep() {
            this.generalLoading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGEndStep() : await Services.getPLSEndStep()
                this.endStepData = data
                this.privacy = data.privacy
                if (this.endStepData && !this.missingMandatoryFields) {
                    this.uiStore.setShowStepperGoForward(true)
                } else {
                    this.uiStore.setShowStepperGoForward(false)
                }
                this.generalLoading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.generalLoading = false
            }
        },
        async saveEndStep() {
            this.generalLoading = true
            try {
                const dataToSend = {
                    privacy: this.privacy,
                    receipt: this.receipt
                }
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.postMMGEndStep(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.postPLSEndStep(dataToSend)
                }
                this.uiStore.setShowStepperGoForward(true)
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.generalLoading = false
                this.receipt = null,
                    this.$emit('recallRecap')
                await this.getEndStep()
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.generalLoading = false
                return;
            }
        },
        addReceipt(file) {
            this.receipt = file
        },
        downloadReceiptForm() {
            window.open(process.env.VUE_APP_AUTH_API_BASE_URL + "/storage/MoliseAllegatoC.pdf", "_blank")
        },
    }
}
</script>