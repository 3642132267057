<template>
  <div
    class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary mb-4 mx-2 lg:mx-4 items-center hover:bg-gray-50 cursor-pointer"
    :class="rowClass"
    @click="$router.push('/application-details/' + application.req_type.toLowerCase() + '/' + application.id)">
    <div class="flex w-full lg:w-2/12 order-1 lg:order-1 items-center lg:items-start border-primary">
      <div
        class="flex flex-col w-1/2 lg:w-5/12 xl:w-4/12 items-center lg:items-start border-r lg:border-r-0 border-primary px-1 py-2 xl:p-2">
        <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Codice</div>
        <div class="flex">{{ application.id }}</div>
      </div>
      <div
        class="flex flex-col lg:flex-row w-1/2 lg:w-7/12 xl:w-8/12 items-center lg:items-start lg:justify-center lg:border-l p-2 border-primary">
        <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
        <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
          :class="application.status.toLowerCase() === 'rifiutata' ? 'bg-red-flag' : (application.status.toLowerCase() === 'accettata' || application.status.toLowerCase() === 'con riserva') ? 'bg-green-flag' : 'bg-orange-500'">
          {{ application.status }}</div>
      </div>
    </div>
    <div
      class="flex flex-col w-full lg:w-2/12 order-3 lg:order-3 items-center lg:items-start border-y lg:border-y-0 lg:border-l border-primary p-2">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Tipo</div>
      <div class="flex">{{ application.new_entry == '1' ? 'Primo inserimento' : 'Integrazione' }}</div>
    </div>
    <div
      class="flex flex-col w-1/2 lg:w-3/12 order-4 lg:order-4 items-center lg:items-start lg:border-l border-primary p-2">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Cognome</div>
      <div class="flex">{{ application.lastname }}</div>
    </div>
    <div class="flex flex-col w-1/2 lg:w-3/12 order-5 lg:order-5 items-center lg:items-start border-l border-primary p-2">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Nome</div>
      <div class="flex">{{ application.firstname }}</div>
    </div>
    <div
      class="flex flex-col lg:flex-row w-full lg:w-2/12 order-6 p-2 items-center lg:justify-center border-t lg:border-t-0 lg:border-l border-primary">
      <div class="flex lg:hidden font-semibold mb-4 text-primary-dark">Operazioni</div>
      <div class="flex w-full lg:mx-0 my-2 lg:my-0">
        <div
          v-if="(application.req_type.toLowerCase() === 'mmg' && statusStore.mmgRankingTypeId != '2') || (application.req_type.toLowerCase() === 'pls' && statusStore.plsRankingTypeId != '2')"
          class="flex w-1/3 justify-center">
          <font-awesome-icon
            v-if="application.status.toLowerCase() !== 'accettata' && application.status.toLowerCase() !== 'con riserva'"
            class="h-6 hover:text-green-flag cursor-pointer" icon="far fa-circle-check" alt="Accetta la domanda"
            title="Accetta la domanda" @click.stop="acceptApplication" />
          <font-awesome-icon
            v-if="application.status.toLowerCase() === 'accettata' || application.status.toLowerCase() === 'con riserva'"
            class="h-6 text-green-flag cursor-pointer" icon="fas fa-circle-check" alt="Domanda accettata"
            title="Domanda accettata" />
        </div>
        <div
          v-if="(application.req_type.toLowerCase() === 'mmg' && statusStore.mmgRankingTypeId != '2') || (application.req_type.toLowerCase() === 'pls' && statusStore.plsRankingTypeId != '2')"
          class="flex w-1/3 justify-center">
          <font-awesome-icon v-if="application.status.toLowerCase() !== 'rifiutata'"
            class="h-6 hover:text-red-flag cursor-pointer" icon="far fa-circle-xmark" alt="Rifiuta la domanda"
            title="Rifiuta la domanda" @click.stop="rejectModal = true" />
          <font-awesome-icon v-if="application.status.toLowerCase() === 'rifiutata'"
            class="h-6 text-red-flag cursor-pointer" icon="fas fa-circle-xmark" alt="Domanda rifiutata"
            title="Domanda rifiutata" />
        </div>
        <div
          v-if="(application.req_type.toLowerCase() === 'mmg' && statusStore.mmgRankingTypeId != '2') || (application.req_type.toLowerCase() === 'pls' && statusStore.plsRankingTypeId != '2')"
          class="flex w-1/3 justify-center">
          <font-awesome-icon v-if="application.status.toLowerCase() !== 'riaperta'"
            class="h-6 hover:text-orange-500 cursor-pointer" icon="fas fa-lock" alt="Riapri la domanda"
            title="Riapri la domanda" @click.stop="reopenApplication" />
          <font-awesome-icon v-if="application.status.toLowerCase() === 'riaperta'"
            class="h-6 text-orange-500 cursor-pointer" icon="fas fa-lock-open" alt="Domanda riaperta"
            title="Domanda riaperta" />
        </div>
        <div
          v-if="(application.req_type.toLowerCase() === 'mmg' && statusStore.mmgRankingTypeId == '2') || (application.req_type.toLowerCase() === 'pls' && statusStore.plsRankingTypeId == '2')"
          class="flex w-full justify-center"> - </div>
      </div>
    </div>
  </div>
  <Modal :active="rejectModal" title="Motivo del rifiuto" card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
    <template #body>
      <div class="w-full bg-white">
        <RejectMotivation @close-reject-modal="rejectModal = false" :application="application"
          @update="$emit('update')" />
      </div>
    </template>
  </Modal>
</template>

<script>
import RejectMotivation from '@/components/administrator/RejectMotivation.vue'
import Modal from '@/components/Modal.vue'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
  name: 'ApplicationSummary',
  props: {
    application: Object,
    isLast: Boolean
  },
  emits: ['update'],
  components: { RejectMotivation, Modal },
  data: () => ({
    rejectModal: false
  }),
  computed: {
    ...mapStores(useStatusStore),
    rowClass() {
      return {
        'rounded-b': this.isLast,
        'lg:mb-0': !this.isLast,
        'bg-orange-200 hover:bg-orange-100': this.application.status.toLowerCase() === 'con riserva'
      }
    }
  },
  methods: {
    acceptApplication() {
      try {
        this.$swal({
          title: 'Attenzione!',
          text: 'Accettare la domanda?',
          showCancelButton: true,
          confirmButtonText: 'Accetta',
          cancelButtonText: 'Annulla'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const data = this.application.req_type.toLowerCase() === 'mmg' ? await Services.MMGAcceptApplication(this.application.id) : await Services.PLSAcceptApplication(this.application.id)
              this.$swal({
                title: "Salvataggio riuscito",
                text: "La domanda è stata accettata."
              })
              this.$emit('update')
            } catch (error) {
              this.$swal({
                title: "Errore",
                text: "Si è verificato un errore. Contatta il gestore del servizio."
              })
            }
          }
        })
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore. Contatta il gestore del servizio."
        })
      }
    },
    reopenApplication() {
      try {
        this.$swal({
          title: 'Riaprire la domanda?',
          text: 'Riaprendo la domanda si da la possibilità al medico di modificare o integrare i dati inseriti.',
          showCancelButton: true,
          confirmButtonText: 'Riapri',
          cancelButtonText: 'Annulla'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const data = this.application.req_type.toLowerCase() === 'mmg' ? await Services.MMGReopenApplication(this.application.id) : await Services.PLSReopenApplication(this.application.id)
              this.$swal({
                title: "Salvataggio riuscito",
                text: "La domanda è stata riaperta."
              })
              this.$emit('update')
            } catch (error) {
              this.$swal({
                title: "Errore",
                text: "Si è verificato un errore. Contatta il gestore del servizio."
              })
            }
          }
        })
      } catch (e) {
        this.$swal({
          title: "Errore",
          text: "Si è verificato un errore. Contatta il gestore del servizio."
        })
      }
    },
  }
}
</script>