<template>
    <div v-if="!generalLoading">
        <div v-if="statusStore.newEntry || statusStore.type.toLowerCase() === 'mmg'"
            class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Titoli Accademici e di Studio - Sez. 1</div>
            <div class="text-gray-600 font-bold my-4 mx-2">Sotto la propria responsabilità e consapevole delle sanzioni
                penali
                previste dall'art. 76 del D.P.R. n.445 del 28/12/2000 per ipotesi di falsità in atti e dichiarazioni
                mendaci,
                dichiara di essere in possesso di: </div>
            <div class="flex flex-col mb-4 pb-4" v-if="!mandatoryLoading">
                <div v-if="statusStore.newEntry" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Laurea <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Laurea"
                                class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="statusStore.degree" disabled />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Ateneo <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Ateneo"
                                class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="applicationRequestStore.mandatoryQualifications.degree.university" />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4">
                        <label>Data Laurea <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input"
                                v-model="applicationRequestStore.mandatoryQualifications.degree.date"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1" modelType="dd/MM/yyyy"
                                placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()">
                            </Datepicker>
                        </label>
                    </div>
                </div>
                <div v-if="statusStore.newEntry" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Voto Laurea <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Inserisci voto Laurea"
                                class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="applicationRequestStore.mandatoryQualifications.degree.vote" />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Su <span class="text-red-flag">*</span>
                            <v-select v-model="applicationRequestStore.mandatoryQualifications.degree.scale"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="scaleArray" label="scale"
                                placeholder="Seleziona un valore" :reduce="scale => scale.scale" :searchable="false" />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4">
                        <label>Lode <span class="text-red-flag">*</span>
                            <v-select v-model="applicationRequestStore.mandatoryQualifications.degree.laude"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="laudeArray" label="label"
                                placeholder="Seleziona un valore" :reduce="laude => laude.isLaude" :searchable="false" />
                        </label>
                    </div>
                </div>
                <div v-if="statusStore.newEntry" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/2 mt-6 mb-6">
                        <input type="checkbox" class="cursor-pointer" true-value="1" false-value="0"
                            v-model="applicationRequestStore.mandatoryQualifications.degree.abroad" />
                        <label class="ml-2 font-semibold">Titolo acquisito all'estero</label>
                    </div>
                </div>
                <div v-if="statusStore.newEntry" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Data Abilitazione <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input"
                                v-model="applicationRequestStore.mandatoryQualifications.qualification.date"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1" modelType="dd/MM/yyyy"
                                placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Abilitazione Conseguita Presso <span class="text-red-flag">*</span>
                            <input type="text" placeholder="Abilitazione presso"
                                class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="applicationRequestStore.mandatoryQualifications.qualification.at" />
                        </label>
                    </div>
                    <div class="flex w-full lg:w-1/3 mt-4 mb-6 lg:mb-0 items-center lg:pt-6 pt-2 lg:pl-6">
                        <input type="checkbox" class="cursor-pointer" true-value="1" false-value="0"
                            v-model="applicationRequestStore.mandatoryQualifications.qualification.abroad" />
                        <label class="ml-2 font-semibold">Abilitazione all'estero</label>
                    </div>
                </div>
                <div v-if="statusStore.newEntry" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Data Iscrizione all'Ordine <span class="text-red-flag">*</span>
                            <Datepicker inputClassName="dp-custom-input"
                                v-model="applicationRequestStore.mandatoryQualifications.register.date"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1" modelType="dd/MM/yyyy"
                                placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                        <label>Provincia dell'Ordine <span class="text-red-flag">*</span>
                            <v-select v-model="applicationRequestStore.mandatoryQualifications.register.province"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="provinces" label="province"
                                placeholder="Seleziona una provincia" :reduce="province => province.province"
                                :searchable="true" />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4"></div>
                </div>
                <div v-if="statusStore.type.toLowerCase() === 'mmg'" class="mx-2 lg:mx-4 mt-6 font-semibold">Attestato
                    Emergenza territoriale</div>
                <div v-if="statusStore.type.toLowerCase() === 'mmg'" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                    <div class="w-full lg:w-1/3 mt-1 lg:mr-4">
                        <label>Conseguito il
                            <Datepicker inputClassName="dp-custom-input"
                                v-model="applicationRequestStore.mandatoryQualifications.emergency.date"
                                class="w-full md:w-2/3 lg:w-full text-sm mt-1" modelType="dd/MM/yyyy"
                                placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona" cancelText="Annulla"
                                :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()">
                            </Datepicker>
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4 lg:mt-1 lg:mr-4">
                        <label>Presso
                            <input type="text" placeholder="Attestato Emergenza Territoriale presso"
                                class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                                v-model="applicationRequestStore.mandatoryQualifications.emergency.at" />
                        </label>
                    </div>
                    <div class="w-full lg:w-1/3 mt-4"></div>
                </div>
            </div>
            <div class="flex items-center h-96" v-else>
                <Spinner />
            </div>
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">{{ (statusStore.newEntry ||
                statusStore.type.toLowerCase() === 'mmg') ? `Titoli Accademici e di Studio - Sez. 2` : `Titoli Accademici e
                di Studio` }}</div>
            <div class="text-gray-600 font-bold my-4 mx-2">Sotto la propria responsabilità e consapevole delle sanzioni
                penali
                previste dall'art. 76 del D.P.R. n.445 del 28/12/2000 per ipotesi di falsità in atti e dichiarazioni
                mendaci,
                dichiara di essere in possesso dei seguenti titoli di studio: </div>
            <div class="text-red-flag mx-2">ATTENZIONE: ai fini dell'inserimento nella graduatoria sono valutabili
                esclusivamente i titoli posseduti alla data del 31/12 dell'anno precedente a quello di presentazione della
                domanda, fatta eccezione per il diploma di formazione in medicina generale (per MMG) ed il diploma
                di
                specializzazione (per PLS) che possono essere acquisiti ed inseriti a sitema entro e non oltre il
                15
                settembre dello stesso anno. </div>
            <div class="text-red-flag font-bold my-4 mx-2">La domanda deve contenere dichiarazioni concernenti i TITOLI e
                SERVIZI precedentemente NON AUTOCERTIFICATI. </div>
            <div class="flex flex-col" v-if="!qualificationsLoading">
                <div v-if="qualifications && qualifications.length > 0"
                    class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white mx-2 lg:mx-4 items-center">
                    <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Stato</div>
                    <div class="flex w-10/12 border-l border-r border-white p-2 text-sm xl:text-base">Titolo di studio</div>
                    <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Operazioni</div>
                </div>
                <div v-if="qualifications" v-for="(item, index) in qualifications" :key="index" class="flex flex-col">
                    <QualificationSummary :qualification="item" :isLast="index === qualifications.length - 1"
                        @update-qualification-list="getQualifications" />
                </div>
                <button class="btn-primary w-fit mx-auto my-4" @click="addQualificationModal = true">Aggiungi un Titolo di
                    Studio</button>
            </div>
            <div class="flex items-center h-96" v-else>
                <Spinner />
            </div>
        </div>
        <div class="flex w-full justify-center">
            <button class="w-fit mt-6" :class="missingMandatoryFields ? 'btn-disabled' : 'btn-success'"
                @click="saveMandatoryQualifications" :disabled="missingMandatoryFields">Salva</button>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
    <Modal :active="addQualificationModal" title="Inserisci Titolo di Studio"
        card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div v-if="!loading" class="w-full bg-white">
                <QualificationDetails @close-add-qualification-modal="closeModal" />
            </div>
            <div v-else class="flex w-full items-center justify-center mt-20">
                <Spinner />
            </div>
        </template>
    </Modal>
</template>

<script>
import QualificationSummary from '@/components/qualifications/QualificationSummary.vue'
import QualificationDetails from '@/components/qualifications/QualificationDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { useStatusStore } from '@/store/status.js'
import { useUiStore } from '@/store/ui.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'Qualifications',
    components: { QualificationSummary, QualificationDetails, Spinner, Modal },
    computed: {
        ...mapStores(useApplicationRequestStore),
        ...mapStores(useStatusStore),
        ...mapStores(useUiStore),
        missingMandatoryFields() {
            if (this.statusStore.newEntry == '0') {
                return false;
            }
            if (this.applicationRequestStore.mandatoryQualifications.degree && this.applicationRequestStore.mandatoryQualifications.qualification && this.applicationRequestStore.mandatoryQualifications.register) {
                return (!this.applicationRequestStore.mandatoryQualifications.degree.university || !this.applicationRequestStore.mandatoryQualifications.degree.date || !this.applicationRequestStore.mandatoryQualifications.degree.vote || !this.applicationRequestStore.mandatoryQualifications.degree.scale || !this.applicationRequestStore.mandatoryQualifications.degree.laude || !this.applicationRequestStore.mandatoryQualifications.qualification.date || !this.applicationRequestStore.mandatoryQualifications.qualification.at || !this.applicationRequestStore.mandatoryQualifications.register.date || !this.applicationRequestStore.mandatoryQualifications.register.province)
            }
        },
    },
    data() {
        return {
            laudeArray: [
                {
                    label: 'No',
                    isLaude: '0',
                },
                {
                    label: 'Si',
                    isLaude: '1',
                },
            ],
            scaleArray: [],
            provinces: [],
            qualifications: [],
            addQualificationModal: false,
            loading: false,
            generalLoading: false,
            mandatoryLoading: false,
            qualificationsLoading: false,
        }
    },
    async created() {
        await this.applicationRequestStore.setMandatoryQualifications({
            degree: {}, qualification: {}, register: {}, emergency: {}
        })
        this.getDegreeScales()
        this.getProvinces()
        this.getMandatoryQualifications()
        this.getQualifications()
    },
    methods: {
        async getDegreeScales() {
            try {
                const { data } = await Services.getDegreeScales()
                this.scaleArray = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getProvinces() {
            try {
                const { data } = await Services.getProvinces()
                this.provinces = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero delle province",
                    text: "Si è verificato un errore nel recupero dei dati relativi alle province. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getMandatoryQualifications() {
            this.mandatoryLoading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGMandatoryQualifications() : await Services.getPLSMandatoryQualifications()
                await this.updateMandatoryQualificationsData(data)
                if (data && !this.missingMandatoryFields) {
                    this.uiStore.setShowStepperGoForward(true)
                } else {
                    this.uiStore.setShowStepperGoForward(false)
                }
                this.mandatoryLoading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.mandatoryLoading = false
            }
        },
        async getQualifications() {
            this.qualificationsLoading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGQualifications() : await Services.getPLSQualifications()
                this.qualifications = data
                this.qualificationsLoading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.qualificationsLoading = false
            }
        },
        async updateMandatoryQualificationsData(data) {
            if (data && data.length === 0) {
                return
            }
            if (data) {
                await this.applicationRequestStore.setMandatoryQualifications({
                    degree: data.degree ? {
                        university: data.degree.issued_by,
                        date: this.$moment(data.degree.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        vote: data.degree.vote,
                        scale: data.degree.scale,
                        laude: data.degree.honors,
                        abroad: data.degree.foreign_title ? data.degree.foreign_title : '0'
                    } : {},
                    qualification: data.qualification ? {
                        date: this.$moment(data.qualification.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        at: data.qualification.issued_by,
                        abroad: data.qualification.foreign_title ? data.qualification.foreign_title : '0'
                    } : {},
                    register: data.register ? {
                        date: this.$moment(data.register.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        province: data.register.province,
                    } : {},
                    emergency: data.emergency ? {
                        date: this.$moment(data.emergency.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        at: data.emergency.issued_by
                    } : {}
                })
            }
        },

        async saveMandatoryQualifications() {
            this.generalLoading = true

            if (this.missingMandatoryFields) {
                this.$swal({
                    title: "Campi obbligatori mancanti",
                    text: "Alcuni campi obbligatori non sono stati compilati"
                })
                this.generalLoading = false
                return;
            }

            let dataToSend = {}

            if (this.statusStore.newEntry == '1') {
                dataToSend = {
                    degree: {
                        issued_by: this.applicationRequestStore.mandatoryQualifications.degree.university,
                        graduation_date: this.applicationRequestStore.mandatoryQualifications.degree.date,
                        vote: this.applicationRequestStore.mandatoryQualifications.degree.vote,
                        scale: this.applicationRequestStore.mandatoryQualifications.degree.scale,
                        honors: this.applicationRequestStore.mandatoryQualifications.degree.laude,
                        foreign_title: this.applicationRequestStore.mandatoryQualifications.degree.abroad ? this.applicationRequestStore.mandatoryQualifications.degree.abroad : null
                    },
                    qualification: {
                        graduation_date: this.applicationRequestStore.mandatoryQualifications.qualification.date,
                        issued_by: this.applicationRequestStore.mandatoryQualifications.qualification.at,
                        foreign_title: this.applicationRequestStore.mandatoryQualifications.qualification.abroad ? this.applicationRequestStore.mandatoryQualifications.qualification.abroad : null
                    },
                    register: {
                        graduation_date: this.applicationRequestStore.mandatoryQualifications.register.date,
                        province: this.applicationRequestStore.mandatoryQualifications.register.province,
                    }
                }
            }


            if (this.applicationRequestStore.mandatoryQualifications.emergency.date || this.applicationRequestStore.mandatoryQualifications.emergency.at) {
                dataToSend.emergency = {
                    graduation_date: this.applicationRequestStore.mandatoryQualifications.emergency.date,
                    issued_by: this.applicationRequestStore.mandatoryQualifications.emergency.at,
                }
            }

            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.updateMMGMandatoryQualifications(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.updatePLSMandatoryQualifications(dataToSend)
                }
                this.uiStore.setShowStepperGoForward(true)
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.generalLoading = false
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.generalLoading = false
                return;
            }

        },
        closeModal(isSaved) {
            if (isSaved) {
                this.getQualifications()
            }
            this.addQualificationModal = false
        }
    }
}


</script>