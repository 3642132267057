import { defineStore } from 'pinia'

export const useStatusStore = defineStore('status', {
    state: () => ({
        nextStep: '',
        status: '',
        statusId: null,
        type: '',
        hasCertificate: 0,
        degree: '',
        newEntry: false,
        mmgLastApplicationYear:'',
        plsLastApplicationYear:'',
        mmgStartLine: '',
        mmgDeadLine: '',
        plsStartLine: '',
        plsDeadLine: '',
        year: null,
        mmgTempRanking: false,
        mmgFinalRanking: false,
        mmgRankingTypeId: null,
        plsTempRanking: false,
        plsFinalRanking: false,
        plsRankingTypeId: null,
    }),
    getters: {
        getNextStep: (state) => state.nextStep,
        getStatus: (state) => state.status,
        getStatusId: (state) => state.statusId,
        getType: (state) => state.type,
        getHasCertificate: (state) => state.hasCertificate,
        getDegree: (state) => state.degree,
        getNewEntry: (state) => state.newEntry,
        getMmgLastApplicationYear: (state) => state.mmgLastApplicationYear,
        getPlsLastApplicationYear: (state) => state.plsLastApplicationYear,
        getMmgStartLine: (state) => state.mmgStartLine,
        getMmgDeadLine: (state) => state.mmgDeadLine,
        getPlsStartLine: (state) => state.plsStartLine,
        getPlsDeadLine: (state) => state.plsDeadLine,
        getYear: (state) => state.year,
        getMmgTempRanking: (state) => state.mmgTempRanking,
        getMmgFinalRanking: (state) => state.mmgFinalRanking,
        getMmgRankingTypeId: (state) => state.mmgRankingTypeId,
        getPlsTempRanking: (state) => state.plsTempRanking,
        getPlsFinalRanking: (state) => state.plsFinalRanking,
        getPlsRankingTypeId: (state) => state.plsRankingTypeId,
    },
    actions: {
        setNextStep(step) {
            this.nextStep = step
        },
        setStatus(status) {
            this.status = status
        },
        setStatusId(statusId) {
            this.statusId = statusId
        },
        setType(type) {
            this.type = type
        },
        setHasCertificate(value) {
            this.hasCertificate = value
        },
        setDegree(degree) {
            this.degree = degree
        },
        setNewEntry(isNewEntry) {
            this.newEntry = isNewEntry
        },
        setMmgLastApplicationYear(year) {
            this.mmgLastApplicationYear = year
        },
        setPlsLastApplicationYear(year) {
            this.plsLastApplicationYear = year
        },
        setMmgStartLine(startline) {
            this.mmgStartLine = startline
        },
        setMmgDeadLine(deadline) {
            this.mmgDeadLine = deadline
        },
        setPlsStartLine(startline) {
            this.plsStartLine = startline
        },
        setPlsDeadLine(deadline) {
            this.plsDeadLine = deadline
        },
        setYear(year) {
            this.year = year
        },
        setMmgTempRanking(isTemp) {
            this.mmgTempRanking = isTemp
        },
        setMmgFinalRanking(isFinal) {
            this.mmgFinalRanking = isFinal
        },
        setMmgRankingTypeId(id) {
            this.mmgRankingTypeId = id
        },
        setPlsTempRanking(isTemp) {
            this.plsTempRanking = isTemp
        },
        setPlsFinalRanking(isFinal) {
            this.plsFinalRanking = isFinal
        },
        setPlsRankingTypeId(id) {
            this.plsRankingTypeId = id
        },
    },
})