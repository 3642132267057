<template>
    <div class="flex mt-4 mb-2 ml-6 group items-center">
        <font-awesome-icon class="h-4 md:h-6 mr-2 text-primary-dark group-hover:text-primary cursor-pointer"
            icon="fas fa-arrow-left" @click="$router.push('/adminhome')" />
        <div class="text-primary-dark group-hover:text-primary cursor-pointer" @click="$router.push('/adminhome')">Torna
            alla home</div>
    </div>
    <div class="mx-6" v-if="application">
        <div class="flex flex-wrap items-center border border-primary my-4 px-6 py-4 rounded shadow-rankings"
            :class="this.application.status.toLowerCase() === 'con riserva' ? 'bg-orange-200' : ''">
            <div class="flex flex-col w-full md:w-1/4 mb-4 md:mb-0 justify-center">
                <div class="text-primary-dark">Codice</div>
                <div>{{ application.mmg_id || application.pls_id }}</div>
            </div>
            <div class="flex flex-col w-full md:w-1/4 mb-4 md:mb-0 justify-center">
                <div class="text-primary-dark">Stato</div>
                <div class="px-2 rounded shadow-rankings text-white w-fit"
                    :class="application.status.toLowerCase() === 'rifiutata' ? 'bg-red-flag' : (application.status.toLowerCase() === 'accettata' || application.status.toLowerCase() === 'con riserva') ? 'bg-green-flag' : 'bg-orange-500'">
                    {{ application.status }}</div>
            </div>
            <div class="flex flex-col w-full md:w-1/4 mb-4 md:mb-0 justify-center">
                <div class="text-primary-dark">Tipo</div>
                <div>{{ application.new_entry === '1' ? 'Primo inserimento' : 'Integrazione' }}</div>
            </div>
            <div class="flex flex-col w-full md:w-1/4 mb-4 md:mb-0 justify-center">
                <div class="text-primary-dark">Punteggio
                    <font-awesome-icon class="h-4 ml-2 text-primary-dark hover:text-primary cursor-pointer"
                        icon="fas fa-file-arrow-down" title="Scarica calcolo punteggio" @click="downloadCalculationLog" />
                </div>
                <div>{{ (statusStore.mmgRankingTypeId == '2' && application.mmg_id) || (statusStore.plsRankingTypeId == '2'
                    && application.pls_id) ? application.score :
                    application.temp_score }}
                </div>
            </div>
            <div v-if="application.status.toLowerCase() === 'rifiutata'"
                class="flex flex-col w-full mb-4 md:mt-2 md:mb-0 justify-center">
                <div class="text-primary-dark">Motivo del rifiuto</div>
                <div>{{ application.refusal_reason }}</div>
            </div>
            <div v-if="application.status.toLowerCase() === 'accettata' && application.accept_reserve_reason"
                class="flex flex-col w-full mb-4 md:mt-2 md:mb-0 justify-center">
                <div class="text-primary-dark">Motivo scioglimento riserva</div>
                <div>{{ application.accept_reserve_reason }}</div>
            </div>
        </div>
        <Accordion v-if="application.sectors && application.sectors.length > 0" class="w-full" :title="'Settori'"
            :icon="'fas fa-list-check'" :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div class="flex flex-wrap bg-gray-50 rounded shadow-rankings">
                    <div v-for="(sector, index) in application.sectors" :key="index" class="flex w-full md:w-auto p-4">
                        <div class=" px-2">{{ sector.sector }}</div>
                    </div>
                </div>
            </template>
        </Accordion>
        <Accordion class="w-full" :title="'Anagrafica'" :icon="'fas fa-user-doctor'" :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div class="flex flex-wrap bg-gray-50 p-4 rounded shadow-rankings">
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Cognome</div>
                        <div class="mb-4 mt-1">{{ application.lastname }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Nome</div>
                        <div class="mb-4 mt-1">{{ application.firstname }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Sesso</div>
                        <div class="mb-4 mt-1">{{ application.gender }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Codice fiscale</div>
                        <div class="mb-4 mt-1">{{ application.fiscal_code }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Comune di nascita</div>
                        <div class="mb-4 mt-1">{{ application.birth_city }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Provincia di nascita</div>
                        <div class="mb-4 mt-1">{{ application.birth_province }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Nazione di nascita</div>
                        <div class="mb-4 mt-1">{{ application.birth_country }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Data di nascita</div>
                        <div class="mb-4 mt-1">{{ $moment(application.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                        </div>
                    </div>
                    <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Residenza
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Via</div>
                        <div class="mb-4 mt-1">{{ application.res_street }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Civico</div>
                        <div class="mb-4 mt-1">{{ application.res_street_number }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Comune di residenza</div>
                        <div class="mb-4 mt-1">{{ application.res_city }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Provincia di residenza</div>
                        <div class="mb-4 mt-1">{{ application.res_province }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">CAP</div>
                        <div class="mb-4 mt-1">{{ application.res_postal_code }}</div>
                    </div>
                    <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Domicilio
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Via</div>
                        <div class="mb-4 mt-1">{{ application.dom_street }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Civico</div>
                        <div class="mb-4 mt-1">{{ application.dom_street_number }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Comune del domicilio</div>
                        <div class="mb-4 mt-1">{{ application.dom_city }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Provincia del domicilio</div>
                        <div class="mb-4 mt-1">{{ application.dom_province }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">CAP</div>
                        <div class="mb-4 mt-1">{{ application.dom_postal_code }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">C/O</div>
                        <div class="mb-4 mt-1">{{ application.dom_at || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full text-primary-dark px-2 my-2 border-b border-primary-dark">Contatti
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Telefono Casa</div>
                        <div class="mb-4 mt-1">{{ application.phone_number || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Telefono Ufficio</div>
                        <div class="mb-4 mt-1">{{ application.office_phone_number || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Cellulare Personale</div>
                        <div class="mb-4 mt-1">{{ application.mobile || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Cellulare Aziendale</div>
                        <div class="mb-4 mt-1">{{ application.company_mobile || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">Email</div>
                        <div class="mb-4 mt-1">{{ application.email || ' - ' }}</div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/4 px-2">
                        <div class="text-primary-dark">PEC</div>
                        <div class="mb-4 mt-1">{{ application.pec }}</div>
                    </div>
                </div>
            </template>
        </Accordion>
        <Accordion class="w-full" :title="'Autocertificazione'" :icon="'fas fa-file-lines'"
            :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div class="flex flex-wrap bg-gray-50 p-4 rounded shadow-rankings">
                    <div class="flex w-full px-2 items-center mb-4">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-check" />
                        <label class="ml-2">{{ application.req_type.toLowerCase() === 'mmg' ? `di non essere titolare di
                            incarico a tempo indeterminato nei suddetti settori prescelti per i quali intende concorrere` :
                            `di non essere titolare di incarico a tempo indeterminato di pediatria di libera scelta`
                        }}</label>
                    </div>
                    <div class="flex w-full px-2 items-center mb-4">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-check" />
                        <label class="ml-2">DI NON FRUIRE DEL TRATTAMENTO PER INVALIDITA' ASSOLUTA E PERMANENTE da parte del
                            fondo di previdenza competente di cui al Decreto 15 ottobre 1976 del Ministero del lavoro e
                            della
                            previdenza sociale</label>
                    </div>
                    <div class="flex w-full px-2 items-center mb-4">
                        <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-check" />
                        <label class="ml-2">DI NON FRUIRE DEL TRATTAMENTO DI QUIESCENZA COME PREVISTO DALLA NORMATIVA
                            VIGENTE.
                            (Tale incompatibilità non opera nei confronti di medici che beneficiano delle sole prestazioni
                            delle
                            quote "A e B" del fondo di previdenza generale dell'ENPAM)</label>
                    </div>
                    <div class="flex flex-col w-full px-2 mb-4">
                        <div class="flex items-center">
                            <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-check" />
                            <label v-if="application.nationality === 'it'" class="ml-2">di essere cittadino italiano</label>
                            <label v-if="application.nationality === 'eu'" class="ml-2">di essere cittadino di Stato membro
                                dell'Unione Europea</label>
                            <label v-if="application.nationality === 'noEu'" class="ml-2">di essere cittadino del seguente
                                Stato ed in possesso di uno dei requisiti di cui
                                all'art.38, commi 1 e 3 bis del D.Lgs. 165/2001 e s.m.i.</label>
                        </div>
                        <div class="flex items-center">
                            <label class="ml-7 mt-2"
                                v-if="application.nationality === 'eu' || application.nationality === 'noEu'"><span
                                    class="text-primary-dark">Stato: </span>{{ application.nat_country }}</label>
                        </div>
                    </div>
                    <div class="flex flex-col w-full px-2 mb-4">
                        <div class="flex items-center">
                            <font-awesome-icon class="h-5 w-5 text-primary-dark" icon="fas fa-check" />
                            <label v-if="application.agreement_revoked === '0'" class="ml-2">{{
                                application.req_type.toLowerCase() === 'mmg' ? `DI NON AVERE subito PROVVEDIMENTI DI REVOCA
                                DEL RAPPORTO CONVENZIONALE DI MEDICO DI MEDICINA GENERALE` : `DI NON AVERE subito
                                PROVVEDIMENTI DI REVOCA DEL RAPPORTO CONVENZIONALE DI PEDIATRA DI LIBERA SCELTA` }}</label>
                            <label v-if="application.agreement_revoked === '1'" class="ml-2">{{
                                application.req_type.toLowerCase() === 'mmg' ? `DI AVERE subito PROVVEDIMENTI DI REVOCA DEL
                                RAPPORTO CONVENZIONALE DI MEDICO DI MEDICINA GENERALE` : `DI AVERE subito PROVVEDIMENTI DI
                                REVOCA DEL RAPPORTO CONVENZIONALE DI PEDIATRA DI LIBERA SCELTA` }}</label>
                        </div>
                        <div class="flex flex-col w-full justify-center">
                            <label v-if="application.agreement_revoked === '1'" class="ml-7 mt-2"><span
                                    class="text-primary-dark">Data di revoca: </span>{{ $moment(application.revoked_at,
                                        'YYYY-MM-DD').format('DD/MM/YYYY') }}</label>
                            <label v-if="application.agreement_revoked === '1'" class="ml-7 mt-2"><span
                                    class="text-primary-dark">Azienda: </span>{{ application.revoking_company }}</label>
                        </div>
                    </div>
                </div>
            </template>
        </Accordion>
        <Accordion class="w-full" :title="'Titoli Accademici'" :icon="'fas fa-graduation-cap'"
            :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div v-if="application.acTitles && application.acTitles.length > 0"
                    class="flex flex-col bg-gray-50 p-2 rounded shadow-rankings">
                    <div
                        class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white items-center">
                        <!-- <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Stato</div> -->
                        <div class="flex w-full p-2 text-sm xl:text-base">Titolo di studio</div>
                        <!-- <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Operazioni</div> -->
                    </div>
                    <div v-for="(item, index) in application.acTitles" :key="index" class="flex flex-col">
                        <AdminQualificationSummary :qualification="item"
                            :isLast="index === application.acTitles.length - 1" />
                    </div>
                </div>
                <div v-else class="flex flex-col bg-gray-50 p-2 rounded shadow-rankings"> Nessun titolo accademico
                    presentato</div>
            </template>
        </Accordion>
        <Accordion class="w-full" :title="'Titoli di servizio'" :icon="'fas fa-stethoscope'"
            :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div v-if="application.jobTitles && application.jobTitles.length > 0"
                    class="flex flex-col bg-gray-50 p-2 rounded shadow-rankings">
                    <div
                        class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white items-center">
                        <!-- <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Stato</div> -->
                        <div class="flex w-full p-2 text-sm xl:text-base">Titolo di servizio</div>
                        <!-- <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Operazioni</div> -->
                    </div>
                    <div v-for="(item, index) in application.jobTitles" :key="index" class="flex flex-col">
                        <AdminJobSummary :job="item" :isLast="index === application.jobTitles.length - 1" />
                    </div>
                </div>
                <div v-else class="flex flex-col bg-gray-50 p-2 rounded shadow-rankings"> Nessun titolo di servizio
                    presentato</div>
            </template>
        </Accordion>
        <Accordion class="w-full" :title="'Allegati'" :icon="'fas fa-paperclip'" :textColorClass="'text-primary-dark'">
            <template #content class="transition duration-500 ease-in-out">
                <div class="flex flex-col bg-gray-50 p-2 rounded shadow-rankings">
                    <AttachmentsSummary :attachments="application.attachments" />
                </div>
            </template>
        </Accordion>
        <div v-if="(application.req_type.toLowerCase() === 'mmg' && statusStore.mmgRankingTypeId != '2') || (application.req_type.toLowerCase() === 'pls' && statusStore.plsRankingTypeId != '2')"
            class="flex flex-col md:flex-row items-center justify-center mt-8 mb-8 bottom-0">
            <button
                v-if="application.status.toLowerCase() !== 'accettata' && application.status.toLowerCase() !== 'con riserva'"
                class="w-1/2 md:w-auto btn-success md:mr-10" @click="acceptApplication">Accetta
            </button>
            <button v-if="application.status.toLowerCase() === 'con riserva'" class="w-1/2 md:w-auto btn-success md:mr-10" @click="acceptReserveModal = true">Sciogli riserva</button>    
            <button v-if="application.status.toLowerCase() !== 'rifiutata'"
                class="w-1/2 md:w-auto btn-danger mt-4 md:mt-0 md:mr-10" @click="rejectModal = true">Rifiuta
            </button>
            <button class="w-1/2 md:w-auto btn-primary mt-4 md:mt-0" @click="reopenApplication">Riapri</button>
        </div>
    </div>
    <Modal :active="rejectModal" title="Motivo del rifiuto" card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <RejectMotivation @close-reject-modal="rejectModal = false" :application="application"
                    @update="$router.push('/adminhome')" />
            </div>
        </template>
    </Modal>
    <Modal :active="acceptReserveModal" title="Giustificare lo scioglimento della riserva" card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div class="w-full bg-white">
                <AcceptReserveMotivation @close-accept-reserve-modal="acceptReserveModal = false" :application="application"
                    @update="$router.push('/adminhome')" />
            </div>
        </template>
    </Modal>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import AdminQualificationSummary from '@/components/administrator/AdminQualificationSummary.vue'
import AdminJobSummary from '@/components/administrator/AdminJobSummary.vue'
import AttachmentsSummary from '@/components/administrator/AttachmentsSummary.vue'
import RejectMotivation from '@/components/administrator/RejectMotivation.vue'
import AcceptReserveMotivation from '@/components/administrator/AcceptReserveMotivation.vue'
import Modal from '@/components/Modal.vue'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: "ApplicationDetails",
    components: { Accordion, AdminQualificationSummary, AdminJobSummary, AttachmentsSummary, AcceptReserveMotivation, RejectMotivation, Modal },
    data: () => ({
        application: null,
        acceptReserveModal: false,
        rejectModal: false
    }),
    async created() {
        await this.getApplicationDetails()
    },
    computed: {
        ...mapStores(useStatusStore),
    },
    methods: {
        async getApplicationDetails() {
            try {
                if (!this.$route.params.type || !this.$route.params.id) {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore imprevisto. Prova a ricaricare la pagina o contatta il gestore del servizio."
                    })
                }
                const { data } = this.$route.params.type === 'mmg' ? await Services.getMMGApplicationDetails(this.$route.params.id) : await Services.getPLSApplicationDetails(this.$route.params.id)
                this.application = data
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore nel recupero dei dati. Contatta il gestore del servizio."
                })
            }
        },
        async acceptApplication() {
            try {
                if (!this.$route.params.type || !this.$route.params.id) {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore imprevisto. Prova a ricaricare la pagina o contatta il gestore del servizio."
                    })
                }
                this.$swal({
                    title: 'Attenzione!',
                    text: 'Accettare la domanda?',
                    showCancelButton: true,
                    confirmButtonText: 'Accetta',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const data = this.$route.params.type === 'mmg' ? await Services.MMGAcceptApplication(this.$route.params.id) : await Services.PLSAcceptApplication(this.$route.params.id)
                            this.$swal({
                                title: "Salvataggio riuscito",
                                text: "La domanda è stata accettata."
                            })
                            this.$router.push('/adminhome')
                        } catch (e) {
                            this.$swal({
                                title: "Errore",
                                text: "Si è verificato un errore. Contatta il gestore del servizio."
                            })
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio."
                })
            }
        },
        async reopenApplication() {
            try {
                if (!this.$route.params.type || !this.$route.params.id) {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore imprevisto. Prova a ricaricare la pagina o contatta il gestore del servizio."
                    })
                }
                this.$swal({
                    title: 'Riaprire la domanda?',
                    text: 'Riaprendo la domanda si da la possibilità al medico di modificare o integrare i dati inseriti.',
                    showCancelButton: true,
                    confirmButtonText: 'Riapri',
                    cancelButtonText: 'Annulla'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const data = this.$route.params.type === 'mmg' ? await Services.MMGReopenApplication(this.$route.params.id) : await Services.PLSReopenApplication(this.$route.params.id)
                            this.$swal({
                                title: "Salvataggio riuscito",
                                text: "La domanda è stata riaperta."
                            })
                            this.$router.push('/adminhome')
                        } catch (error) {
                            this.$swal({
                                title: "Errore",
                                text: "Si è verificato un errore. Contatta il gestore del servizio."
                            })
                        }
                    }
                })
            } catch (e) {
                this.$swal({
                    title: "Errore",
                    text: "Si è verificato un errore. Contatta il gestore del servizio."
                })
            }
        },
        async downloadCalculationLog() {
            try {
                if (this.$route.params.type === 'mmg') {
                    await Services.downloadMMGCalculationLog(this.$route.params.id)
                } else if (this.$route.params.type === 'pls') {
                    await Services.downloadPLSCalculationLog(this.$route.params.id)
                }
            } catch (error) {
                this.$swal({
                    title: "Errore durante il download",
                    text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
                })
            }
        }
    }
}
</script>