<template>
    <div v-if="!generalLoading">
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4">
            <div class="flex flex-col items-center justify-center bg-primary text-white p-2 rounded-t">
                <div class="text-lg font-semibold uppercase">Dichiarazione sostitutiva dell'atto di notorietà</div>
                <div class="text-sm">(art. 46 D.P.R. n. 445 del 28/12/2000)</div>
            </div>
            <div class="text-gray-600 font-bold my-4 mx-2">Sotto la propria responsabilità e consapevole delle sanzioni
                penali
                previste dall'art. 76 del D.P.R. n.445 del 28/12/2000 per ipotesi di falsità in atti e dichiarazioni
                mendaci,
                dichiara di essere in possesso dei seguenti titoli di servizio:
            </div>
            <div class="text-red-flag mx-2">ATTENZIONE: ai fini dell'inserimento nella graduatoria sono valutabili
                esclusivamente i titoli posseduti alla data del 31/12 dell'anno precedente a quello di presentazione della
                domanda, fatta eccezione per il diploma di formazione in medicina generale (codice A7 per MMG) ed il diploma
                di
                specializzazione (codice A11 per PLS) che possono essere acquisiti ed inseriti a sitema entro e non oltre il
                15
                settembre dello stesso anno.
            </div>
            <div class="text-red-flag font-bold my-4 mx-2">La domanda deve contenere dichiarazioni concernenti i TITOLI e
                SERVIZI precedentemente NON AUTOCERTIFICATI.
            </div>
            <div class="flex flex-col" v-if="!jobsLoading">
                <div v-if="jobs.length > 0"
                    class="hidden lg:flex rounded-t shadow-rankings border border-primary bg-primary text-white mx-2 lg:mx-4 items-center">
                    <div class="flex w-1/12 justify-center p-2 text-sm xl:text-base">Stato</div>
                    <div class="flex w-10/12 border-l border-r border-white p-2 text-sm xl:text-base">Titolo di servizio
                    </div>
                    <div class="flex w-1/12 p-2 items-center justify-center text-sm xl:text-base">Operazioni</div>
                </div>
                <div v-for="(item, index) in jobs" :key="index" class="flex flex-col">
                    <JobSummary :job="item" :isLast="index === jobs.length - 1" @update-job-list="getJobs" />
                </div>
                <button class="btn-primary w-fit mx-auto my-4" @click="addJobModal = true">Aggiungi un Titolo di
                    Servizio</button>
            </div>
            <div class="flex items-center h-96" v-else>
                <Spinner />
            </div>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
    <Modal :active="addJobModal" title="Inserisci Titolo di Servizio" card-class="w-full lg:w-10/12 sm:rounded-lg bg-white">
        <template #body>
            <div v-if="!loading" class="w-full bg-white">
                <JobDetails @close-add-job-modal="closeModal" />
            </div>
            <div v-else class="flex w-full items-center justify-center mt-20">
                <Spinner />
            </div>
        </template>
    </Modal>
</template>

<script>

import JobSummary from '@/components/jobs/JobSummary.vue'
import JobDetails from '@/components/jobs/JobDetails.vue'
import Spinner from '@/components/Spinner.vue'
import Modal from '@/components/Modal.vue'
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { useStatusStore } from '@/store/status.js'
import { useUiStore } from '@/store/ui.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'Jobs',
    components: { JobSummary, JobDetails, Spinner, Modal },
    data() {
        return {
            provinces: [],
            jobs: [],
            addJobModal: false,
            loading: false,
            generalLoading: false,
            jobsLoading: false,
        }
    },
    created() {
        this.uiStore.setShowStepperGoForward(true)
        this.getJobs()
    },
    computed: {
        ...mapStores(useApplicationRequestStore),
        ...mapStores(useStatusStore),
        ...mapStores(useUiStore),
    },
    methods: {
        async getJobs() {
            this.jobsLoading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGJobs() : await Services.getPLSJobs()
                this.jobs = data
                for (var i in this.jobs) {
                    for (var j in this.jobs[i].periods) {
                        this.jobs[i].periods[j].start = this.$moment(this.jobs[i].periods[j].start, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        this.jobs[i].periods[j].end = this.$moment(this.jobs[i].periods[j].end, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    }
                }
                this.jobsLoading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.jobsLoading = false
            }
        },
        closeModal(isSaved) {
            if (isSaved) {
                this.getJobs()
            }
            this.addJobModal = false
        }
    }
}


</script>