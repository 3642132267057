<template>
  <div class="flex flex-col pt-10 px-2 md:px-6">
    <div class="w-full text-center font-medium text-2xl md:text-4xl text-gray-600">Graduatorie MMG/PLS</div>  
    <button class="btn-primary md:h-14 w-10/12 sm:w-auto mx-auto mt-10" @click="spidSignin"> <!-- FIXME andare alla pagina di maintenance solo se è flaggato (chiamare rest e verificare) $router.push('/maintenance')-->
      <div class="flex justify-center">
        <span class="flex items-center mr-4"><font-awesome-icon class="h-5 text-white" icon="fas fa-circle-user"
            title="Entra con Identità Digitale" /></span>
        <span class="md:text-lg">Entra con Identità Digitale</span>
      </div>
    </button>
    <div class="flex flex-col mx-auto mt-8">
      <div class="flex group cursor-pointer mt-6" @click="downloadMMGInstructions()">
        <font-awesome-icon class="h-4 md:h-6 mr-4 text-primary group-hover:text-primary-dark" icon="fas fa-file-pdf" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-lg">
          Avvertenze Generali MMG
        </div>
      </div>
      <div class="flex group cursor-pointer mt-6" @click="downloadPLSInstructions()">
        <font-awesome-icon class="h-4 md:h-6 mr-4 text-primary group-hover:text-primary-dark" icon="fas fa-file-pdf" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-lg">
          Avvertenze Generali PLS
        </div>
      </div>
      <div class="flex group cursor-pointer mt-6" @click="downloadReceiptForm()">
        <font-awesome-icon class="h-4 md:h-6 mr-4 text-primary group-hover:text-primary-dark" icon="fas fa-file-pdf" />
        <div class="text-primary group-hover:text-primary-dark text-sm md:text-lg">
          Attestato Pagamento Marca da Bollo
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Landing',
  methods: {
    downloadMMGInstructions(){
      window.open(process.env.VUE_APP_AUTH_API_BASE_URL + "/storage/MoliseAvvMMG.pdf", "_blank")
    },
    downloadPLSInstructions(){
      window.open(process.env.VUE_APP_AUTH_API_BASE_URL + "/storage/MoliseAvvPLS.pdf", "_blank")
    },
    downloadReceiptForm(){
      window.open(process.env.VUE_APP_AUTH_API_BASE_URL + "/storage/MoliseAllegatoC.pdf", "_blank")
    },
    spidSignin() {
      window.open(process.env.VUE_APP_AUTH_API_BASE_URL + '/spid/login', '_self')
    }
  }
}
</script>

<style scoped>
:deep() {
  --vs-controls-color: #0185CF;
  --vs-border-color: #0185CF;
  --vs-selected-bg: #0185CF;
  --vs-dropdown-option--active-bg: #0185CF;
}
</style>