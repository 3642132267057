import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import Datepicker from '@vuepic/vue-datepicker';
import vSelect from "vue-select";
import VueCookies from 'vue-cookies'

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(fas, far)

/* css import */
import './assets/tailwind.css'
import './assets/styles/index.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@vuepic/vue-datepicker/dist/main.css'
import "vue-select/dist/vue-select.css";

const pinia = createPinia()
const app = createApp(App)

moment.locale('it')
app.config.globalProperties.$moment=moment;

const swalOptions = {
    confirmButtonColor: '#0185CF',
    cancelButtonColor: '#d91918',
  };


app.component('font-awesome-icon', FontAwesomeIcon)
app.component('Datepicker', Datepicker);
app.component("v-select", vSelect);

app.use(router)
app.use(VueCookies)
app.use(pinia)
app.use(VueSweetalert2,swalOptions)

app.mount('#app')
