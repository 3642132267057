<template>
    <div v-if="!loading">
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4 pb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Dati anagrafici</div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Cognome <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Cognome"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.lastname" disabled />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Nome <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Nome"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.firstname" disabled />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Sesso <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Sesso"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.gender" disabled />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Codice Fiscale <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Codice Fiscale"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.fiscalCode" disabled />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Comune di nascita <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Comune di nascita"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.birthCity" disabled />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Provincia di nascita <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Provincia di nascita"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.birthProvince" disabled />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Nazione di nascita <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Nazione di nascita"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.birthState" disabled />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Data di nascita <span class="text-red-flag">*</span>
                        <Datepicker inputClassName="dp-custom-input"
                            v-model="applicationRequestStore.personalData.birthDate" :textInput="true"
                            class="w-full md:w-2/3 text-sm mt-1" modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa"
                            locale="it" selectText="Seleziona" cancelText="Annulla" :enableTimePicker="false"
                            format="dd/MM/yyyy" disabled>
                        </Datepicker>
                    </label>
                </div>
            </div>
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4 pb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Residenza</div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Via <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Inserire la via"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.residenceStreet" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Civico <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Inserire il numero civico"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.residenceStreetNumber" />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Comune di residenza <span class="text-red-flag">*</span>
                        <v-select v-model="applicationRequestStore.personalData.residenceCity"
                            class="w-full md:w-2/3 text-sm mt-1" :options="cities" label="city_name"
                            placeholder="Seleziona il comune" :reduce="city => city.city_id"
                            @update:modelValue="updatePersonalData(null, applicationRequestStore.personalData.residenceCity, 'residence')"
                            :searchable="true" :clearable="false" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Provincia di residenza <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Provincia di residenza"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.residenceProvince" @change="updatePersonalData()"
                            disabled />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>CAP <span class="text-red-flag">*</span>
                        <input type="text" placeholder="Inserire il CAP"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.residenceCap" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4"></div>
            </div>
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4 pb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Domicilio (da dichiarare solo se diverso
                dalla residenza)</div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Via
                        <input type="text" placeholder="Inserire la via"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.domicileStreet" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Civico
                        <input type="text" placeholder="Inserire il numero civico"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.domicileStreetNumber" />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Comune del domicilio
                        <v-select v-model="applicationRequestStore.personalData.domicileCity"
                            class="w-full md:w-2/3 text-sm mt-1" :options="cities" label="city_name"
                            placeholder="Seleziona il comune" :reduce="city => city.city_id"
                            @update:modelValue="updatePersonalData(null, applicationRequestStore.personalData.domicileCity, 'domicile')"
                            :searchable="true" :clearable="false" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Provincia del domicilio
                        <input type="text" placeholder="Provincia del domicilio"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.domicileProvince" @change="updatePersonalData()"
                            disabled />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>CAP
                        <input type="text" placeholder="Inserire il CAP"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.domicileCap" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>C/O
                        <input type="text" placeholder="C/O"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.domicileCo" />
                    </label>
                </div>
            </div>
        </div>
        <div class="flex flex-col rounded shadow-rankings border border-primary mb-4 pb-4">
            <div class="flex bg-primary text-white font-semibold p-2 rounded-t">Contatti</div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Telefono Casa
                        <input type="text" placeholder="Inserire il numero di telefono di casa"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.phone" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Telefono Ufficio
                        <input type="text" placeholder="Inserire il numero di telefono dell'ufficio"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.businessPhone" />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Cellulare Personale
                        <input type="text" placeholder="Inserire il numero di cellulare personale"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.mobile" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>Cellulare Aziendale
                        <input type="text" placeholder="Inserire il numero di cellulare aziendale"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.businessMobile" />
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
                <div class="w-full lg:w-1/2 mt-4 lg:mr-4">
                    <label>Email <span class="text-red-flag">*</span>
                        <input type="email" placeholder="Inserire l'indirizzo email"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.email" @blur="validateEmail" />
                    </label>
                </div>
                <div class="w-full lg:w-1/2 mt-4">
                    <label>PEC <span class="text-red-flag">*</span>
                        <input type="email" placeholder="Inserire l'indirizzo della PEC"
                            class="w-full md:w-2/3 text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                            v-model="applicationRequestStore.personalData.pec" @blur="validatePec" />
                    </label>
                </div>
            </div>
        </div>
        <div class="flex w-full justify-center">
            <button class="w-fit mt-6" :class="missingMandatoryFields ? 'btn-disabled' : 'btn-success'"
                @click="savePersonalData" :disabled="missingMandatoryFields">Salva</button>
        </div>
    </div>
    <div class="flex items-center h-96" v-else>
        <Spinner />
    </div>
</template>

<script>
import { useApplicationRequestStore } from '@/store/applicationRequest.js'
import { useUiStore } from '@/store/ui.js'
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Spinner from '@/components/Spinner.vue'
import Services from '@/services'

export default {
    name: 'PersonalData',
    components: { Spinner },
    computed: {
        ...mapStores(useApplicationRequestStore),
        ...mapStores(useUiStore),
        ...mapStores(useStatusStore),
        missingMandatoryFields() {
            return (!this.applicationRequestStore.personalData.residenceStreet || !this.applicationRequestStore.personalData.residenceStreetNumber || !this.applicationRequestStore.personalData.residenceCity || !this.applicationRequestStore.personalData.residenceProvince || !this.applicationRequestStore.personalData.residenceCap || !this.applicationRequestStore.personalData.email || !this.applicationRequestStore.personalData.pec)
        },
        missingDomicileFields() {
            return (!this.applicationRequestStore.personalData.domicileStreet || !this.applicationRequestStore.personalData.domicileStreetNumber || !this.applicationRequestStore.personalData.domicileCity || !this.applicationRequestStore.personalData.domicileProvince || !this.applicationRequestStore.personalData.domicileCap)
        }
    },
    data() {
        return {
            cities: [],
            loading: false,
        }
    },
    created() {
        this.getCities()
        this.getPersonalData()
    },
    methods: {
        async getCities() {
            try {
                const { data } = await Services.getCities()
                this.cities = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero delle città",
                    text: "Si è verificato un errore nel recupero dei dati relativi alle città. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getPersonalData() {
            this.loading = true
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGContacts() : await Services.getPLSContacts()
                await this.updatePersonalData(data)
                if (data && !this.missingMandatoryFields) {
                    this.uiStore.setShowStepperGoForward(true)
                } else {
                    this.uiStore.setShowStepperGoForward(false)
                }
                this.loading = false
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
                this.loading = false
            }
        },
        async updatePersonalData(data = null, cityId = null, cityType = null) {
            if (cityId) {
                const selectedCity = this.cities.find(element => element.city_id === cityId)
                if (cityType === 'residence') {
                    this.applicationRequestStore.personalData.residenceProvince = selectedCity.province
                } else if (cityType === 'domicile') {
                    this.applicationRequestStore.personalData.domicileProvince = selectedCity.province
                }
            }
            await this.applicationRequestStore.setPersonalData({
                lastname: data ? data.lastname : this.applicationRequestStore.personalData.lastname,
                firstname: data ? data.firstname : this.applicationRequestStore.personalData.firstname,
                gender: data ? data.gender : this.applicationRequestStore.personalData.gender,
                fiscalCode: data ? data.fiscal_code : this.applicationRequestStore.personalData.fiscalCode,
                birthCity: data ? data.birth_city : this.applicationRequestStore.personalData.birthCity,
                birthProvince: data ? data.birth_province : this.applicationRequestStore.personalData.birthProvince,
                birthState: data ? data.birth_country : this.applicationRequestStore.personalData.birthState,
                birthDate: data ? this.$moment(data.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : this.applicationRequestStore.personalData.birthDate,
                residenceStreet: data ? data.res_street : this.applicationRequestStore.personalData.residenceStreet,
                residenceStreetNumber: data ? data.res_street_number : this.applicationRequestStore.personalData.residenceStreetNumber,
                residenceCity: data ? data.res_city_id : this.applicationRequestStore.personalData.residenceCity,
                residenceProvince: data ? data.res_province : this.applicationRequestStore.personalData.residenceProvince,
                residenceCap: data ? data.res_postal_code : this.applicationRequestStore.personalData.residenceCap,
                domicileStreet: data ? data.dom_street : this.applicationRequestStore.personalData.domicileStreet,
                domicileStreetNumber: data ? data.dom_street_number : this.applicationRequestStore.personalData.domicileStreetNumber,
                domicileCity: data ? data.dom_city_id : this.applicationRequestStore.personalData.domicileCity,
                domicileProvince: data ? data.dom_province : this.applicationRequestStore.personalData.domicileProvince,
                domicileCap: data ? data.dom_postal_code : this.applicationRequestStore.personalData.domicileCap,
                domicileCo: data ? data.dom_at : this.applicationRequestStore.personalData.domicileCo,
                phone: data ? data.phone_number : this.applicationRequestStore.personalData.phone,
                businessPhone: data ? data.office_phone_number : this.applicationRequestStore.personalData.businessPhone,
                mobile: data ? data.mobile : this.applicationRequestStore.personalData.mobile,
                businessMobile: data ? data.company_mobile : this.applicationRequestStore.personalData.businessMobile,
                email: data ? data.email : this.applicationRequestStore.personalData.email,
                pec: data ? data.pec : this.applicationRequestStore.personalData.pec,
            })
        },
        validateEmail() {
            if (!this.applicationRequestStore.personalData.email || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.applicationRequestStore.personalData.email))) {
                this.$swal({
                    title: "Campo email non valido",
                    text: "Il campo email non è stato compilato correttamente"
                })
                return;
            }
        },
        validatePec() {
            if (!this.applicationRequestStore.personalData.pec || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.applicationRequestStore.personalData.pec))) {
                this.$swal({
                    title: "Campo pec non valido",
                    text: "Il campo pec non è stato compilato correttamente"
                })
                return;
            }
        },

        async savePersonalData() {
            this.loading = true

            if (this.missingMandatoryFields) {
                this.$swal({
                    title: "Campi obbligatori mancanti",
                    text: "Alcuni campi obbligatori non sono stati compilati"
                })
                this.loading = false
                return;
            }

            if (this.applicationRequestStore.personalData.domicileStreet || this.applicationRequestStore.personalData.domicileStreetNumber || this.applicationRequestStore.personalData.domicileCity || this.applicationRequestStore.personalData.domicileProvince || this.applicationRequestStore.personalData.domicileCap || this.applicationRequestStore.personalData.domicileCo) {
                if (this.missingDomicileFields) {
                    this.$swal({
                        title: "Attenzione campi domicilio mancanti",
                        text: "Alcuni campi del domicilio sono stati compilati. Riempire i restanti campi del domicilio o cancellare quelli inseriti. Il campo C/O non è obbligatorio."
                    })
                    this.loading = false
                    return;
                }
            }

            const dataToSend = {
                res_street: this.applicationRequestStore.personalData.residenceStreet,
                res_street_number: this.applicationRequestStore.personalData.residenceStreetNumber,
                res_city_id: this.applicationRequestStore.personalData.residenceCity,
                res_province: this.applicationRequestStore.personalData.residenceProvince,
                res_postal_code: this.applicationRequestStore.personalData.residenceCap,
                dom_street: this.applicationRequestStore.personalData.domicileStreet,
                dom_street_number: this.applicationRequestStore.personalData.domicileStreetNumber,
                dom_city_id: this.applicationRequestStore.personalData.domicileCity,
                dom_province: this.applicationRequestStore.personalData.domicileProvince,
                dom_postal_code: this.applicationRequestStore.personalData.domicileCap,
                dom_at: this.applicationRequestStore.personalData.domicileCo,
                phone_number: this.applicationRequestStore.personalData.phone,
                office_phone_number: this.applicationRequestStore.personalData.businessPhone,
                mobile: this.applicationRequestStore.personalData.mobile,
                company_mobile: this.applicationRequestStore.personalData.businessMobile,
                email: this.applicationRequestStore.personalData.email,
                pec: this.applicationRequestStore.personalData.pec,
            }

            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    await Services.updateMMGContacts(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    await Services.updatePLSContacts(dataToSend)
                }
                this.uiStore.setShowStepperGoForward(true)
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.loading = false
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.loading = false
                return;
            }
        },
    }
}
</script>