<template>
    <transition enter-active-class="transition-all duration-300 ease-out-quad"
        leave-active-class="transition-all duration-300 ease-out-quad" enter-class="opacity-0"
        enter-to-class="opacity-100" leave-class="opacity-100" leave-to-class="opacity-0" @after-enter="isBody = true">
        <div class="fixed flex inset-0 bg-black bg-opacity-25 z-40 overflow-auto" v-if="isOpen"
            @click="$emit('dialog-click')">
            <transition enter-active-class="transition-all duration-300 ease-out-quad"
                leave-active-class="transition-all duration-300 ease-out-quad" enter-class="scale-50 opacity-0"
                enter-to-class="scale-100 opacity-100" leave-class="scale-100 opacity-100"
                leave-to-class="scale-50 opacity-0" @after-leave="isOpen = false">
                <div class="absolute sm:left-1/2 sm:-translate-x-1/2 card transform sm:translate-y-32"
                    :class="cardClass" role="dialog" :aria-labelledby="title" v-if="isBody">
                    <slot name="header" :close="close">

                        <div class="flex justify-end p-4 sm:p-6 items-center leading-none" v-if="title">
                            <div class="font-semibold uppercase w-full text-lg xl:text-xl text-black text-center">
                                {{  title  }}
                            </div>
                            <span v-if="userClose" @click.prevent="close" class="cursor-pointer text-primary text-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.086" height="20.086"
                                    viewBox="0 0 20.086 20.086">
                                    <g id="Raggruppa_2175" data-name="Raggruppa 2175"
                                        transform="translate(1.414 1.414)">
                                        <line id="Linea_10" data-name="Linea 10" x2="17.258" y2="17.258" fill="none"
                                            stroke="#229fd5" stroke-linecap="round" stroke-width="2" />
                                        <line id="Linea_11" data-name="Linea 11" x1="17.258" y2="17.258" fill="none"
                                            stroke="#229fd5" stroke-linecap="round" stroke-width="2" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </slot>
                    <slot name="body" :close="close"></slot>
                    <slot name="actions" :close="close"></slot>
                </div>
            </transition>
        </div>
    </transition>
</template>
  
  <script>

export default {
    data: () => ({
        isOpen: false,
        isBody: false
    }),
    props: {
        userClose: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        cardClass: {
            type: String,
            required: false,
            default: "w-full md:w-2/3 lg:w-1/2"
        }
    },
    components: {},
    mounted() {
        this.isOpen = this.active;
        this.isBody = this.active;
    },
    methods: {
        close() {
            this.isBody = false;
        },
        open() {
            this.isOpen = true;
        }
    },
    watch: {
        active(value) {
            this.isOpen = value
        }
    }
};
</script>
  
  <style scoped>
  .card {
      @apply shadow-lg bg-white;
  }
  </style>