<template>
    <div class="flex flex-col">
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/2 mt-4 lg:mr-10">
                <label>Titolo di studio <span class="text-red-flag">*</span>
                    <v-select v-model="qualificationEntitlementId" class="w-full text-sm mt-1"
                        :options="qualificationEntitlements" label="title" placeholder="Seleziona il titolo di studio"
                        :reduce="entitl => entitl.title_id" :searchable="true" :clearable="false"
                        :disabled="qualification ? true : false" />
                </label>
            </div>
            <div class="w-full lg:w-1/2 mt-4">
                <label>Conseguito il <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="date" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()"
                        :disabled="!hasCertificate">
                    </Datepicker>
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/2 mt-4 lg:mr-10">
                <label>Inizio periodo <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="startDate" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()">
                    </Datepicker>
                </label>
            </div>
            <div class="w-full lg:w-1/2 mt-4">
                <label>Fine periodo <span class="text-red-flag">*</span>
                    <Datepicker inputClassName="dp-custom-input" v-model="endDate" class="w-full text-sm mt-1"
                        modelType="dd/MM/yyyy" placeholder="Data gg/mm/aaaa" locale="it" selectText="Seleziona"
                        cancelText="Annulla" :enableTimePicker="false" format="dd/MM/yyyy" :max-date="new Date()"
                        :disabled="!hasCertificate">
                    </Datepicker>
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Ente <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Ente"
                        class="w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                        v-model="entity" />
                </label>
            </div>
        </div>
        <div v-if="statusStore.type.toLowerCase() === 'pls' && hasVote" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                <label>Voto <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Inserisci voto"
                        class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                        v-model="vote" />
                </label>
            </div>
            <div class="w-full lg:w-1/3 mt-4 lg:mr-4">
                <label>Su <span class="text-red-flag">*</span>
                    <v-select v-model="scale" class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="scaleArray"
                        label="scale" placeholder="Seleziona un valore" :reduce="scale => scale.scale"
                        :searchable="false" />
                </label>
            </div>
            <div class="w-full lg:w-1/3 mt-4">
                <label>Lode <span class="text-red-flag">*</span>
                    <v-select v-model="laude" class="w-full md:w-2/3 lg:w-full text-sm mt-1.5" :options="laudeArray"
                        label="label" placeholder="Seleziona un valore" :reduce="laude => laude.isLaude"
                        :searchable="false" />
                </label>
            </div>
        </div>
        <div v-if="hasSpecialization" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Specializzazione <span class="text-red-flag">*</span>
                    <v-select v-model="qualificationSpecializationId" class="w-full text-sm mt-1"
                        :options="qualificationSpecializations" label="specialization"
                        placeholder="Seleziona la specializzazione" :reduce="spec => spec.spec_id" :searchable="true"
                        :clearable="false" />
                </label>
            </div>
        </div>
        <div v-if="hasDiffSpecialization" class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-4">
                <label>Specializzazione <span class="text-red-flag">*</span>
                    <input type="text" placeholder="Inserisci specializzazione"
                        class="w-full md:w-2/3 lg:w-full text-sm block mt-1 py-2 px-3 border border-primary rounded focus:outline-none"
                        v-model="diffSpecialization" />
                </label>
            </div>
        </div>
        <div class="flex flex-wrap lg:flex-nowrap mx-2 lg:mx-4">
            <div class="w-full mt-6 mb-6">
                <input type="checkbox" class="cursor-pointer" v-model="isAbroad" true-value="1" false-value="0" />
                <label class="ml-2 font-semibold">Titolo acquisito all'estero</label>
            </div>
        </div>
    </div>
    <div class="py-5 flex w-full justify-around my-8">
        <button class="-btn-primary px-4 uppercase" @click="$emit('closeAddQualificationModal', false)">
            Annulla
        </button>
        <button class="btn-primary px-4 uppercase" @click="saveQualification">
            <span v-if="!loading">Salva</span>
            <svg viewBox="0 0 38 38" stroke="#FFFFFF" class="w-6 mx-auto" v-else>
                <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)" stroke-width="2">
                        <circle stroke-opacity="0.5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                repeatCount="indefinite" />
                        </path>
                    </g>
                </g>
            </svg>
        </button>
    </div>
</template>

<script>
import { useStatusStore } from '@/store/status.js'
import { mapStores } from 'pinia'
import Services from '@/services'

export default {
    name: 'QualificationDetails',
    emits: ["closeAddQualificationModal"],
    props: {
        qualification: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            laudeArray: [
                {
                    label: 'No',
                    isLaude: '0',
                },
                {
                    label: 'Si',
                    isLaude: '1',
                },
            ],
            scaleArray: [],
            qualificationEntitlements: [],
            qualificationSpecializations: [],
            qualificationEntitlementId: null,
            qualificationSpecializationId: null,
            diffSpecialization: null,
            date: null,
            startDate: null,
            endDate: null,
            entity: '',
            vote: '',
            scale: '',
            laude: '',
            isAbroad: false,
            loading: false,
        }
    },
    async created() {
        this.statusStore.type.toLowerCase() === 'mmg' ? await this.getDegreeScales() : await this.getPlsSpecScales()
        await this.getQualificationTitles()
        if (this.qualification) {
            this.qualificationEntitlementId = this.qualification.title_id,
            this.date = this.qualification.graduation_date ? this.$moment(this.qualification.graduation_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            this.startDate = this.$moment(this.qualification.start, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            this.endDate = this.qualification.end ? this.$moment(this.qualification.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            this.entity = this.qualification.issued_by,
            this.vote = this.qualification.vote ? this.qualification.vote : '',
            this.scale = this.qualification.scale ? this.qualification.scale : '',
            this.laude = this.qualification.honors ? this.qualification.honors : '',
            this.qualificationSpecializationId = this.qualification.spec_id ? this.qualification.spec_id : null,
            this.diffSpecialization = this.qualification.diff_specialization ? this.qualification.diff_specialization : null
            this.isAbroad = this.qualification.foreign_title ? this.qualification.foreign_title : null
        }
    },
    computed: {
        ...mapStores(useStatusStore),
        hasDiffSpecialization(){
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if (selectedEntitlement.code.toLowerCase() == 'a13'){
                    return true
                }
                return false 
            }
        },
        hasSpecialization() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                }) 
                if (selectedEntitlement.specializations.length > 0) {
                    this.qualificationSpecializations = selectedEntitlement.specializations
                    return true
                }
                this.qualificationSpecializationId = null
                return false
            }
        },
        hasCertificate() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if ((selectedEntitlement.code.toLowerCase() === 'a7-i' || selectedEntitlement.code.toLowerCase() === 'a11-i') && this.statusStore.hasCertificate == 0) {
                    this.date = null
                    this.endDate = null
                    return false
                }
                return true
            }
        },
        hasVote() {
            if (this.qualificationEntitlementId) {
                var selectedEntitlement = this.qualificationEntitlements.find(obj => {
                    return obj.title_id === this.qualificationEntitlementId
                })
                if (selectedEntitlement.enter_vote == '1') {
                    return true
                }
                return false
            }
        },
        missingMandatoryFields() {
            if (this.hasVote) {
                return (!this.qualificationEntitlementId || (this.hasSpecialization && !this.qualificationSpecializationId) || (this.hasDiffSpecialization && !this.diffSpecialization) || (this.hasCertificate && !this.date) || !this.startDate || (this.hasCertificate && !this.endDate) || !this.entity || !this.vote || !this.scale || !this.laude)
            }
            return (!this.qualificationEntitlementId || (this.hasSpecialization && !this.qualificationSpecializationId) || (this.hasDiffSpecialization && !this.diffSpecialization) || (this.hasCertificate && !this.date) || !this.startDate || (this.hasCertificate && !this.endDate) || !this.entity)
        }
    },
    methods: {
        async getDegreeScales() {
            try {
                const { data } = await Services.getDegreeScales()
                this.scaleArray = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getPlsSpecScales() {
            try {
                const { data } = await Services.getPlsSpecScales()
                this.scaleArray = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },
        async getQualificationTitles() {
            try {
                const { data } = this.statusStore.type.toLowerCase() === 'mmg' ? await Services.getMMGQualificationStudyTitles() : await Services.getPLSQualificationStudyTitles()
                this.qualificationEntitlements = data
            } catch (e) {
                this.$swal({
                    title: "Errore nel recupero dei dati",
                    text: "Si è verificato un errore nel recupero dei dati. Riprova più tardi o contatta l'assistenza tecnica",
                })
            }
        },

        async saveQualification() {

            this.loading = true

            if (this.missingMandatoryFields) {
                this.$swal({
                    title: "Campi obbligatori mancanti",
                    text: "Alcuni campi obbligatori non sono stati compilati"
                })
                this.loading = false
                return;
            }

            let dataToSend = {
                title_id: this.qualificationEntitlementId,
                graduation_date: this.date,
                start: this.startDate,
                end: this.endDate,
                issued_by: this.entity,
                spec_id: this.qualificationSpecializationId ? this.qualificationSpecializationId : null,
                foreign_title: this.isAbroad ? this.isAbroad : null
            }

            if (this.statusStore.type.toLowerCase() === 'pls'){
                dataToSend.diff_specialization = this.diffSpecialization ? this.diffSpecialization : null
            }

            if (this.vote && this.scale && this.laude) {
                dataToSend.vote = this.vote
                dataToSend.scale = this.scale
                dataToSend.honors = this.laude
            }

            try {
                if (this.statusStore.type.toLowerCase() === 'mmg') {
                    this.qualification ? await Services.updateMMGQualification(dataToSend, this.qualification.ac_qual_id) : await Services.postMMGQualification(dataToSend)
                } else if (this.statusStore.type.toLowerCase() === 'pls') {
                    this.qualification ? await Services.updatePLSQualification(dataToSend, this.qualification.ac_qual_id) : await Services.postPLSQualification(dataToSend)
                }
                this.$swal({
                    title: "Salvataggio completato",
                    text: "I dati sono stati salvati correttamente."
                })
                this.loading = false
                this.$emit('closeAddQualificationModal', true)
            } catch (e) {
                if (e.response.data.status === 400) {
                    this.$swal({
                        title: "Attenzione",
                        text: e.response.data.data.error
                    })
                } else {
                    this.$swal({
                        title: "Errore",
                        text: "Si è verificato un errore durante il salvataggio. Contatta il gestore del servizio"
                    })
                }
                this.loading = false
                return;
            }
        }
    }
}
</script>