import { defineStore } from 'pinia'

export const useApplicationRequestStore = defineStore('applicationRequest', {
    state: () => ({
        requestType: {sectors:[]},
        personalData: {},
        selfCertification: {},
        mandatoryQualifications: {},
        jobs: {},
    }),
    getters: {
        getRequestType: (state) => state.requestType,
        getPersonalData: (state) => state.personalData,
        getSelfCertification: (state) => state.selfCertification,
        getMandatoryQualifications: (state) => state.mandatoryQualifications,
        getJobs: (state) => state.jobs,
    },
    actions: {
        setRequestType(requestType) {
            this.requestType = requestType
        },
        setPersonalData(personalDataInfo) {
            this.personalData = personalDataInfo
        },
        setSelfCertification(selfCertificationInfo) {
            this.selfCertification = selfCertificationInfo
        },
        setMandatoryQualifications(qualificationsArray){
            this.mandatoryQualifications = qualificationsArray
        },
        setJobs(jobsInfo){
            this.jobs = jobsInfo
        }
    },
})