<template>
  <div
    class="flex flex-wrap lg:flex-nowrap shadow-rankings border lg:border-t-0 lg:border-b lg:border-l lg:border-r border-primary mx-2 lg:mx-4 items-center"
    :class="rowClass" @click="goToApplication">
    <div
      class="flex flex-col lg:flex-row w-1/3 lg:w-1/12 order-2 lg:order-1 items-center lg:items-start lg:justify-center p-2 border-r lg:border-r-0 border-primary lg:border-0">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Stato</div>
      <div class="px-2 lg:px-1 xl:px-2 rounded shadow-rankings lg:text-sm xl:text-base text-white w-fit mx-auto lg:mx-0"
        :class="application.status.toLowerCase() === 'rifiutata' ? 'bg-red-flag' : (application.status.toLowerCase() === 'accettata' || application.status.toLowerCase() === 'con riserva') ? 'bg-green-flag' : 'bg-orange-500'">
        {{ application.status }}</div>
    </div>
    <div
      class="flex flex-col w-1/3 lg:w-1/12 order-3 lg:order-2 items-center lg:items-start  lg:border-l border-r border-primary p-2">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Anno</div>
      <div class="flex">{{ application.year }}</div>
    </div>
    <div class="flex flex-col w-full lg:w-9/12 order-1 lg:order-3 border-b lg:border-r lg:border-b-0 border-primary p-2">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Tipo domanda</div>
      <div class="flex">{{ application.new_entry == '1' ? application.req_type.toUpperCase() + ' - Primo inserimento' :
        application.req_type.toUpperCase() + ' - Integrazione' }}</div>
    </div>
    <div class="flex flex-col lg:flex-row w-1/3 lg:w-1/12 order-4 p-2 items-center lg:justify-center border-primary">
      <div class="flex lg:hidden font-semibold mb-2 text-primary-dark">Download</div>
      <div class="flex lg:mx-0">
        <font-awesome-icon v-if="application.recap_id" class="h-6 text-red-logo hover:text-red-logo-dark cursor-pointer"
          icon="fas fa-file-pdf" alt="Scarica la domanda" title="Scarica la domanda" @click.stop="downloadPdf" />
        <div v-else> - </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '@/services'

export default {
  name: 'ApplicationSummary',
  props: {
    application: Object,
    isLast: Boolean
  },
  computed: {
    rowClass() {
      return {
        'rounded-b mb-4': this.isLast,
        'mb-4 lg:mb-0': !this.isLast,
        'cursor-pointer': (this.application.status_id == 6 || this.application.status_id == 1)
      }
    }
  },
  methods: {
    goToApplication() {
      if (this.application.status_id == 6 || this.application.status_id == 1) {
        this.$router.push('/home')
      }
    },
    async downloadPdf() {
      try {
        if (this.application.req_type.toLowerCase() === 'mmg') {
          await Services.getMMGAttachment(this.application.recap_id)
        } else if (this.application.req_type.toLowerCase() === 'pls') {
          await Services.getPLSAttachment(this.application.recap_id)
        }
      } catch (error) {
        this.$swal({
          title: "Errore durante il download",
          text: "Si è verificato un errore durante il download. Controlla la connessione e riprova o contatta l'assistenza tecnica",
        })
      }
    }
  }
}
</script>